import React, { useLayoutEffect, useState, useContext } from "react";
import axios from "axios";
import { encryptStorage } from "../../EncryptStorage";
import Avatar from 'react-avatar';
import { Link } from "react-router-dom";
import globalContext from "../../../context/GlobalContext";
import { Button, Col, Modal, Row } from "react-bootstrap";
import moment from "moment/moment";
import { TimelineComponent } from "../../../Utils/Timeline Component";
import { css } from "@emotion/css";
import { BsFileEarmarkText } from 'react-icons/bs'

function MyDeals(props) {
  const Profile_Data = encryptStorage.getItem('Profile_data')
  const contxt = useContext(globalContext);
  const [TimelineUpdate, setTimelineUpdate] = useState(null);
  const [TimelineModalShow, setTimelineModalShow] = useState(false);
  const [TimelineData, setTimelineData] = useState(null)
  const [Timeline_Data, setTimeline_Data] = useState(null)
  const [DealData, setDealData] = useState(null)
  const handleClose = () => setTimelineModalShow(false);
  const handleShow = () => setTimelineModalShow(true);

  useLayoutEffect(() => {

    const fetch_data = async () => {
      try {
        let res = await axios({
          method: 'get',
          url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/get_user_investements_lists?farmer_id=${Profile_Data.id}&mobile_number=${Profile_Data.mn}`,
          headers:
          {
            "Content-Type": "Content-Type: multipart/form-data"
          }
        })
        console.log('axios : ' + res); // response is received    
        return setTimelineData(res?.data?.data)
      } catch (error) {
        console.log(error);
      }
    }
    fetch_data()
  }, [])

  const Timeline = (e, data) => {
    console.log('clk', data)
    handleShow()
    setDealData(data)
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/deal_time_line?cultivation_request_id=${data?.cultivation_request_id}&farmer_id=${data?.requested_farmer_id}&niveshak_id=${Profile_Data.id}`,
    };
    axios(config)
      .then(function (response) {
        console.log(response.data.data)
        setTimeline_Data(response?.data?.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  console.log(TimelineData)
  return (
    !TimelineData ?
      <>
        <div className={css({ boxShadow: '0px 3px 6px #00000029', display: 'flex', justifyContent: 'center', padding: '15px', marginTop: 20, backgroundColor: '#fff', borderRadius: 8 })}>
          <div className="text-center">
            <BsFileEarmarkText fontSize={35} />
            <div className="mt-2">No deals happened</div>
          </div>
        </div>
      </>
      :
      <>
        {TimelineData?.map((item, index) => (
          <div className="search_cards_container" key={index}>
            <div className="grid-container" style={{ gridTemplateColumns: '770px 1fr' }}>
              <div className="card_container" style={{ padding: 15 }}>
                <div className="grid">
                  <div className="content">
                    <Link reloadDocument to={'/post-view?postid=' + item.cultivation_request_id + '&farmer_id=' + item.requested_farmer_id}>
                      <span className="name">{item.crop_name + '-' + item.crop_category}</span></Link>
                    <span className="right">
                      <span className="location">Posted on: {contxt.getdate(item.requested_date)}</span>
                    </span>
                    <div className="star">
                      {item.investment_agreement ? <>
                        <span className="review">Agreement Paper: </span> <a href={item.investment_agreement} target="_blank">View</a> |
                      </> : null}
                      <span className="review"> Invested on: </span> {moment(item.invested_date).format('ll')}
                    </div>

                    <div className="deal-betn">
                      <div className="user">
                        <div className="farmer-img image">
                          {item.requested_farmer_image ? <img src={item.requested_farmer_image} />
                            : <Avatar name={item.requested_farmer_name} maxInitials={1} size="50" />}
                        </div>
                        <div className="info">
                          <div className="name">{item.requested_farmer_name} </div>
                          <div className="user-type">(Farmer)</div>
                        </div>
                      </div>
                      <div className="user">
                        <div className="niveshak-img image">
                          {props.profile.farmer_image ? <img src={props.profile.farmer_image} />
                            : <Avatar name={props.profile.farmer_name} maxInitials={1} size="50" />}
                        </div>
                        <div className="info">
                          <div className="name">{props.profile.farmer_name} </div>
                          <div className="user-type">(Niveshak)</div>
                        </div>
                      </div>

                      <div className="deal-status">
                        <div>Deal Status</div>
                        <h5 className="budget">{item.deal_status}</h5>
                      </div>
                      <div className="amt">
                        <div>Invested Amount</div>
                        <h5>₹{item.invested_amount}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <span className="card-1">
                <div className="card1_container h-100 align-items-center">

                  <Button className="offer m-auto" onClick={(e) => Timeline(e, item)}>View Timeline</Button>

                  {item.niveshak_certificate ?
                    <a href={item.niveshak_certificate} target="_blank">
                      <button className="connect">View Certificate</button>
                    </a> :
                    <Button disabled variant="outline-danger" className="mt-2 m-auto" style={{ width: 140, height: 33, padding: 5, marginTop: '20px !important', borderRadius: 5 }}>No Certificate</Button>
                  }
                </div>
              </span>
            </div>
          </div>
        ))}
        < Modal
          show={TimelineModalShow}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size='lg'
        >
          <Modal.Header closeButton>
            <Modal.Title className={css({ fontSize: 16 })}>Investment status timeline</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <p className={css({ fontSize: 14, color: 'rgb(0,0,0)' })}>
                  {DealData?.deal_status === 'Open' ? `${DealData?.crop_name}-${DealData?.crop_category} is currently open for investment. You can find status of deal below:` : DealData?.deal_status === 'Niveshak_Confirmed' ? `Niveshak ${props.profile.farmer_name} has been confirmed deal for ${DealData?.crop_name}-${DealData?.crop_category}, awaiting for farmer confirmation to complete this deal. You can find status of deal below:` : DealData?.deal_status === 'Farmer_Approved' ? `Deal for ${DealData?.crop_name}-${DealData?.crop_category} has been completed between niveshak ${props.profile.farmer_name} and farmer ${DealData?.requested_farmer_name}. You can find status of deal below:` : ''}
                </p>
              </Col>
            </Row>
            <TimelineComponent Data={Timeline_Data} Deal_Data={DealData} />
          </Modal.Body>
        </Modal >
      </>
  )

}

export default MyDeals;
