import React, { useState, useEffect, useRef, useContext } from 'react'
import axios from 'axios';
import { encryptStorage } from '../EncryptStorage';
import globalContext from '../../context/GlobalContext';
import { BsBell } from 'react-icons/bs';
import { toast } from 'react-toastify';

function NewNotification() {

  const ref = useRef(null)
  const contxt = useContext(globalContext);
  const FarmerData = encryptStorage.getItem('farmer_data')
  const [NewNotification, setNewNotification] = useState();

  const NotificationAPI = () => {
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/notification?for_id=${FarmerData?.farmer_id}`,
    };

    axios(config)
      .then(function (response) {
        contxt.setNotificationData(response?.data?.data);
        const UnreadNotification = response?.data?.data.filter((data) => {
          return data.read_status === 'unread'
        })
        setNewNotification(UnreadNotification?.length)
      })
      .catch(function (error) {
        console.log(error);
        if (error?.response?.data?.code === 400) {
          toast.error(`Error ${error?.response?.data?.code}: Unexpected error occured.`, {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
      });
  }

  useEffect(() => {
    NotificationAPI()
    ref.current = setInterval(NotificationAPI, 5 * 60 * 1000);

    return () => {
      if (ref.current) {
        clearInterval(ref.current)
      }
    }
  }, [])

  return (
    <span className="icon notification">
      <span style={{ fontSize: 20 }}><BsBell /></span>
      {NewNotification ? <span className="num">{NewNotification}</span> : ''}
    </span>

  )
}

export default NewNotification
