import { css } from '@emotion/css'
import React from 'react'
import { Accordion, Col, Row } from 'react-bootstrap'
import { encryptStorage } from '../EncryptStorage'
import CropCategory from './Crop category filter/CropCategory'
import GenderFilter from './Gender filter/GenderFilter'
import StateFilter from './State filter/StateFilter'

const Filters = ({ setState, setGender, setCropCategory }) => {
    const PostsListPath = window.location.pathname

    const Clear_Filter = () => {
        encryptStorage.removeItem('crop_category_filter')
        encryptStorage.removeItem('state_filter')
        encryptStorage.removeItem('gender_filter')
        setState('')
        setCropCategory('')
        setGender('')
    }
    return (
        <>
            <Row>
                <Col>
                    <div className='d-flex justify-content-between'>
                        <p className={css({
                            color: '#333',
                            fontWeight: '600',
                            marginBottom: 15,
                            marginTop: 15,
                        })}>Filter</p>
                        <p className={css({
                            color: '#3D7F39',
                            fontWeight: '600',
                            marginBottom: 15,
                            marginTop: 15,
                            textTransform: 'uppercase',
                            cursor: 'pointer'
                        })}
                            onClick={() => Clear_Filter()} >Clear all</p>
                    </div>
                    <Accordion defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>State</Accordion.Header>
                            <Accordion.Body>
                                <StateFilter setState={setState} />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    {PostsListPath === '/posts' ? null :
                        <Accordion defaultActiveKey={['0']} alwaysOpen>
                            <Accordion.Item eventKey="0" className={css({
                                marginTop: 15
                            })}>
                                <Accordion.Header >Gender</Accordion.Header>
                                <Accordion.Body>
                                    <GenderFilter setGender={setGender} />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>}

                    {PostsListPath === '/posts' ?
                        <Accordion defaultActiveKey={['0']} alwaysOpen>
                            <Accordion.Item eventKey="0" className={css({
                                marginTop: 15
                            })}>
                                <Accordion.Header >Crop category</Accordion.Header>
                                <Accordion.Body>
                                    <CropCategory setCropCategory={setCropCategory} />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion> :
                        null}
                </Col>

            </Row>
        </>)
}

export default Filters