import axios from "axios";
import FormData from 'form-data';
import React, { useLayoutEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { encryptStorage } from "../EncryptStorage";
import InputMobileNum from "./InputMobileNum";
import VerifyOTP from "./VerifyOTP";
function Signin() {
  const [Mobile_number, setMobile_number] = useState('')
  const [OtpSent, setOtpSent] = useState(false)
  const [Disable, setDisable] = useState(false)
  const [FirstRegister, setFirstRegister] = useState(null)
  useLayoutEffect(() => {
    const UserData = encryptStorage.getItem('farmer_data')
    UserData?.farmer_name === '' ? setFirstRegister(true) : setFirstRegister(false)
  }, [])

  var data = new FormData();

  const SendOTP = () => {
    encryptStorage.setItem('Mob', Mobile_number)
    setDisable(true)
    data.append('mobile_number', Mobile_number);
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/receive_mobile_otp`,
      data: data
    };

    axios(config)
      .then(function (response) {
        setOtpSent(true)
        toast.success(response?.data?.message);
      })
      .catch(function (error) {
        setDisable(false)
        toast.error(error?.response?.data?.message)
      });

  }


  return (
    <div className="row position-absolute" style={{ backgroundColor: '#fff', zIndex: 10, top: 0, left: 0, width: "100vw" }}>
      <Col lg={5} md={6} className="bg-img Col" style={{ padding: '30px 60px' }}>
        <Link to="/">
          <div className="back">
            <img src="img/prev.svg" alt="" />
          </div>
        </Link>
        <div className="title">
          <span className="highlight">Help </span>
          you to Solve Farming Needs
        </div>
        <p>
          List as Farmer or Niveshak to Solve Farmer needs and get amazing
          benefits.
        </p>
        <div className="banner">
          <img src="img/signin_banner.png" />
        </div>
      </Col>
      <Col lg={7} md={6} className="Col" style={{ position: "relative" }} >
        <Row>
          <Col>
            <>
              <Row className="justify-content-center ">
                <img src="img/logo_signin.png" style={{ width: 180, height: 116, objectFit: 'contain' }} />
              </Row>
              <Row className="m-auto" style={{ width: 500 }}>
                <Col xs={12}>
                  {!OtpSent ?
                    <InputMobileNum setMobile_number={setMobile_number} SendOTP={SendOTP} heading={true}
                     Disable={Disable} setDisable={setDisable} />
                    :
                    <VerifyOTP Mobile_number={Mobile_number} setOtpSent={setOtpSent} SendOTP={SendOTP} />}
                </Col>
              </Row>
            </>
          </Col>
        </Row>
      </Col>
    </div >
  );
}

export default Signin;
