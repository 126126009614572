import axios from 'axios';
import FormData from 'form-data';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import Avatar from 'react-avatar';
import { Button, Card, Col, Image, Modal, Row } from 'react-bootstrap';
import { AiOutlineFileText } from 'react-icons/ai';
import { RatingComponent } from 'react-rating-emoji';
import { toast, ToastContainer } from 'react-toastify';
import globalContext from '../../../context/GlobalContext';
import { encryptStorage } from '../../EncryptStorage';

function Rating_Review_Card() {
    var data = new FormData();
    const contxt = useContext(globalContext);
    const farmerId = contxt?.ViewProfileData?.farmer_id;
    const FarmerData = encryptStorage.getItem("farmer_data");
    const Authenticated = encryptStorage.getItem('Authenticated');
    const Profile_Data = encryptStorage.getItem('Profile_data')
    const [show, setShow] = useState({ postList: false });
    const [MyPostList, setMyPostList] = useState(null);
    const [ReviewBox, setReviewBox] = useState(false)
    const [Rating, setRating] = useState(0);
    const [OwnProfile, setOwnProfile] = useState(false);
    const [PostSingleData, setPostSingleData] = useState(null)
    const [RatingCount, setRatingCount] = useState(null)
    const [PercentageOfRatings, setPercentageOfRatings] = useState({
        per_of_5_stars: null,
        per_of_4_stars: null,
        per_of_3_stars: null,
        per_of_2_stars: null,
        per_of_1_stars: null,
    })
    const handleClose = () => {
        setReviewBox((s) => !s)
        setShow({ ...show, postList: false })
    }
    const handleShow = () => setShow({ ...show, postList: true });
    const handleRating = (newRating) => {
        setRating(newRating);
    }
    const [ReviewData, setReviewData] = useState({
        rating: null,
        review: '',
        added_by: null || FarmerData?.farmer_id,
        farmer: null || FarmerData?.farmer_id,
        cultivation_request_id: null,
    })

    useEffect(() => {
        setReviewData({ ...ReviewData, rating: Rating, cultivation_request_id: PostSingleData?.cultivation_request_id })
    }, [Rating, PostSingleData])

    const GetReview = () => {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/review?farmer=${farmerId}`,
        };

        axios(config)
            .then(function (response) {
                setRatingCount(response?.data?.data)
                CountReview(response?.data?.data);
            })
            .catch(function (error) {
                console.log('0 *');
            });
    }
    useEffect(() => {
        GetReview()
    }, [])

    const CountReview = (data) => {
        const total_Raiting = data?.rating_overview?.number_of_ratings
        const per_of_5_stars = (data?.rating_overview?.no_of_5_stars / total_Raiting) * 100
        const per_of_4_stars = (data?.rating_overview?.no_of_4_stars / total_Raiting) * 100
        const per_of_3_stars = (data?.rating_overview?.no_of_3_stars / total_Raiting) * 100
        const per_of_2_stars = (data?.rating_overview?.no_of_2_stars / total_Raiting) * 100
        const per_of_1_stars = (data?.rating_overview?.no_of_1_stars / total_Raiting) * 100

        setPercentageOfRatings({ ...PercentageOfRatings, per_of_5_stars: per_of_5_stars, per_of_4_stars: per_of_4_stars, per_of_3_stars: per_of_3_stars, per_of_2_stars: per_of_2_stars, per_of_1_stars: per_of_1_stars })

    }

    useEffect(() => {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/get_investements_between_users?farmer_id_1=${FarmerData?.farmer_id}&farmer_id_2=${Profile_Data.id}`,
        };
        axios(config)
            .then(function (response) {
                setMyPostList(response?.data?.data);
            })
            .catch(function (error) {
                setMyPostList(null);
            });

        if (Profile_Data.id === FarmerData?.farmer_id) {
            setOwnProfile(true)
        }
    }, [Profile_Data?.id])

    const SinglePostData = (key) => {
        setPostSingleData(key)
        setReviewBox((s) => !s)
    }
    const PostReview = () => {
        const data = PostSingleData
        const onChangeInput = useCallback(
            (e) => {
                setReviewData({ ...ReviewData, review: e?.target?.value })
            },
            [ReviewData]
        );
        return (
            <Row >
                <Card className='p-3'>
                    <div className='w-50 m-auto'>
                        <Row className='justify-content-center'>
                            {!data?.requested_farmer_image ? <Avatar name={data?.requested_farmer_name} maxInitials={1} size="200" /> :
                                <Image roundedCircle className='border p-0 m-auto text-center roundedCircle' src={data?.requested_farmer_image} width={100} height={100} style={{ height: 60, width: 60, objectFit: 'cover' }} />}
                        </Row>
                        <Row>
                            <div className='justify-content-center d-flex mt-3' >
                                <RatingComponent rating={Rating} onClick={handleRating} />
                            </div>
                        </Row>
                        <Row className="mt-3 mb-3">
                            <textarea className='w-100' placeholder='Write a review' rows={3} onChange={onChangeInput} />
                        </Row>
                        <Row>
                            <Button type='submit' onClick={() => PostReviewData()}>Submit</Button>
                        </Row>
                    </div>
                </Card>
            </Row>

        )
    }

    const PostReviewData = () => {

        data.append('rating', ReviewData?.rating);
        data.append('review', ReviewData?.review);
        data.append('added_by', ReviewData?.added_by);
        data.append('farmer', ReviewData?.farmer);
        data.append('cultivation_request_id', ReviewData?.cultivation_request_id);

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/review`,
            data: data
        };

        axios(config)
            .then(function (response) {
                GetReview()
            })
            .catch(function (error) {
                console.log(error)
                toast.error(error?.response?.data?.message)
            });
    }

    const ReviewCard = (data, index) => {
        const Style = {
            Rating: {
                backgroundColor: data?.rating === 1 ? '#f44336' : data?.rating === 2 ? '#ff9800' : data?.rating === 3 ? '#00bcd4' : data?.rating === 4 ? '#2196F3' : '#04AA6D',
            }
        }
        return (
            <>
                <div key={data} className="review-card">
                    <span className="star-rating" style={Style?.Rating}>{data?.rating}
                        <img alt='' className="review-star" src="img/Icon awesome-star white.svg" />
                    </span>
                    <span className="star-rating-title">{data?.added_by.farmer_name}</span>
                    <div className="review">
                        {data?.review}
                    </div>
                    <span className="reviewer">
                        KhetiValah Farmer
                    </span>
                    <img className="check-mark" src="img/check-grey.svg" alt="" />
                    <span className="certified">
                        Certified User, IN, Since 2020
                    </span>
                </div>
            </>
        )
    }
    return (
        <>
            <div className="review-tabs">
                <span className="title">Rating & Reviews</span>
                {!Authenticated ? null : OwnProfile ? null : <span className="write-review" onClick={handleShow}>Write a review</span>}
                <div className="grid">
                    <div className="rating-left">
                        <div className="rating">{RatingCount === null ? 0 : RatingCount?.rating_overview?.average_rating}
                            <img alt='' className="star-img" src="img/Icon awesome-star.svg" />
                        </div>
                        <div className="review-rating">{RatingCount === null ? 0 : RatingCount?.rating_overview?.number_of_ratings} Rating &</div>
                        <span className="review-rating">{RatingCount === null ? 0 : RatingCount?.rating_overview?.number_of_reviews} Reviews</span>
                    </div>
                    <div className="star-reviews">
                        <div className="row">
                            <div className="side">
                                <div>5 star</div>
                            </div>
                            <div className="middle">
                                <div className="bar-container">
                                    <div className="bar-5" style={{ width: !RatingCount ? 0 : `${PercentageOfRatings?.per_of_5_stars}%` }}></div>
                                </div>
                            </div>
                            <span className="side right">
                                <span className="review-count">{!RatingCount ? 0 : RatingCount?.rating_overview?.no_of_5_stars}</span>
                            </span>
                            <span className="side">
                                <div>4 star</div>
                            </span>
                            <div className="middle">
                                <div className="bar-container">
                                    <div className="bar-4" style={{ width: !RatingCount ? 0 : `${PercentageOfRatings?.per_of_4_stars}%` }}></div>
                                </div>
                            </div>
                            <div className="side right">
                                <div className="review-count">{!RatingCount ? 0 : RatingCount?.rating_overview?.no_of_4_stars}</div>
                            </div>
                            <div className="side">
                                <div>3 star</div>
                            </div>
                            <div className="middle">
                                <div className="bar-container">
                                    <div className="bar-3" style={{ width: !RatingCount ? 0 : `${PercentageOfRatings?.per_of_3_stars}%` }}></div>
                                </div>
                            </div>
                            <div className="side right">
                                <div className="review-count">{!RatingCount ? 0 : RatingCount?.rating_overview?.no_of_3_stars}</div>
                            </div>
                            <div className="side">
                                <div>2 star</div>
                            </div>
                            <div className="middle">
                                <div className="bar-container">
                                    <div className="bar-2" style={{ width: !RatingCount ? 0 : `${PercentageOfRatings?.per_of_2_stars}%` }}></div>
                                </div>
                            </div>
                            <div className="side right">
                                <div className="review-count">{!RatingCount ? 0 : RatingCount?.rating_overview?.no_of_2_stars}</div>
                            </div>
                            <div className="side">
                                <div>1 star</div>
                            </div>
                            <div className="middle">
                                <div className="bar-container">
                                    <div className="bar-1" style={{ width: !RatingCount ? 0 : `${PercentageOfRatings?.per_of_1_stars}%` }}></div>
                                </div>
                            </div>
                            <div className="side right">
                                <div className="review-count">{!RatingCount ? 0 : RatingCount?.rating_overview?.no_of_1_stars}</div>
                            </div>
                        </div>
                    </div>
                </div>

                {RatingCount === null ? null : <>
                    <div className="horizontal-line"></div>
                    <div>
                        <div className="reviews-title">Top Reviews
                            <span className="top-reviews">Top Reviews
                                <span className="down-arrow">
                                    <img alt='' src="img/down-arrow.svg" />
                                </span>
                            </span>
                        </div>
                    </div>
                    {RatingCount?.ratings_list.map((data, index) => (
                        <span key={index} >
                            {ReviewCard(data, index)}
                        </span>
                    ))}

                    {/* <div className="load-more">
                        Load More +
                    </div> */}
                </>}
            </div>
            <Modal
                show={show?.postList}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                dialogClassName='modal-60w'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Choose a post for review</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!MyPostList ?
                        <Row>
                            <Col className='text-center p-4'>
                                <div><AiOutlineFileText fontSize={40} color='#6c6c6c' /></div>
                                <p className='mt-3' style={{ color: '#6c6c6c' }}>There is no investment agreement happened.</p>
                            </Col>
                        </Row> :
                        MyPostList.map((data, index) => (
                            <>
                                <Row className='rounded p-3' key={index} style={{ backgroundColor: '#e4f1e3' }} >
                                    <Col>
                                        <Row className='bg-white p-3 rounded  align-items-center' style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                            <Col xs={3}>
                                                {!data?.requested_farmer_image ? <Avatar name={data?.requested_farmer_name} maxInitials={1} size="200" /> :
                                                    <Image roundedCircle src={data?.requested_farmer_image} width={100} height={100} style={{ objectFit: 'cover' }} />}

                                            </Col>
                                            <Col xs={7}>

                                                <h3 className='fs-6 fw-bold'>{data?.crop_name + ' - ' + data?.crop_category}</h3>
                                                <p className='fs-7'>Crop Growing Duration: {data?.crop_growing_duration} | Land Area: {data?.land_area_space} | Return Type: 43vcvc</p>
                                            </Col>
                                            <Col xs={2}>
                                                <p className='secondary' style={{ cursor: 'pointer' }} onClick={() => SinglePostData(data)}>Write a review</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {ReviewBox ?
                                    <PostReview />
                                    : null}
                            </>
                        ))
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Rating_Review_Card;

