import { useEffect, useState } from "react";
import axios from "axios";
import EventPop from "./EventPop";
import Confirmation from "./Confirmation";
import { toast } from "react-toastify";

const Events = () => {
  const [eventList, setEventList] = useState([]);
  const [Epop, setEpop] = useState(false);
  const [Confirm, setConfirm] = useState(false);
  const [Res, setRes] = useState(false);
  const [EveInfo, setEveInfo] = useState(null);
  const [PassID, setPassID] = useState();

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url:
        process.env.REACT_APP_BASE_API +
        "/admin_app/event_schedule?status=Ongoing",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setEventList(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const findAttendee = () =>{
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_BASE_API+'/farmer_app/event_attendee_details?event_pass_id='+PassID,
      headers: {}
    };
    
    axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data.data));
      setRes(response.data.data)
      setConfirm(true);
    })
    .catch((error) => {
      toast.error('No attendee found');
      console.log(error);
    });
  }

  

  return (
    <>
      {Epop && (
        <EventPop
          setEpop={setEpop}
          setConfirm={setConfirm}
          setRes={setRes}
          EveInfo={EveInfo}
        />
      )}
      {Confirm && <Confirmation setConfirm={setConfirm} Res={Res} />}
      <div className="container">
        <h4 className="text-center mb-5">Upcoming Events</h4>
        <div class="input-group mb-3" width="60%">
          <input
            type="text"
            class="form-control"
            placeholder="Enter Pass ID"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
            onChange={(e)=>setPassID(e.target.value)}
          />
          <span class="input-group-text" id="basic-addon2" onClick={findAttendee}>
            Find Attendee
          </span>
        </div>

        <div className="row">
          {eventList.map((event) => (
            <div className="col-md-4 mb-4" key={event.primary_key}>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">{event.title}</h5>
                  <div className="card-text">
                    <p className="mb-1">{event.description}</p>
                    <p className="mb-1">
                      <strong>Start Date:</strong> {event.start_date}
                    </p>
                    <p className="mb-1">
                      <strong>End Date:</strong> {event.end_date}
                    </p>
                    <p className="mb-1">
                      <strong>Location:</strong> {event.address}
                    </p>
                    <p className="mb-1">
                      <strong>City:</strong> {event.city}
                    </p>
                  </div>
                  <a
                    href="#"
                    className="btn btn-primary mt-3 text-white"
                    onClick={(e) => {
                      setEpop(true);
                      setEveInfo({
                        title: event.title,
                        eventId: event.primary_key,
                      });
                    }}
                  >
                    Book Your Slot
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Events;
