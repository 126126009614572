import axios from 'axios';
import FormData from 'form-data';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { useAuth } from '../../Utils/Auth Context';
import { encryptStorage } from '../EncryptStorage';
import { BiEditAlt } from 'react-icons/bi'
const VerifyOTP = ({ SendOTP, Mobile_number, setOtpSent, message, afterResponse }) => {
    var data = new FormData();
    let navigate = useNavigate();
    const location = useLocation();
    const Auth = useAuth();
    const [Disable, setDisable] = useState(true)
    const [OTP, setOTP] = useState(null)

    // useEffect(() => {
    //     if (OTP && OTP.length === 4) {
    //         VerifyOTP()
    //     } else {
    //         setDisable(true)
    //     }
    // }, [OTP])

    const OtpHandleChange = (otp) => {
        setOTP(otp)
        if (otp.length === 4) {
            setDisable(false)
        } else {
            setDisable(true)
        }
    };
    const [timer, setTimer] = useState(59);
    const [Counter, setCounter] = useState(null)

    const routeChangeToRegister = (data) => {
        Auth.login(true)
        if (data?.farmer_name) {
            let path = `/`;
            navigate(path, { replace: true });
        } else {
            let path = `/register`;
            navigate(path, { replace: true });

        }

    }
    const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);
    useEffect(() => {
        const counter = timer > 0 && setTimeout(timeOutCallback, 1000);
        return () => clearInterval(counter)
    }, [timer]);

    const resetTimer = () => {
        SendOTP()
        setCounter(Count => Count + 1)
        if (!timer) {
            setTimer(59);
        }
    };
    console.log(encryptStorage.getItem('farmer_data'), encryptStorage.getItem('UserData'))

    const VerifyOTP = () => {
        setDisable(true);
        data.append('mobile_number', Mobile_number);
        data.append('mobile_otp', OTP);
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/verify_mobile_number`,
            data: data
        };

        axios(config)
            .then(function (response) {
                afterResponse();
                if(location.pathname === 'signin'){
                    toast.success('Logged in successfully');
                }else{
                    toast.success(message);
                }
                encryptStorage.setItem('Authenticated', true);
                encryptStorage.setItem('farmer_data', JSON.stringify(response?.data?.data))
                routeChangeToRegister(response?.data?.data)
                console.log(response?.data?.data);
            })
            .catch(function (error) {
                toast.warning('Incorrect OTP');
                setDisable(false);
            });

    }

    return (
        <>
            <Row>
                <h2 className="fw-bold fs-2 title text-center"><span className="highlight">Verify OTP</span></h2>
                <p className="color-text fw-semibold text-center m-auto mt-3">We have send the code verification to your Mobile number</p>
                <p className='title fs-5 text-center m-0 fw-bold mt-2'>+91-{Mobile_number}
                    <span className='ms-3'><BiEditAlt onClick={() => setOtpSent(false)} style={{ cursor: 'pointer' }} /></span></p>
            </Row>
            <Row>
                <OtpInput
                    value={OTP}
                    onChange={OtpHandleChange}
                    numInputs={4}
                    isInputNum={true}
                    containerStyle={'justify-content-between w-75 m-auto mt-3'}
                    inputStyle={{ width: '60px', height: '70px', borderRadius: 12, border: '1px solid #ACACAC', fontSize: 28, fontWeight: 600, color: '#6c6c6c' }}
                    focusStyle={{ outlineColor: '#ACACAC' }}
                    shouldAutoFocus={true}
                />
                <Button className="m-auto mt-4" disabled={Disable} onClick={!Disable ? () => VerifyOTP() : null}>Verify OTP</Button>



                <p className='mt-3 text-center fw-semibold color-text' onClick={Counter === 5 ? null : (!timer ? () => resetTimer() : null)} style={{ textDecoration: Counter === 5 ? 'none' : (!timer ? 'underline' : 'none'), cursor: Counter === 5 ? 'auto' : (!timer ? 'pointer' : 'auto'), fontSize: Counter === 5 ? 11 : 12 }}>{Counter === 5 ? 'You have reached meximum number of attempts' : (timer === 0) ? ' Resend OTP' : `Request again in: 00:${timer}`}</p>

            </Row>
        </>
    )
}

export default VerifyOTP