import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import DealConfirmation from "../components/DealConfirmation";
import { encryptStorage } from "../components/EncryptStorage";
import Farmer from "../components/Farmer/Farmer";
import Footer from "../components/Footer";
import Home from "../components/home/Home";
import FarmerKycUpdate from "../components/KYC/FarmerKycUpdate";
import InvestorKycUpdate from "../components/KYC/InvestorKycUpdate";
import Messaging from "../components/messaging/Messaging";
import NavBar from "../components/navbar/NavBar";
import Niveshak from "../components/Niveshak/niveshak";
import Notification from "../components/notification/Notification";
import PostList from "../components/post/PostList";
import Post_Need from "../components/post/Post_Need";
import Post_View from "../components/post/Post_View";
import Profile from "../components/profile/Profile";
import RegisterUser from "../components/Register";
import RegisterRoute from "../components/Register route";
import Search from "../components/search/Search";
import Setting from "../components/setting/Setting";
import Signin from "../components/signin/Signin";
import globalContext from "../context/GlobalContext";
import { AuthProvider } from "../Utils/Auth Context";
import { RequireAuth } from "../Utils/Require Auth";
import ScrollToTop from "../Utils/Scroll to top/ScrollToTop";
import PageNotFound from "./404 not found";
import Events from "../components/events/Events";
const UserData = encryptStorage.getItem("UserData");

const DesktopLayout = () => {
  //  const UserData = encryptStorage.getItem('UserData');
  const contxt = useContext(globalContext);

  const cover_click = () => {
    const cover = document.getElementById("cover");
    var nav_dropdown = document.getElementById("nav-dropdown");

    cover.addEventListener("click", function () {
      if (contxt.Dropdown === true) {
        nav_dropdown.style.display = "none";
        contxt.setDropdown(false);
      }
    });
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <NetworkStatus /> */}
      <AuthProvider>
        <NavBar />
        <div id="cover" onClick={cover_click}>
          <div className="home">
            <ScrollToTop />
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route
                path="messaging"
                element={
                  <RequireAuth>
                    <Messaging />
                  </RequireAuth>
                }
              />
              <Route
                path="notification"
                element={
                  <RequireAuth>
                    <Notification />
                  </RequireAuth>
                }
              />

              <Route
                path="post-your-need"
                element={
                  <RequireAuth>
                    <Post_Need />
                  </RequireAuth>
                }
              />
              <Route
                path="profile"
                element={
                  <RequireAuth>
                    <Profile />
                  </RequireAuth>
                }
              />
              <Route path="signin" element={<Signin />} />
              <Route
                path="/register"
                element={
                  <RequireAuth>
                    <RegisterRoute />
                  </RequireAuth>
                }
              >
                <Route path="" element={<RegisterUser />} />
                <Route
                  path="farmer-kyc-verification"
                  element={<FarmerKycUpdate />}
                />
                <Route
                  path="investor-kyc-verification"
                  element={<InvestorKycUpdate />}
                />
              </Route>
              <Route
                path="post-view"
                element={
                  <RequireAuth>
                    <Post_View />
                  </RequireAuth>
                }
              />
              <Route path="search" element={<Search />} />
              <Route path="posts" element={<PostList />} />
              <Route
                path="niveshak"
                element={
                  <RequireAuth>
                    <Niveshak />
                  </RequireAuth>
                }
              />
              <Route
                path="farmers"
                element={
                  <RequireAuth>
                    <Farmer />
                  </RequireAuth>
                }
              />
              <Route path="setting" element={<Setting />} />
              <Route path="dealconfirmation" element={<DealConfirmation />} />
              <Route path="*" element={<PageNotFound />} />
              <Route path="events" element={<Events />} />
            </Routes>
          </div>
        </div>
        <Footer />
      </AuthProvider>
    </>
  );
};

export default DesktopLayout;
