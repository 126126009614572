import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import { encryptStorage } from '../../EncryptStorage';

const StateFilter = ({ setState }) => {
    const PostsListPath = window.location.pathname
    const [StateList, setStateList] = useState([]);

    useEffect(() => {
        const url = `${process.env.REACT_APP_BASE_API}/admin_app/states`;
        axios.get(url).then((response) => {
            setStateList(response.data.data);
        });
    }, []);

    const clkdbox = (e) => {
        setState(e.target.value)
        encryptStorage.setItem('state_filter', e.target.value)
        console.log(e.target.value)
    };
    return (
        <>
            <Form.Check
                label='All'
                name='state__Group'
                type='radio'
                id={`radio-all-common`}
                aria-label='all'
                checked={encryptStorage.getItem('state_filter')?false:true}
                value=''
                onChange={(e) => clkdbox(e)}
            />
            {
                StateList.map((state, index) => {
                    return (
                        <Form.Check
                            key={index}
                            label={state?.state_name}
                            name='state__Group'
                            type='radio'
                            id={`radio-${state?.state_name}`}
                            aria-label={PostsListPath === '/posts' ? state.state_name : state?.primary_key}
                            checked={PostsListPath === '/posts' ? encryptStorage.getItem('state_filter') === state.state_name : encryptStorage.getItem('state_filter') === state?.primary_key}
                            value={PostsListPath === '/posts' ? state.state_name : state?.primary_key}
                            onChange={(e) => clkdbox(e)}
                        />
                    );
                })}
        </>
    )
}

export default StateFilter