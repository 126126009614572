import { css } from "@emotion/css";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Pagination from "../../Utils/Pagination/Pagination";
import Farmer_Card from "../cards/Farmer_Card";
import Search_Card from "../cards/Search_Card";
import { encryptStorage } from "../EncryptStorage";
import Filters from "../Filters";
import Spinner from "../Spinner";
import "./search.css";
import { BsSearch } from 'react-icons/bs'
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
let PageSize = 3;

function Search() {
  const [Farmers, setFarmers] = useState([])
  const [Niveshak, setNiveshak] = useState([])
  const [searchParams, setsearchParams] = useSearchParams()

  const FarmerData = encryptStorage.getItem("farmer_data");
  const [isLoading, setisLoading] = useState(true)
  const [PostQueryString, setPostQueryString] = useState([])
  const [FarmerQueryString, setFarmerQueryString] = useState([])
  const [PostsList, setPostsList] = useState(null)
  const [FarmerList, setFarmerList] = useState(null)
  const [NiveshakList, setNiveshakList] = useState(null)
  const [ActivePagination, setActivePagination] = useState({ post: 1, farmer: 1, niveshak: 1 })

  const ResultPosts = PostQueryString?.requests_list
  const ResultFarmers = FarmerQueryString?.farmers_list

  useEffect(() => {

    setisLoading(true)
    const Post_Data = async () => {
      try {
        let res = await axios({
          method: 'get',
          url: `${process.env.REACT_APP_BASE_API}/farmer_app/search_cultivation_requests?&search_input=${searchParams?.get('q')}&status=published${encryptStorage.getItem('state_filter') ? '&state_name=' + encryptStorage.getItem('state_filter') : ''}`,
          headers:
          {
            "Content-Type": "Content-Type: multipart/form-data"
          }
        })
        return setPostQueryString(res?.data?.data)
        // return console.log(res?.data?.data)
      } catch (error) {
        if (error?.response?.data?.code === 400) {
          toast.error(`Error ${error?.response?.data?.code}: Unexpected error occured.`, {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
        return setPostQueryString([])
      }
    }
    const Farmer_Data = async () => {
      try {
        let res = await axios({
          method: 'get',
          url: `${process.env.REACT_APP_BASE_API}/farmer_app/search_farmers?search_input=${searchParams?.get('q')}`,
          headers:
          {
            "Content-Type": "Content-Type: multipart/form-data"
          }
        })
        console.log('axios : ' + res); // response is received    
        return setFarmerQueryString(res?.data?.data)
      } catch (error) {
        if (error?.response?.data?.code === 400) {
          toast.error(`Error ${error?.response?.data?.code}: Unexpected error occured.`, {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
        return setFarmerQueryString([])
      }
    }
    Post_Data()
    Farmer_Data()
    setisLoading(false)
  }, ['', searchParams])

  const postsTable = useMemo(() => {
    const firstPageIndex = (ActivePagination?.post - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return ResultPosts?.slice(firstPageIndex, lastPageIndex);
  }, [ActivePagination, ResultPosts]);
  console.log(Farmers, Niveshak)
  const FarmerTable = useMemo(() => {
    const firstPageIndex = (ActivePagination?.farmer - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return Farmers?.slice(firstPageIndex, lastPageIndex);
  }, [ActivePagination, Farmers]);
  const NiveshakTable = useMemo(() => {
    const firstPageIndex = (ActivePagination?.niveshak - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return Niveshak?.slice(firstPageIndex, lastPageIndex);
  }, [ActivePagination, Niveshak]);
  console.log(FarmerTable, postsTable, NiveshakTable)
  useEffect(() => {
    let Farmers_list_data = []
    let Niveshak_list_data = []
    setisLoading(true)
    const posts = postsTable?.map((data) => {
      return (
        // eslint-disable-next-line react/jsx-pascal-case
        <Search_Card
          key={data.primary_key}
          id={data.primary_key}
          farmerid={data.farmer_id}
          ownprofile={data?.farmer_id === FarmerData?.farmer_id}
        />
      );
    });
    setPostsList(posts)
    console.log(FarmerTable)
    ResultFarmers?.map(element => {
      if (element?.farmer_type === 'Farmer') {
        Farmers_list_data.push(element)
        return (
          setFarmers(Farmers_list_data)
        );
      } else if (element?.farmer_type === 'Niveshak') {
        Niveshak_list_data.push(element)
        return (
          setNiveshak(Niveshak_list_data)
        )
      }
    });
    const farmers = FarmerTable?.map((data) => {
      return (
        // eslint-disable-next-line react/jsx-pascal-case
        <Farmer_Card
          key={data.farmer_id}
          name={data.farmer_name}
          img={data.farmer_image}
          district={data.district_name}
          state={data.state_name}
          date={data.created_at}
          id={data.farmer_id}
          kyc={data.farmer_kyc_status}
          mn={data.mobile_number}
        />
      )
    });
    const niveshak = NiveshakTable?.map((data) => {
      return (
        // eslint-disable-next-line react/jsx-pascal-case
        <Farmer_Card
          key={data.farmer_id}
          name={data.farmer_name}
          img={data.farmer_image}
          district={data.district_name}
          state={data.state_name}
          date={data.created_at}
          id={data.farmer_id}
          kyc={data.would_be_farmer_kyc_status}
          mn={data.mobile_number}
        />
      )
    })
    setFarmerList(farmers)
    setNiveshakList(niveshak)
    setisLoading(false)
    console.log(Farmers, Niveshak)
  }, [FarmerTable, Farmers, Niveshak, NiveshakTable, postsTable])

  console.log(ResultPosts, ResultFarmers, ActivePagination, FarmerList, NiveshakList, Farmers, Niveshak)
  if (isLoading) {
    return <Spinner />
  } else {
    return (
      <>
        <Container>
          <Row>
            <Col xs={3}>
              {/* <Filters setState={setState} setCropCategory={setCropCategory} />  */}
            </Col>
            <Col xs={9}>
              <h5>Search Result for: <b className="highlight">{searchParams?.get('q')}</b></h5>
              <div className={css({ display: 'grid', flexWrap: 'wrap', marginTop: 30, gridTemplateColumns: 'auto' })}>
                {ResultPosts || ResultFarmers ?
                  <>
                    {PostsList?.length > 0 ?
                      <>
                        <section>
                          <div className={css({ fontSize: 20, fontWeight: 600 })}>
                            Posts
                          </div>
                          <div className={css({ backgroundColor: '#E8E8E8', padding: 15, borderRadius: 12 })}>
                            {PostsList}
                            {PostsList &&
                              <Row className='mt-4'>
                                <Pagination
                                  className="pagination-bar"
                                  currentPage={ActivePagination?.post}
                                  pageSize={PageSize}
                                  totalCount={ResultPosts?.length}
                                  onPageChange={page => setActivePagination({ ...ActivePagination, post: page })} />
                              </Row>
                            }
                          </div>
                        </section>
                      </>
                      : null}
                    {FarmerList?.length > 0 ?
                      <>
                        <section className="mt-3">
                          <div className={css({ fontSize: 20, fontWeight: 600 })}>
                            Farmers
                          </div>
                          <div className={css({ backgroundColor: '#E8E8E8', padding: 15, borderRadius: 12, paddingTop: 1 })}>
                            <div className={`card_farmer ${css({ display: 'grid', gap: 20, flexWrap: 'wrap', gridTemplateColumns: '1fr 1fr 1fr', paddingTop: 15 })}`}>
                              {FarmerList}
                            </div>
                            {FarmerList &&
                              <Row className='mt-4'>
                                <Pagination
                                  className="pagination-bar"
                                  currentPage={ActivePagination?.farmer}
                                  pageSize={PageSize}
                                  totalCount={Farmers?.length}
                                  onPageChange={page => setActivePagination({ ...ActivePagination, farmer: page })} />
                              </Row>
                            }
                          </div>
                        </section>
                      </>
                      : null}
                    {NiveshakList?.length > 0 ?
                      <>
                        <section className="mt-3">
                          <div className={css({ fontSize: 20, fontWeight: 600 })}>
                            Niveshak
                          </div>
                          <div className={css({ backgroundColor: '#E8E8E8', padding: 15, borderRadius: 12, paddingTop: 1 })}>
                            <div className={`card_farmer ${css({ display: 'grid', gap: 20, flexWrap: 'wrap', gridTemplateColumns: '1fr 1fr 1fr', paddingTop: 15 })}`}>
                              {NiveshakList}
                            </div>
                            {NiveshakList &&
                              <Row className='mt-4'>
                                <Pagination
                                  className="pagination-bar"
                                  currentPage={ActivePagination?.niveshak}
                                  pageSize={PageSize}
                                  totalCount={Niveshak?.length}
                                  onPageChange={page => setActivePagination({ ...ActivePagination, niveshak: page })} />
                              </Row>
                            }
                          </div>
                        </section>
                      </>
                      : null}
                  </>
                  :
                  <>
                    <section className="h-100" style={{ paddingTop: 180 }}>
                      <div className="d-flex flex-column align-items-center">
                        <BsSearch size={55} color='#d0d0d0' />
                        <div className="mt-3 fs-4 fw-bold" style={{ color: '#d0d0d0' }}>Search result not found.</div>
                      </div>
                    </section>
                  </>
                }
              </div>

            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Search;
