import React, { useState, useEffect } from 'react'
import axios from "axios";
import { BsBookmark, BsBookmarkCheck } from 'react-icons/bs'
import { encryptStorage } from "../EncryptStorage";
import { css } from '@emotion/css';


function Bookmark(props) {
  const FarmerData = encryptStorage.getItem("farmer_data");
  const [Bookmark, setBookmark] = useState({ status: false, cultivation_id: null })
  const [SavedPosts, setSavedPosts] = useState(null)
  const GetBookparkedPosts = (farmer_id) => {
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_API}/farmer_app/book_mark?farmer_id=${farmer_id}`,
    };

    axios(config)
      .then(function (response) {
        setSavedPosts(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    GetBookparkedPosts(FarmerData?.farmer_id)
  }, [FarmerData?.farmer_id])
  const clkbooked = (e, data) => {
    if (e.target.getAttribute('id') === 'unbook') {
      bookmark(props.postid);
    } else {
      unbookmark(data);
    }

  }

  const bookmark = (postid) => {

    let data = new FormData();

    data.append('farmer_id', FarmerData.farmer_id);
    data.append('post_id', postid);

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_API}/farmer_app/book_mark`,
      data: data
    };
    axios(config)
      .then(function (response) {
        setBookmark({ ...Bookmark, status: true })
      })
      .catch(function (error) {
        //console.log(error?.response.data.message);
      });
  }

  const unbookmark = (postid) => {
    let url = `${process.env.REACT_APP_BASE_API}/farmer_app/book_mark?post_id=${postid}&farmer_id=${FarmerData.farmer_id}`
    axios.delete(url)
      .then(function (response) {
        setBookmark({ ...Bookmark, status: false })
        GetBookparkedPosts(FarmerData?.farmer_id)
      }
      )
  }

  useEffect(() => {
    SavedPosts && SavedPosts.map((data, index) => {
      if (data?.cultivation_id === props?.postid) {
        setBookmark({ ...Bookmark, key: index, status: true, cultivation_id: data?.cultivation_id })
      } else {
      }
    })
  }, [SavedPosts, props?.postid])
  const BookmarkedPosts = () => {
    if (Bookmark?.status) {
      return <BsBookmarkCheck id="booked" fontSize={12} color='#fff' onClick={(e) => clkbooked(e, Bookmark?.cultivation_id)} />
    } else {
      return <BsBookmark id="unbook" fontSize={12} onClick={(e) => clkbooked(e, props?.postid)} />
    }
  }
  return (
    <>
      <span className={`bookmark ${css({ borderWidth: 1, borderStyle: 'solid', borderColor: '#757575', borderRadius: '50%', display: 'flex', width: 20, height: 20, justifyContent: 'center', alignItems: 'center', backgroundColor: Bookmark?.status ? '#3d7f39' : '#ffffff' })}`}>
        {BookmarkedPosts()}
      </span>
    </>


  )
}

export default Bookmark