// Popup.js
import React, { useState } from "react";
import "./deleteAccount.css";
import InputMobileNum from "../signin/InputMobileNum";
import { encryptStorage } from "../EncryptStorage";
import axios from "axios";
import { toast } from "react-toastify";
import VerifyOTP from "../signin/VerifyOTP";
import { Button, Form, InputGroup } from "react-bootstrap";
import { IoInformationCircleOutline } from "react-icons/io5";

const DeleteAccount = ({ isOpen, closePopup, children }) => {
  const [Mobile_number, setMobile_number] = useState("");
  const [OtpSent, setOtpSent] = useState(false);
  const [Disable, setDisable] = useState(false);
  const [deleteReasons, setdeleteReasons] = useState([
    "Privacy Concerns",
    "Too Many Notifications",
    "Not Using the Service",
    "Poor User Experience",
    "Billing or Payment Issues",
    "Preffer Not to Say",
    "other",
  ]);
  const [pageType, setPageType] = useState("mobile");
  const [farmerPostData, setFarmerPostData] = useState("");
  const [selectedType, setSelectedType] = useState("");
  if (!isOpen) return null;

  var data = new FormData();
  const SendOTP = () => {
    encryptStorage.setItem("Mob", Mobile_number);
    setDisable(true);
    data.append("mobile_number", Mobile_number);
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/receive_mobile_otp`,
      data: data,
    };

    axios(config)
      .then(function (response) {
        setOtpSent(true);
        toast.success(response?.data?.message);
      })
      .catch(function (error) {
        setDisable(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const afterResponse = () => {
    setPageType("delete");
  };
  const selectReason = (e) => {
    let value = e.target.value;
    setSelectedType(value);
    if (value === "other") {
      setFarmerPostData("");
    } else {
      setFarmerPostData(value);
    }
  };

  const submitForm = () => {
    const farmer_data = encryptStorage.getItem("farmer_data");
    const mobile_number = encryptStorage.getItem("Mob");

    let config = {
      url:
        process.env.REACT_APP_BASE_API + "/farmer_app/account_delete_request",
    };
    let formData = new FormData();
    formData.append("farmer_id", farmer_data.farmer_id);
    formData.append("mobile_number", mobile_number);
    formData.append("comment", farmerPostData);
    axios
      .post(config.url, formData)
      .then((response) => {
          toast.success(response.message);
          closePopup();
      })
      .catch((error) => {
        toast.error("Something went wrong, please check");
      });
  };

  return (
    <div
      className="modal show d-block popup-overlay modal-lg"
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Delete Account</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={closePopup}
            ></button>
          </div>
          <div className="modal-body">
            <h4 className="text-base d-flex align-items-center">
              <IoInformationCircleOutline
                color="red"
                value={{ color: "blue", size: "50px" }}
              />
              <div>If you delete this account:</div>
            </h4>
            <ol>
              <li>
                This account will be deleted from the khetiValah and all your
                devices
              </li>
              <li>Your chat history will be deleted</li>
              <li>Delete your payment info</li>
              <li>Delete your post info</li>
            </ol>
            <div className="w-75 m-auto my-4">
              {!OtpSent && pageType === "mobile" ? (
                <InputMobileNum
                  setMobile_number={setMobile_number}
                  heading={false}
                  SendOTP={SendOTP}
                  Disable={Disable}
                  setDisable={setDisable}
                />
              ) : pageType === "mobile" ? (
                <VerifyOTP
                  Mobile_number={Mobile_number}
                  setOtpSent={setOtpSent}
                  SendOTP={SendOTP}
                  message="OTP verified successfully"
                  afterResponse={afterResponse}
                />
              ) : null}
            </div>
            <div className="w-75 m-auto my-4">
              {pageType === "delete" ? (
                <div className="jumbotron">
                  <p className="color-text fw-semibold text-center m-auto my-3">
                    What hates to see you leave! Tell us why your deleting your
                    account
                  </p>
                  <div>
                    <select
                      name="investment-category"
                      className="w-100 border p-2"
                      onChange={selectReason}
                    >
                      <option selected disabled>
                        Select Reason
                      </option>
                      {!deleteReasons
                        ? null
                        : deleteReasons.map((data, index) => (
                            <option key={index} value={data}>
                              {data}
                            </option>
                          ))}
                    </select>
                    {selectedType === "other" ? (
                      <InputGroup className="my-3">
                        <Form.Control
                          value={farmerPostData}
                          as="textarea"
                          rows={10}
                          onChange={(e) => setFarmerPostData(e.target.value)}
                          placeholder="Please enter reason"
                        />
                      </InputGroup>
                    ) : null}
                  </div>
                  <Button
                    type="submit"
                    className="m-auto my-4 btn btn-primary"
                    disabled={farmerPostData === ""}
                    onClick={submitForm}
                    style={farmerPostData === "" ? { opacity: 0.2 } : null}
                  >
                    Delete Account
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
