import React, { useState, useEffect, useContext, useCallback } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import globalContext from "../../context/GlobalContext";
import Avatar from 'react-avatar';
import Bookmark from "./Bookmark";
import { encryptStorage } from "../EncryptStorage";
import { Button } from "react-bootstrap";
import FormData from "form-data";
import { toast } from "react-toastify";
import { css } from "@emotion/css";
import { CryptoJS } from 'crypto-js'

const elemStyles = css({
    display: 'flex',
    gap: 3,
    alignItems: 'center'
})

function Search_Card(props) {

    const { ownprofile } = props;
    const Farmer_id = encryptStorage.getItem('farmer_data')
    const [Data, setData] = useState({});
    const [UserData, setUserData] = useState({});
    const contxt = useContext(globalContext);
    const FarmerData = encryptStorage.getItem("farmer_data");

    useEffect(() => {
        if (Farmer_id?.farmer_id === props && props?.farmerid) {
            contxt.setOwnPost(true)
        } else {
            contxt.setOwnPost(false);
        }
    }, [])

    const GetPost = () => {
        let url = `${process.env.REACT_APP_BASE_API}/farmer_app/cultivation_request?farmer_id=${props.farmerid}&primary_key=${props.id}`;
        var config = {
            method: 'get',
            url: url,
        };

        axios(config)
            .then(function (response) {
                setData(response.data.data);
                setUserData(response.data.data.farmer_id)
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    useEffect(() => {
        GetPost()
    }, [props.farmerid, props?.id])

    const DeletePost = () => {
        var data = new FormData();
        data.append('updating_by', 'User');
        data.append('farmer_id', props?.farmerid);
        data.append('cultivation_request_id', Data?.primary_key);
        data.append('status', 'Unpublished');

        var config = {
            method: 'put',
            url: `${process.env.REACT_APP_BASE_API}/farmer_app/update_cultivation_request_status`,
            data: data
        };

        axios(config)
            .then(function (response) {
                toast.success('Post unpublished')
                GetPost()
                window.location.reload()
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    const startconnection = () => {
        encryptStorage.setItem('ongoing_connectection', {
            send_to: props.farmerid,
            send_name: UserData.farmer_name,
            for_post_id: Data.primary_key,
            post_title: Data.crop_name + ' - ' + Data.crop_category,
            send_to_img: UserData.farmer_image,
            cultivation_request_id: Data.cultivation_request_id
        })
    }
    const KycStatus = () => {
        if (encryptStorage.getItem('UserData')?.farmer_type === 'Farmer') {
            return encryptStorage.getItem('UserData')?.farmer_kyc_status === 'Completed' ? true : false
        } else
            if (encryptStorage?.getItem('UserData')?.farmer_type === 'Niveshak') {
                return encryptStorage.getItem('UserData')?.would_be_farmer_kyc_status === 'Completed' ? true : false
            }
        return false
    }
    if (Data) {
        return (
            <div className="search_cards_container" onClick={() => { encryptStorage.setItem('Post_View', { post_id: Data.primary_key, farmer_id: props.farmerid }) }}>
                <div className="grid-container">
                    <div className="card_container">
                        <div className="grid">
                            <div className="img">
                                <img src="img/dummy_profile.png" />
                            </div>
                            <div className="content">
                                <Link to={'/post-view?postid=' + props.id + '&farmer_id=' + props.farmerid}>
                                    <span className="name">{Data.crop_name + '-' + Data.crop_category}</span></Link>
                                <span className={`right ${css({ display: 'flex', alignItems: 'center', gap: 7 })}`}>
                                    <span className={`time ${elemStyles}`}><img src="img/time.svg" /> {contxt.getdate(Data.created_at)}</span>
                                    <span className={`location ${elemStyles}`}><img src="img/location-map.svg" /> {Data.district_name}, {Data.state_name}, IN</span>
                                    {FarmerData && <Bookmark postid={Data.primary_key} Data={Data} />}
                                </span>
                                <div className="star">
                                    <span className="review">Crop Growing Duration: </span>{Data.crop_growing_duration}
                                    <span className="review"> | Land: </span>{Data.land_ownership_type}
                                    <span className="review"> | Return Type: </span> {Data.investment_return_type}
                                </div>
                                {/* <div className="project">Invested in 12 project</div> */}
                                <div className="about">Description</div>
                                <div className="aboutmore">{Data.crop_details}</div>
                                <div className="ftr mt-3">
                                    {Data.cultivation_request_id && <span className={`postid ${css({ color: '#333333', backgroundColor: '#E8E8E8', borderRadius: 12, padding: '5px 10px', fontWeight: 600 })}`}>Post id: {Data.cultivation_request_id}</span>}
                                    <span className="profile"><Link to="/profile" onClick={() => encryptStorage.setItem('Profile_data', { id: Data?.farmer_id?.farmer_id})}>
                                        {UserData.farmer_image ?
                                            <img className="pic" src={UserData.farmer_image} /> :
                                            <Avatar name={UserData?.farmer_name} maxInitials={1} round='8px' size="25" textSizeRatio={2} />}
                                        {' ' + UserData.farmer_name}
                                    </Link></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card1_container card-1">
                        <div className="investment-budget">
                            Investment Need
                        </div>
                        <div className="budget">₹{Data.expected_investment}
                        </div>
                        {ownprofile ?
                            <>
                                <div className="d-flex align-items-center" style={{ height: 86 }}>
                                    <Button variant="danger" className="connect" style={{ marginRight: 'auto', color: 'var(--bs-btn-color)', border: 'var(--bs-btn-border-width) solid var(--bs-btn-border-color)', backgroundColor: 'var(--bs-btn-bg)' }} onClick={() => DeletePost()}>Unpublish post</Button>
                                </div>
                            </>
                            : <>
                                <Link to={'/post-view?postid=' + props.id + '&farmer_id=' + props.farmerid}><button className="offer">View Offer</button></Link>
                                {KycStatus() ?
                                    <>
                                        <Link to="/messaging"><button onClick={startconnection} className="connect">Connect</button></Link>
                                    </> : null}
                            </>}

                    </div >
                </div >
            </div >

        );
    }
}

export default Search_Card;
