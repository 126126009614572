import React, { useState } from "react"
import BasicSettings from "./Basic_settings";
import Gallery from "./Gallery";
import CropInfo from "./Crop_info";
import { encryptStorage } from "../EncryptStorage";
import Avatar from "react-avatar";
import { MdOutlineManageAccounts } from 'react-icons/md'
import DeleteAccount from "./DeleteAccount";

function Setting() {
  const FarmerData = encryptStorage.getItem('UserData')
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index)
  }

  return (
    <div className="settings">
      <div className="settings-tab-section" style={{ height: '100vh' }}>
        <div className="profile align-items-center">
          <div>
            {FarmerData?.farmer_image ? <img className="pic" src={FarmerData?.farmer_image} /> :
              <Avatar round name={FarmerData?.farmer_name} textSizeRatio={2} size='50' />
            }
          </div>
          <div className="info">
            <div className="name">{FarmerData?.farmer_name} {FarmerData?.verified ? <img src="img/verified.svg" /> : null}</div>
            <div className="location">
              {" "}Hyderabad, Telangana, IN
            </div>
          </div>
        </div>

        <div className="tab-list">
          <div className={toggleState === 1 ? "tab-items active" : "tab-items"}
            onClick={() => toggleTab(1)}>
            <img className="image" src="img/setting.svg" />
            <span className="item-name">Basic Settings</span>
          </div>
          <div className={toggleState === 2 ? "tab-items active" : "tab-items"}
            onClick={() => toggleTab(2)}>
            <MdOutlineManageAccounts size={28} />
            <span className="item-name">Account Setting</span>
          </div>


        </div>
      </div>
      <div className={toggleState === 1 ? "tab setting active" : "tab next"}>
        <BasicSettings />
      </div>
      <div className={toggleState === 2 ? "tab setting active" : "tab next"}>
        <DeleteAccount />
      </div>

    </div>
  );
}

export default Setting;
