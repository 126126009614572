import React from 'react'

function Icon_Grid() {
  return (
    <div className="icon_grid">
        <div className="grid_item">
          <img src="/img/grid_icon/icon-1.png" alt="" />
          <div className="icon_title">Farming Hand</div>
        </div>
        <div className="grid_item">
          <img src="/img/grid_icon/icon-2.png" alt="" />
          <div className="icon_title">Buyers & Auction</div>
        </div>
        <div className="grid_item">
          <img src="/img/grid_icon/icon-3.png" alt="" />
          <div className="icon_title">Finance</div>
        </div>
        <div className="grid_item">
          <img src="/img/grid_icon/icon-4.png" alt="" />
          <div className="icon_title">Insurance</div>
        </div>
        <div className="grid_item">
          <img src="/img/grid_icon/icon-5.png" alt="" />
          <div className="icon_title">Seeds</div>
        </div>
        <div className="grid_item">
          <img src="/img/grid_icon/icon-6.png" alt="" />
          <div className="icon_title">Pesticides</div>
        </div>
        <div className="grid_item">
          <img src="/img/grid_icon/icon-7.png" alt="" />
          <div className="icon_title">Machinery</div>
        </div>
        <div className="grid_item">
          <img src="/img/grid_icon/icon-8.png" alt="" />
          <div className="icon_title">Borewell</div>
        </div>
        <div className="grid_item">
          <img src="/img/grid_icon/icon-9.png" alt="" />
          <div className="icon_title">Guidance</div>
        </div>
      </div>
  )
}

export default Icon_Grid