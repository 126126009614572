import { css } from '@emotion/css';
import axios from 'axios';
import React, { useContext, useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import globalContext from '../../context/GlobalContext';
import Farmer_Card from '../cards/Farmer_Card';
import { encryptStorage } from '../EncryptStorage';
import Filters from '../Filters';
import Spinner from '../Spinner';

const Farmer = () => {
    const FarmerData = encryptStorage.getItem("farmer_data");
    const contxt = useContext(globalContext);
    const [isLoading, setisLoading] = useState(true);
    const [CardList, setCardList] = useState(null);
    const [State, setState] = useState('')
    const [Gender, setGender] = useState('')
    useEffect(() => {
        const getNiveshakList = () => {
            setisLoading(true);
            let url = `${process.env.REACT_APP_BASE_API
                }/farmer_app/farmers_list?page=1&farmer_type=Farmer${State ? "&state_name=" + State : ""
                }${Gender ? "&gender=" + Gender : ""}`;

            const fetch_data = async () => {
                setisLoading(true)
                await axios
                    .get(url)
                    .then((response) => {
                        let res = response.data.data.farmers_list;
                        const list = res.map((key) => {
                            return (
                                <Farmer_Card
                                    key={key.farmer_id}
                                    name={key.farmer_name}
                                    img={key.farmer_image}
                                    district={key.district_name}
                                    state={key.state_name}
                                    date={key.created_at}
                                    id={key.farmer_id}
                                    kyc={key.farmer_kyc_status}
                                    mn={key.mobile_number}
                                />
                            );
                        });

                        setCardList(list);
                    })
                    .catch((error) => {
                        setCardList("No Results");
                        setisLoading(false);
                        if (error?.response?.data?.code === 400) {
                            toast.error(`Error ${error?.response?.data?.code}: Unexpected error occured.`, {
                                position: "top-right",
                                autoClose: false,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    });
                setisLoading(false);
            };

            fetch_data();
        }

        getNiveshakList()
    }, ['',Gender, State])
    return (
        isLoading ? <Spinner /> :
            <>
                <Container>
                    <Row>
                        <Col xs={3}>
                            <Filters setState={setState} setGender={setGender} />
                        </Col>
                        <Col xs={9}>
                            <div className={`${css({ display: 'grid', gap: 20, flexWrap: 'wrap', marginTop: 50, gridTemplateColumns: '1fr 1fr 1fr' })} card_farmer`}>
                                {CardList}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>)
}

export default Farmer