import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { AiOutlineMobile, AiOutlineMail } from 'react-icons/ai'
import { GrFacebookOption, GrTwitter, GrYoutube, GrLinkedinOption, GrPinterest } from 'react-icons/gr'
import { IoLogoInstagram } from 'react-icons/io5'
import { SiQuora } from 'react-icons/si'
import {RiCalendarEventLine  } from "react-icons/ri";
import { Link } from 'react-router-dom'

const TopNavBar = () => {

    

    return (
        <>
       
            <div>
                <Container className='topbar-nav position-fixed' style={{ fontSize: 14, color: 'rgb(51 51 51 / 91%)', backgroundColor: '#F9F9FC', top: 0, zIndex: 9 }}>
                    <Row className='py-2'>
                        <Col md={4}>
                            <div className='d-flex align-items-center' style={{ gap: 20 }}>
                                <div>
                                    <AiOutlineMobile />
                                    <span><a href='tel:+919440906666'>+91-9440906666</a></span>
                                </div>
                                
                                <Link to="/events">
                                    <RiCalendarEventLine />
                                    <span> Khetivalah Event</span>
                                </Link> 
                                
                                
                            </div>
                        </Col>
                        <Col md={8}>
                            <div className='d-flex justify-content-end' style={{ gap: 20 }}>
                                <div>
                                    <a href="https://khetivalah.in/" target="_blank" rel="noreferrer">
                                        Know about Khetivalah
                                    </a>
                                </div>
                                <div>
                                    Download magazine
                                </div>
                                <div>
                                    <span className='topbar-icon-nav'>
                                        <a href="https://www.facebook.com/KhetiValah/" target="_blank" rel="noreferrer">
                                            <GrFacebookOption />
                                        </a>
                                    </span>
                                    <span className='topbar-icon-nav'>
                                        <a href="https://twitter.com/kethiValah" target="_blank" rel="noreferrer">
                                            <GrTwitter />
                                        </a>
                                    </span>
                                    <span className='topbar-icon-nav'>
                                        <a href="https://www.instagram.com/khetiValah/" target="_blank" rel="noreferrer">
                                            <IoLogoInstagram />
                                        </a>
                                    </span>
                                    <span className='topbar-icon-nav'>
                                        <a href="https://www.youtube.com/@khetivalah" target="_blank" rel="noreferrer">
                                            <GrYoutube />
                                        </a>
                                    </span>
                                    <span className='topbar-icon-nav'>
                                        <a href="https://www.linkedin.com/company/khetivalah/" target="_blank" rel="noreferrer">
                                            <GrLinkedinOption />
                                        </a>
                                    </span>
                                    <span className='topbar-icon-nav'>
                                        <a href="http://www.pinterest.com/khetivalah" target="_blank" rel="noreferrer">
                                            <GrPinterest />
                                        </a>
                                    </span>
                                    <span className='topbar-icon-nav'>
                                        <a href="https://www.quora.com/KhetiValah" target="_blank" rel="noreferrer">
                                            <SiQuora />
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default TopNavBar