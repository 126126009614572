import axios from "axios"
import React, { useContext, useEffect, useState } from "react";
import Avatar from 'react-avatar';
import { Link } from "react-router-dom";
import globalContext from "../../context/GlobalContext";
import { encryptStorage } from "../EncryptStorage";
import SimilarProfiles from "./SimilarProfiles";
import Tabs from "./Tabs";
import { toast, ToastContainer } from "react-toastify";
import ReportProfile from "./ReportProfile";
import About_Description from "./About_Description";
import Mudra from "./Mudra";

function Profile() {
  const Authenticated = encryptStorage.getItem('Authenticated')
  const Profile_Data = encryptStorage.getItem('Profile_data')
  const url = new URL(window.location.href);
  const contxt = useContext(globalContext);
  const FarmerData = encryptStorage.getItem("farmer_data");
  const [isOpen, setIsOpen] = useState(false);
  const [ProfileData, setProfileData] = useState();
  const [OwnProfile, setOwnProfile] = useState(false);
  const [SimilarProfilePath, setSimilarProfilePath] = useState('')

  const follow = (e) => {
    let btn = e.target.innerHTML;
    if (btn === "Follow") {
      e.target.innerHTML = "Unfollow";
    } else {
      e.target.innerHTML = "Follow";
    }
  };

  const KycStatus = () => {
    if (ProfileData?.farmer_type === "Farmer") {
      return ProfileData?.farmer_kyc_status
    } else {
      return ProfileData?.would_be_farmer_kyc_status
    }
  }

  const fields = () => {

    if (ProfileData?.farmer_type === "Farmer") {
      return (
        <>
          <li>
            <div>Having own land</div>
            <h4>{ProfileData?.having_own_land != 'undefined' ? ProfileData?.having_own_land : 'N/A'}</h4>
          </li>
          <li>
            <div>Land Size</div>
            <h4>{ProfileData?.own_land_space != 'undefined acres' ? ProfileData?.own_land_space : 'N/A'}</h4>
          </li>
        </>
      )
    } else {
      return (
        <>
          <li>
            <div>Budget</div>
            <h4>{!ProfileData?.investment_amount ? '-N/A-' : ProfileData?.investment_amount}</h4>
          </li>
          <li>
            <div>Return Type</div>
            <h4>{!ProfileData?.investment_return_type ? '-N/A-' : ProfileData?.investment_return_type}</h4>
          </li>
        </>
      )

    }
  }

  useEffect(() => {

    if (FarmerData?.farmer_id === Profile_Data.id) {
      setOwnProfile(true)
    }

    const profileid = contxt.ProfileView.id?contxt.ProfileView:encryptStorage.getItem('Profile_data') ;

    const url = `${process.env.REACT_APP_BASE_API}/farmer_app/farmer_registration?farmer_id=${profileid.id}`;

    const fetch = async () => {
      await axios
        .get(url)
        .then((response) => {
          console.log(response.data);
          setProfileData(response.data.data);
          contxt?.setViewProfileData(response?.data?.data)
        })
        .catch(function (error) {
          if (error?.response?.data?.code === 400) {
            toast.error(`Error ${error?.response?.data?.code}: Unexpected error occured.`, {
              position: "top-right",
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "light",
            });
          }
        });
    };

    fetch();

  }, [contxt.ProfileView.id]);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  }

  if (ProfileData) {
    return (
      <div className="container pt-4">
        <div className="profile-grid">
          <div className="left">
            <div className="about-grid">
              <div className="position-relative" style={{ width: 200 }}>
                {ProfileData?.farmer_kyc_status ? (
                  <img src="./img/verified.svg" className="position-absolute" style={{ right: 8, top: 8, width: 20 }} />
                ) : (
                  null
                )}
                {!ProfileData?.farmer_image ? <Avatar name={ProfileData?.farmer_name} maxInitials={1} size="200" /> :
                  <img className="pic rounded border" src={ProfileData?.farmer_image} style={{ width: 200, height: 200, objectFit: 'cover' }} />
                }
                <h4 className="name mt-2 mb-0 fs-5">
                  {ProfileData?.farmer_name}
                </h4>
                <div className="location fw-semibold">
                  <img src="img/location-map.svg" /> {ProfileData?.address_line_2}

                </div>
              </div>
              <div className="content p-3">
                <h4 className="fs-4">
                  About
                  <span className="type px-3">
                    Last Seen: {contxt.getdate(ProfileData?.last_login)}
                  </span>
                </h4>
                <div className="about">{!ProfileData?.about_me ? '-N/A-' : <About_Description content={ProfileData?.about_me} limit={1000} />}</div>
                <div className="info">
                  <ul>
                    <li>
                      <div>Profile Type</div>
                      <h4>{!ProfileData?.farmer_type ? '-N/A-' : ProfileData?.farmer_type}</h4>
                    </li>
                    {fields()}
                    <li>
                      <div>Member Since</div>
                      <h4>{contxt.getdate(ProfileData?.created_at)}</h4>
                    </li>
                    <li>
                      <div>Gender</div>
                      <h4>{!ProfileData?.gender ? '-N/A-' : ProfileData?.gender}</h4>
                    </li>
                    <li>
                      <div>District & State</div>
                      <h4>{!ProfileData?.district_name ? '' : ProfileData?.district_name}, {!ProfileData?.state_name ? '' : ProfileData?.state_name}</h4>
                    </li>
                    <li>
                      <div>KYC</div>
                      <h4>
                        {KycStatus()}
                      </h4>
                    </li>
                    <li>
                      <div>Proof Verified</div>
                      <h4>{!ProfileData?.identity_proof_type ? '-N/A-' : ProfileData?.identity_proof_type}</h4>
                    </li>
                    <li>
                      <div>Mandal & Village</div>
                      <h4>{!ProfileData?.mandal_name ? '' : ProfileData?.mandal_name}, {!ProfileData?.village_name ? '' : ProfileData?.village_name}</h4>
                    </li>
                  </ul>
                </div>

                <div className="interest">
                  <div>Support for</div>
                  <ul className="tag-grp">
                    <li>
                      <img src="img/check-grey.svg" alt="" /> Land Purchase
                    </li>
                    <li>
                      <img src="img/check-grey.svg" alt="" /> Machinery
                    </li>
                    <li>
                      <img src="img/check-grey.svg" alt="" /> Cultivation
                    </li>
                    <li>
                      <img src="img/check-grey.svg" alt="" /> Seeding
                    </li>
                    <li>
                      <img src="img/check-grey.svg" alt="" /> Planting
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <Tabs ownprofile={OwnProfile} profile={ProfileData} />
          </div>
          <div className="r-sidebar">
            {OwnProfile ? null :
              <>
                <div className="follow" style={{ display: "none" }}>
                  <button className="follow-btn" onClick={follow}>
                    Follow
                  </button>
                </div>
                <div className="connect" style={{ display: "none" }}>
                  <Link to="/messaging">
                    <button className="connect-btn">Connect</button>
                  </Link>
                </div>
              </>}
            <div className="scores">
              <div className="open-investment box">
                <div className="title">Mudra Points</div>
                <div className="score highlight"><Mudra /></div>
              </div>
              <div className="open-investment box">
                <div className="title">Open for Investment</div>
                <div className="score highlight">Yes</div>
                <div className="small">You can send proposal for investment</div>
              </div>
              {/* <div className="past-investment box">
                <div className="title">Investment Made</div>
                <div className="score highlight">0</div>
                <div className="small"> <img src="img/check-grey.svg" alt="" />  0 Investments done of 0</div>
              </div>
              <div className="past-investment box">
                <div className="title">Active Offers</div>
                <div className="score highlight">0</div>
                <div className="small">0 proposal is open</div>
              </div>
              <div className="active-investment box">
                <div className="title">Report on posts</div>
                <div className="score highlight">0 Post</div>
                <div className="small">0 post found as spam</div>
              </div> */}
            </div>
            {OwnProfile ? null : <div className="report" onClick={togglePopup}>
              Report Profile
            </div>}
            {isOpen && <ReportProfile togglePopup={togglePopup} />
            }
          </div>
        </div>
        <div className="related-profile">
          <h3>
            Similar Profiles
            <Link to={`/${ProfileData?.farmer_type === 'Niveshak' ? 'Niveshak' : 'Farmers'}`}>
              <span className="view">
                View all <img src="img/right_arrow.svg" />{" "}
              </span>
            </Link>
          </h3>
          <SimilarProfiles user_type={ProfileData?.farmer_type} state={ProfileData?.state_name} />
        </div>
      </div>
    );
  }
}

export default Profile;
