import { css } from "@emotion/css";
import axios from "axios";
import FormData from "form-data";
import React, { useEffect, useState } from "react";
import { Button, Container, Form, InputGroup } from "react-bootstrap";
import Geocode from "react-geocode";
import { useForm } from "react-hook-form";
import { MdOutlineGpsFixed } from 'react-icons/md';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from '../../Utils/API client/index';
import { encryptStorage } from "../EncryptStorage";
import MultipleFileUpload from "../KYC file upload/File Uploader/MultipleFileUpload";
import Spinner from "../Spinner";
import { BiError } from 'react-icons/bi'
import { BsCheckCircle } from "react-icons/bs";

const ConfigCategory = () => axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin_app/crop_category`)
const ConfigCropTypes = () => axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin_app/crop_types`)
const ConfigLandOwnership = () => axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin_app/land_ownership_types`)
const ConfigLandSoilType = () => axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin_app/land_soil_types`)
const ConfigLandFieldTypes = () => axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin_app/land_field_types`)

const ErrorField = css({
  fontSize: 12,
  color: '#f00'
})

function Post_Need() {
  const [GeoCordinates, setGeoCordinates] = useState({ latitude: encryptStorage.getItem('Latitude'), longitude: encryptStorage.getItem('Longitude') })
  const [CurrentAddress, setCurrentAddress] = useState([]);
  const [Submitted, setSubmitted] = useState(false)

  function getMyLocation() {
    const location = window?.navigator && window?.navigator?.geolocation
    if (location) {
      Geocode.setApiKey("AIzaSyCALGkGIduqYJHJFIW9rRGitfSsARcwhTQ");
      Geocode.setLanguage("en");
      Geocode.setRegion("in");
      Geocode.setLocationType("ROOFTOP");
      Geocode.enableDebug();
      GeoCordinates?.latitude && Geocode.fromLatLng(GeoCordinates?.latitude, GeoCordinates?.longitude).then(
        (response) => {
          const address = response?.results?.[0]?.formatted_address;
          setCurrentAddress(address);
          console.log(address);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }
  console.log(GeoCordinates)
  const url = new URL(window.location.href);
  const postid = url.searchParams.get("postid");
  const farmerid = url.searchParams.get("farmer_id");
  const Post_View = encryptStorage.getItem('Post_View');
  const ConfigCategoryAPI = useApi(ConfigCategory)
  const ConfigCropTypesAPI = useApi(ConfigCropTypes)
  const ConfigLandOwnershipAPI = useApi(ConfigLandOwnership)
  const ConfigLandSoilTypeAPI = useApi(ConfigLandSoilType)
  const ConfigLandFieldTypesAPI = useApi(ConfigLandFieldTypes)
  const [Error, setError] = useState({ status: false, message: null })
  const [LandImages, setLandImages] = useState('')
  const [LandArea, setLandArea] = useState({ landSize: null, landUnit: 'acer' })
  const [CropOutcome, setCropOutcome] = useState({ outcome: null, outcomeUnit: 'ton' })
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();

  const [PostData, setPostData] = useState({
    farmer_id: encryptStorage.getItem('UserData')?.farmer_id,
    mobile_number: encryptStorage.getItem('UserData')?.mobile_number,
    state_name: encryptStorage.getItem('UserData')?.state_name,
    district_name: encryptStorage.getItem('UserData')?.district_name,
    assembly_name: encryptStorage.getItem('UserData')?.assembly_name
  })
  useEffect(() => {
    let mobNum = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/
    if (mobNum.test(PostData?.alternate_mobile)) {
      setError(false)
      setPostData({ ...PostData, alternate_mobile: PostData?.alternate_mobile })
    } else {
      setError(true)
    }
  }, [PostData])

  useEffect(() => {
    ConfigCategoryAPI?.request()
    ConfigCropTypesAPI?.request()
    ConfigLandOwnershipAPI?.request()
    ConfigLandSoilTypeAPI?.request()
    ConfigLandFieldTypesAPI?.request()
    getMyLocation()
  }, [])

  const notificationPost = async (name, id) => {
    var notificationData = new FormData();
    notificationData.append('for_id', id);
    notificationData.append('notification_message', `Post ${name} was submitted to review.`);

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/notification`,
      data: notificationData,
    };

    axios(config)
      .then(function () {
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const sbtmData = (data) => {
    console.table(data)
  }

  const submitPost = (Data) => {
    console.log(LandImages)
    var data = new FormData();
    data.append('farmer_id', PostData?.farmer_id);
    data.append('mobile_number', PostData?.mobile_number);
    data.append('alternate_mobile', Data?.cropAlternetMobile);
    data.append('crop_name', Data?.cropName);
    data.append('crop_category', Data?.cropCategory);
    data.append('water_facility_available', Data?.cropWaterFacility);
    data.append('crop_details', Data?.cropDescription);
    data.append('land_ownership_type', Data?.cropLandOwnershipType);
    data.append('land_soil_type', Data?.cropSoilType);
    data.append('land_field_type', Data?.cropFieldType);
    data.append('land_area_space', Data?.cropLandAreaSize + ' ' + Data?.cropLandAreaUnit);
    data.append('land_address', Data?.cropLandLocation);
    for (const image of LandImages) {
      data.append('image_files', image);
    }
    data.append('water_facility_source', Data?.cropWaterFacilitySource);
    data.append('crop_growing_duration', Data?.cropGrowingDuration + ' ' + Data?.cropGrowingDurationTime);
    data.append('expected_investment', Data?.cropInvestmentAmount);
    data.append('investment_return_type', Data?.cropReturnType);
    data.append('expected_crop_outcome', Data?.cropExpectedOutcome + ' ' + Data?.cropExpectedOutcomeUnit);
    data.append('land_geolocation', [GeoCordinates?.latitude, GeoCordinates?.longitude]);
    data.append('state_name', PostData?.state_name);
    data.append('district_name', PostData?.district_name);
    data.append('assembly_name', PostData?.assembly_name);

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/cultivation_request`,
      data: data
    };
    if (LandImages?.length > 0) {

      axios(config)
        .then(async function () {
          toast.warning(`Post ${Data?.cropName} was submitted fo review!`)
          notificationPost(Data?.cropName, PostData?.farmer_id)
          //window.location.href = "/";
          setSubmitted(true)
        })
        .catch(function (error) {
          toast.error(error.response.data.message);
          if (error?.response?.data?.code === 400) {
            toast.error(`Error ${error?.response?.data?.code}: Unexpected error occured.`, {
              position: "top-right",
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "light",
            });
          }
        });
    }

  }

  useEffect(() => {

    if ((postid !== "") & (farmerid !== "")) {
      if (Post_View?.farmer_id === "") {
        encryptStorage.setItem('Post_View', {
          post_id: postid,
          farmer_id: farmerid,
        });
      }
    }

    let url = `${process.env.REACT_APP_BASE_API}/farmer_app/cultivation_request?farmer_id=${Post_View?.farmer_id ? Post_View?.farmer_id : farmerid}&primary_key=${Post_View?.post_id ? Post_View?.post_id : postid}`;

    axios.get(url).then((response) => {
      encryptStorage.setItem('Update_post_data', response?.data?.data)
    }).catch((error) => {
      console.log('search card error: ' + error.message);
    });
  }, [Post_View])

  if(!Submitted){
  return (
    ConfigLandFieldTypesAPI.loading ? <Spinner /> :
      encryptStorage.getItem('UserData')?.farmer_kyc_status !== 'Completed' ?
        <>
          <Container className={`d-flex align-items-center justify-content-center ${css({
            height: 'calc(100vh - 150px)'
          })}`}>
            <div className="text-center">
              <BiError fontSize={95} color='rgba(108, 108, 108, 0.41)' />
              <p style={{ color: 'rgba(108, 108, 108, 0.41)', fontWeight: '600' }}>Your kyc is pending for verification</p>
            </div>
          </Container>
        </> :
        <>
          <div className="post-investor post-form container">
            <div className="head">
              <div className="title">Create Cultivation Request</div>
              <div className="sub">
                Please fill the information carefully.
              </div>
            </div>
            <div className="form-grid">
              <div className="grid-item">
                <form onSubmit={handleSubmit(submitPost)}>
                  <div className="field">
                    <div className="label">Crop name*</div>

                    <input type="text"
                      onKeyPress={(event) => {
                        if (/[^a-zA-Z ]+/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      {...register("cropName", {
                        required: {
                          value: true,
                          message: 'Crop name for post is required.'
                        },
                        minLength: {
                          value: 5,
                          message: 'Crop name should be at least 5 characters.'
                        }
                      })
                      }
                      placeholder="Write relevant title for your post"
                      maxLength={60} />
                  </div>
                  <span className={ErrorField}>
                    {errors?.cropName?.message}
                  </span>
                  <div className="field">
                    <div className="label">
                      Select category*
                    </div>
                    <select
                      name="investment-category"
                      id="investment-category"
                      {...register("cropCategory", {
                        required: {
                          value: true,
                          message: 'Please select category for crop.'
                        },
                      })
                      }
                    >
                      <option value="" disabled>Select category</option>
                      {!ConfigCategoryAPI.data?.data ? '' :
                        (ConfigCategoryAPI.data?.data?.map((data, index) => (
                          <option key={index} value={data?.primary_key} >{data?.crop_category}</option>
                        )))
                      }
                    </select>
                  </div>
                  <span className={ErrorField}>
                    {errors?.cropCategory?.message}
                  </span>
                  <div className="field">
                    <div className="label">Description*</div>
                    <textarea
                      placeholder="Breif details about your need"
                      {...register("cropDescription", {
                        required: {
                          value: true,
                          message: 'Description about crop is required.'
                        }, minLength: {
                          value: 30,
                          message: 'Minimum 30 characters is required'
                        }, maxLength: {
                          value: 4000,
                          message: 'Maximum 4000 characters are allowed.'
                        }
                      })}></textarea>
                  </div>
                  <span className={ErrorField}>
                    {errors?.cropDescription?.message}
                  </span>

                  <div className="field">
                    <div className="label">
                      Land ownership type*
                    </div>
                    <select
                      name="investment-category"
                      id="investment-category"
                      {...register("cropLandOwnershipType", {
                        required: {
                          value: true,
                          message: 'Select your type of land ownership.'
                        }
                      })
                      }
                    >
                      <option value="" disabled>Select land ownership type</option>
                      {!ConfigLandOwnershipAPI.data?.data ? null :
                        ConfigLandOwnershipAPI.data?.data?.map((data, index) => (
                          <option key={index} value={data?.primary_key} >{data?.land_ownership_type}</option>
                        ))

                      }
                    </select>
                  </div>
                  <span className={ErrorField}>
                    {errors?.cropLandOwnershipType?.message}
                  </span>

                  <div className="field">
                    <div className="label">
                      Soil type?*
                    </div>
                    <select
                      name="investment-category"
                      id="investment-category"
                      {...register("cropSoilType", {
                        required: {
                          value: true,
                          message: 'Select soil type of your land.'
                        }
                      })
                      }
                    >
                      <option value="" disabled>Select land soil type</option>
                      {!ConfigLandSoilTypeAPI.data?.data ? null :
                        ConfigLandSoilTypeAPI.data?.data?.map((data, index) => (
                          <option key={index} value={data?.primary_key} >{data?.land_soil_type}</option>
                        ))

                      }
                    </select>
                  </div>
                  <span className={ErrorField}>
                    {errors?.cropSoilType?.message}
                  </span>

                  <div className="field">
                    <div className="label">
                      Land field type*
                    </div>
                    <select
                      name="investment-category"
                      id="investment-category"
                      {...register("cropFieldType", {
                        required: {
                          value: true,
                          message: 'Select type of your land field.'
                        }
                      })
                      }
                    >
                      <option value="" disabled>Select land field type</option>
                      {!ConfigLandFieldTypesAPI.data?.data ? null :
                        ConfigLandFieldTypesAPI.data?.data?.map((data, index) => (
                          <option key={index} value={data?.primary_key} >{data?.land_field_type}</option>
                        ))
                      }
                    </select>
                  </div>
                  <span className={ErrorField}>
                    {errors?.cropFieldType?.message}
                  </span>

                  <div className="field">
                    <div className="label">Enter land area*</div>
                    <InputGroup className="flex-nowrap p-0" style={{ width: 480 }}>
                      <Form.Control
                        placeholder="eg. 20 Acer"
                        aria-label="land property"
                        type="text"
                        aria-describedby="land property"
                        maxLength={2}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onPaste={(e) => {
                          e.preventDefault()
                          return false;
                        }}
                        {...register("cropLandAreaSize", {
                          required: {
                            value: true,
                            message: 'Crop land area is required.'
                          },
                          validate: {
                            positive: v => parseInt(v) >= 0 || 'Land area size is not a valid input.',
                          }
                        })
                        }
                      />
                      <Form.Select aria-label="Default select example"
                        {...register("cropLandAreaUnit", {
                          required: {
                            value: true,
                            message: 'Unit of crop land area is required.'
                          }
                        })
                        }
                        defaultValue={'acer'}>
                        <option value="acer">Acres</option>
                        <option value="hectare">Hectare</option>
                      </Form.Select>
                    </InputGroup>
                  </div>
                  <span className={ErrorField}>
                    {errors?.cropLandAreaUnit?.message}
                    {errors?.cropLandAreaSize?.message}
                  </span>
                  <div className="field">
                    <div className={css({
                      width: 480,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    })}>
                      <div className="label">Land location*</div>
                    </div>
                    <textarea
                      onPaste={(e) => {
                        e.preventDefault()
                        return false;
                      }}
                      value={CurrentAddress}
                      {...register("cropLandLocation", {
                        required: {
                          value: false,
                          message: 'Location of land is required.'
                        }
                      })
                      }
                      placeholder="Enter full address" maxLength={250}></textarea>
                  </div>
                  <span className={ErrorField}>
                    {errors?.cropLandLocation?.message}
                  </span>

                  <div className="field">
                    <div className="label">
                      Water facility available
                    </div>
                    <select
                      name="investment-category"
                      id="investment-category"
                      defaultValue='yes'
                      {...register("cropWaterFacility", {
                        required: {
                          value: true,
                          message: 'Select water facility available.'
                        }
                      })
                      }
                    >
                      <option value='yes'>Yes</option>
                      <option value='no'>No</option>
                    </select>
                  </div>
                  <span className={ErrorField}>
                    {errors?.cropWaterFacility?.message}
                  </span>

                  <div className="field">
                    <div className="label">Water facility source*</div>
                    <input
                      onKeyPress={(event) => {
                        if (/[^a-zA-Z ]+/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault()
                        return false;
                      }}
                      {...register("cropWaterFacilitySource", {
                        required: {
                          value: true,
                          message: 'Enter source for water.'
                        }
                      })
                      }
                      type="text" placeholder="eg. River, Canal" maxLength={65} />
                  </div>
                  <span className={ErrorField}>
                    {errors?.cropWaterFacilitySource?.message}
                  </span>

                  <div className="field">
                    <div className="label">Crop growing duration*</div>
                    <InputGroup className="flex-nowrap p-0" style={{ width: 480 }}>
                      <Form.Control
                        placeholder="Crop growing duration"
                        aria-label="land property"
                        type="text"
                        aria-describedby="crop growing duration"
                        maxLength={3}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onPaste={(e) => {
                          e.preventDefault()
                          return false;
                        }}
                        {...register("cropGrowingDuration", {
                          required: {
                            value: true,
                            message: 'Crop gowing duration is required.'
                          },
                          validate: {
                            positive: v => parseInt(v) >= 0 || 'Crop gowing duration is not a valid input.',
                          }
                        })
                        }
                      />
                      <select name="crop-duration" id='crop-duration' aria-label="Default select days"
                        defaultValue='days'
                        {...register("cropGrowingDurationTime", {
                          required: {
                            value: true,
                            message: 'Unit for expected crop outcome is required.'
                          }
                        })
                        }>
                        <option value="days">Days</option>
                        <option value="months">Months</option>
                      </select>
                    </InputGroup>
                    {/* <input type="text" placeholder="e.g. 4 Months" maxLength={20}
                      {...register("cropGrowingDuration", {
                        required: {
                          value: true,
                          message: 'Duration for crop to grow is required'
                        }
                      })
                      }
                    /> */}
                  </div>
                  <span className={ErrorField}>
                    {errors?.cropGrowingDuration?.message}
                  </span>

                  <div className="field  position-relative">
                    <div className="label">Investment Amount*</div>
                    <input type="text"
                      placeholder="Enter the amount you wish to invest" maxLength={5}
                      {...register("cropInvestmentAmount", {
                        validate: {
                          positive: v => parseInt(v) >= 10000 || 'Please make sure your investment amount should be in ₹10000 - ₹50000 range.',
                          lessThanTen: v => parseInt(v) < 50001 || 'Please make sure your investment amount should be in ₹10000 - ₹50000 range.',
                        }
                      })}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }} onPaste={(e) => {
                        e.preventDefault()
                        return false;
                      }} style={{ paddingLeft: 25 }} />
                    <p className="position-absolute fw-semibold" style={{ top: 38, fontSize: 20, left: 10 }}>&#x20B9;</p>
                  </div>
                  <span className={ErrorField}>
                    {errors?.cropInvestmentAmount?.message}
                  </span>

                  <div className="field">
                    <div className="label">Return*</div>
                    <InputGroup className="flex-nowrap p-0" style={{ width: 480 }}>
                      <Form.Control
                        placeholder="return percentage of crop you want in return"
                        aria-label="return"
                        type="text"
                        aria-describedby="return"
                        maxLength={3}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onPaste={(e) => {
                          e.preventDefault()
                          return false;
                        }}
                        {...register("cropReturnType", {
                          required: {
                            value: true,
                            message: 'Crop gowing duration is required.'
                          },
                          validate: {
                            positive: v => parseInt(v) >= 0 || 'Crop gowing duration is not a valid input.',
                          }
                        })
                        }
                      />
                      <Form.Select aria-label="Default select example">
                        <option value="crop">Crop</option>
                      </Form.Select>
                    </InputGroup>
                    {/* <input type="text" placeholder="eg. 45% of Crop" maxLength={2}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault()
                        return false;
                      }}
                      {...register("cropReturnType", {
                        validate: {
                          positive: v => parseInt(v) > 0 || 'Please make sure return value be in range 0% to 60% of crop.',
                          lessThanTen: v => parseInt(v) < 60 || 'Please make sure return value be in range 0% to 60% of crop.',
                        }
                      })}
                    /> */}
                  </div>
                  <span className={ErrorField}>
                    {errors?.cropReturnType?.message}
                  </span>

                  <div className="field">
                    <div className="label">Expected crop outcome*</div>
                    <InputGroup className="flex-nowrap p-0" style={{ width: 480 }}>
                      <Form.Control
                        maxLength={CropOutcome?.outcomeUnit === 'ton' ? 2 : 4}
                        placeholder="eg. 4500 kg"
                        aria-label="land property"
                        type="text"
                        value={CropOutcome?.outcome}
                        aria-describedby="land property"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onPaste={(e) => {
                          e.preventDefault()
                          return false;
                        }}
                        {...register("cropExpectedOutcome", {
                          required: {
                            value: true,
                            message: 'Expected outcome from crop is required.'
                          }
                        })}
                      />
                      <Form.Select aria-label="Default select example"
                        defaultValue={CropOutcome?.outcomeUnit}
                        {...register("cropExpectedOutcomeUnit", {
                          required: {
                            value: true,
                            message: 'Unit for expected crop outcome is required.'
                          }
                        })
                        }>
                        <option value="kg">Kg</option>
                        <option value="ton">Ton</option>
                      </Form.Select>
                    </InputGroup>
                  </div>
                  <span className={ErrorField}>
                    {errors?.cropExpectedOutcome?.message}
                    {errors?.cropExpectedOutcomeUnit?.message}
                  </span>

                  <div className="field">
                    <div className="label">Land Images *</div>
                    <MultipleFileUpload setValue={setValue} register={register} errors={errors} setImagePath={setLandImages} />
                    <p className="color-text" style={{ fontSize: 12, maxWidth: 485 }}>Please select all land images (max 6 image can be upload at once)</p>
                  </div>

                  <div className="field">
                    <div className="label">Alternate mobile number*</div>
                    <input type="text" placeholder="Enter alternate mobile number" maxLength={10}
                      {...register("cropAlternetMobile", {
                        required: {
                          value: true,
                          message: 'Provide alternemt number to contact.'
                        }
                      })
                      }
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault()
                        return false;
                      }} />
                  </div>
                  <span className={ErrorField}>
                    {errors?.cropAlternetMobile?.message}
                  </span>
                  <Button type='submit' className="submit mt-3 bg-green d-block" >Post Now</Button>
                </form>
              </div>
            </div>
          </div>
        </>
  ); }else if(Submitted){
    return(
    <div className="post-investor post-form container">
            <div className="head" style={{textAlign:'center'}}>
              <h3 style={{fontSize:50, color:'#3D7F39'}}><BsCheckCircle/></h3>
              <h5>Cultivation Request Submitted Successfully</h5>      
            </div>
     </div> 
    )      
  }
}

export default Post_Need;
