import React from 'react'
import { TwitterTimelineEmbed } from "react-twitter-embed";

const TwitterFeed = () => {
    return (
        <>
            <TwitterTimelineEmbed
                sourceType="profile"
                screenName="kethiValah"
                options={{
                    tweetLimit: "10",
                    width: "340px",
                    height: "500px"
                }}
                theme="light"
                noHeader="true"
                noBorders="true"
                noFooter="true"
            ></TwitterTimelineEmbed>
        </>
    )
}

export default TwitterFeed