import 'bootstrap/dist/css/bootstrap.min.css';
import { React, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { encryptStorage } from './components/EncryptStorage';
import DesktopLayout from './Layout/DesktopLayout';
import './Web.css';
import MediaQuery from 'react-responsive'
import MobileLayout from './Layout/MobileLayout';
import 'react-rating-emoji/dist/index.css'
import ScrollToTop from "react-scroll-to-top";
import { AiOutlineArrowUp } from 'react-icons/ai'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer } from 'react-toastify';
import { useState } from 'react';
import Spinner from './components/Spinner';


function App() {
  const [spinner, setSpinner] = useState(<Spinner />);

  const FarmerData = encryptStorage.getItem('UserData')

  useEffect(() => {

    navigator.geolocation.getCurrentPosition(
      function (position) {
        console.log('current co-ordinates: ', position?.coords?.latitude, position?.coords?.longitude);
        encryptStorage.setItem('Latitude', position.coords.latitude);
        encryptStorage.setItem('Longitude', position.coords.longitude);
      },
      function (error) {
        console.error("Error Code = " + error.code + " - " + error.message);
      }
    );
    setTimeout(() => setSpinner(false), 1000)
  }, [])
  console.log('User Profile data==>', FarmerData)
  if (spinner) {
    return <Spinner />
  } else {
    return (
      <>
        <div className="App">
          {/* <MediaQuery minWidth={1224}> */}
          <DesktopLayout />
          {/* </MediaQuery>
      <MediaQuery maxWidth={1223}>
        <MobileLayout />
      </MediaQuery> */}
          <ScrollToTop smooth component={<AiOutlineArrowUp size={20} />} style={{ backgroundColor: '#2E5F2B' }} className='d-flex justify-content-center align-items-center' color='#fff' />
        </div>
        <ToastContainer limit={1} />
      </>
    );
  }
}

export default App;
