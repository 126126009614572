import axios from 'axios'
import FormData from 'form-data'
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../Utils/Auth Context'
import { encryptStorage } from '../EncryptStorage'


function DeleteAccount() {
    var data = new FormData();
    const FarmerData = encryptStorage.getItem('UserData')
    const auth = useAuth();
    let navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [Disable, setDisable] = useState(true)
    const [FarmerID, setFarmerID] = useState(null)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {

        if (FarmerID === FarmerData?.farmer_id) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    }, [FarmerID])
    const DeleteAccount = () => {
        data.append('updating_by', 'User');
        data.append('mobile_number', FarmerData?.mobile_number);
        data.append('account_status', 'Terminated');
        data.append('farmer_id', FarmerID);
        var config = {
            method: 'put',
            url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/update_farmer_account_status`,
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log('Account Status: ', response.data);
                encryptStorage.clear();
                auth.logout()
                navigate('/', { replace: true })
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    return (
        <div className="settings-container">
            <h3>Delete account</h3>
            <p>This action deletes your account from khetivalah and you'll be not able to login again. There is no going back.</p>

            <Button variant="danger" onClick={handleShow} style={{ border: 'none', width: 'auto' }}>
                Delete my account
            </Button>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Enter the following to confirm your delete request:<br />
                    <code>{FarmerData?.farmer_id}</code>
                    <Row>
                        <Form.Control
                            type="text"
                            id="deleteAccount"
                            aria-describedby="deleteAccount"
                            className="w-75 mt-2 px-3 mx-3"
                            onChange={(e) => setFarmerID(e.target.value)}
                        />
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={Disable} variant="danger" style={{ border: 'none', width: 'auto' }} onClick={!Disable ? () => DeleteAccount() : null}>Confirm</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DeleteAccount;