import { css } from "@emotion/css";
import axios from "axios";
import FormData from "form-data";
import React, { useContext, useEffect, useState } from "react";
import Avatar from "react-avatar";
import { Button, Container, Image, Modal } from "react-bootstrap";
import { IoImageOutline } from 'react-icons/io5';
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import globalContext from "../../context/GlobalContext";
import Bookmark from "../cards/Bookmark";
import Popup from "../cards/Report_Popup";
import { encryptStorage } from "../EncryptStorage";
import FileUploader from "../KYC file upload/File Uploader";
import About_Description from "../profile/About_Description";
import Spinner from "../Spinner";
import SimilarPosts from "./SimilarPosts";
import { BsClock } from 'react-icons/bs'
import { BiError } from "react-icons/bi";
function LandImagesZoom(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop='static'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Land Image
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Image src={props?.image} style={{ width: '100%' }} />
      </Modal.Body>
    </Modal>
  );
}
function Post_View() {
  var data = new FormData();
  const location = useLocation()
  const url = new URL(window.location.href);
  const postid = url.searchParams.get("postid");
  const farmerid = url.searchParams.get("farmer_id");
  const contxt = useContext(globalContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setisLoading] = useState(true)
  const [PostData, setPostData] = useState({});
  const [FarmerData, setFarmerData] = useState({})
  const [OwnProfile, setOwnProfile] = useState(
    encryptStorage.getItem("farmer_data")?.farmer_id == url.searchParams.get("farmer_id")?true:false
  );
  const [ImagePath, setImagePath] = useState('')
  const [Error, setError] = useState({ status: false, message: '' })
  const [modalShow, setModalShow] = React.useState({ status: false, src: null });
  const Post_View = encryptStorage.getItem('Post_View')
  const [ReportPostData, setReportPostData] = useState({
    reported_by: Post_View?.farmer_id ? Post_View?.farmer_id : farmerid,
    post_id_reported: Post_View?.post_id ? Post_View?.post_id : postid,
    report_category: '',
    report_description: '',
    post_owner_id: Post_View?.farmer_id ? Post_View?.farmer_id : farmerid,
    screenshot: null,
  })

  const startchat=()=>{

    let obj = {
      send_to: farmerid,
      send_name: FarmerData.farmer_name,
      for_post_id: postid,
      post_title: PostData.crop_name + ' - ' + PostData.crop_category,
      send_to_img: FarmerData.farmer_image,
      cultivation_request_id: PostData.cultivation_request_id}
    
    encryptStorage.setItem('ongoing_connectection', obj) 
    console.log(encryptStorage.getItem('ongoing_connectection'));
  
  }

  useEffect(() => {
    setReportPostData({ ...ReportPostData, screenshot: ImagePath?.[0] })
  }, [ImagePath])

  useEffect(() => {
    if (ReportPostData?.report_category === '') {
      setError({ status: true, message: 'Please select category.' })
    } else if (ReportPostData?.report_description === '') {
      setError({ status: true, message: 'Please provide description about report.' })
    } else {
      setError({ status: false, message: '' })
    }
    if (ReportPostData?.report_description?.length > 2000) {
      toast.error('Word limit exceeded.')
    }
  }, [ReportPostData])

  const submitReportPost = () => {
    data.append('reported_by', ReportPostData?.reported_by)
    data.append('post_id_reported', ReportPostData?.post_id_reported)
    data.append('report_category', ReportPostData?.report_category)
    data.append('report_description', ReportPostData?.report_description)
    data.append('screenshot', ReportPostData?.screenshot)
    data.append('post_owner_id', ReportPostData?.post_owner_id)
    data.append('post_type', 'Post')

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_API}/farmer_app/report`,
      data: data
    };

    axios(config)
      .then(function (response) {
        toast.warning('Request is pending for review.')
        togglePopup()
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const togglePopup = () => {
    setIsOpen(!isOpen);
  }

  useEffect(() => {

    setisLoading(true)
    if ((postid !== "") & (farmerid !== "")) {
      if (Post_View?.farmer_id === "") {
        encryptStorage.setItem('Post_View', {
          post_id: postid,
          farmer_id: farmerid,
        });
      }
    }

    let url = `${process.env.REACT_APP_BASE_API}/farmer_app/cultivation_request?farmer_id=${farmerid}&primary_key=${postid}`;

    axios.get(url).then((response) => {
      setPostData(response.data.data);
      setFarmerData(response.data.data.farmer_id);
      setisLoading(false)
      updateviews();
    }).catch((error) => {
      console.log('search card error: ' + error.message);
      setisLoading(false);
      if (error?.response?.data?.code === 400) {
        toast.error(`Error ${error?.response?.data?.code}: Unexpected error occured.`, {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      }
    });
  }, [Post_View?.post_id, Post_View?.farmer_id, farmerid, postid])

  const updateviews = () => {

    var data = new FormData();
    data.append('primary_key', postid);

    var config = {
      method: 'put',
      url: `${process.env.REACT_APP_BASE_API}/farmer_app/update_post_view_count`,
      data: data
    };

    axios(config)
      .then(function (response) {
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const Arr = []
  useEffect(() => {
    !isLoading && PostData?.images?.map((img) => {
      // setLandImages([{ ...LandImages, src: img?.image }])
      Arr.push({
        src: img?.image, desc: "Person wearing shoes",
        sub: "Gift Habeshaw"
      })
    })
  }, [PostData, isLoading])
  
  const KycStatus = () => {

    if (encryptStorage.getItem('UserData')?.farmer_type === 'Farmer') {
      return encryptStorage.getItem('UserData')?.farmer_kyc_status === 'Completed' ? true : false
    } else
      if (encryptStorage?.getItem('UserData')?.farmer_type === 'Niveshak') {
        return encryptStorage.getItem('UserData')?.would_be_farmer_kyc_status === 'Completed' ? true : false
      }
    return false
  }
  if (isLoading) {
    <Spinner />
  } else if (FarmerData) {
    return (
      <div className="container post-view pt-4">
        <div className="post-view-grid">
          <div>
            <div className="abt-grid">
              <div>
                {FarmerData?.farmer_image ? <>
                  <img className="pic rounded-circle" src={FarmerData.farmer_image} style={{ width: 45, height: 45, objectFit: 'cover' }} />
                </> : <>
                  <Avatar name={PostData?.farmer_id?.farmer_name} maxInitials={1} round size="45" textSizeRatio={2} />
                </>}

              </div>
              <div className="info">
                <div className="name">{PostData.farmer_id ? PostData?.farmer_id?.farmer_name : '-N/A-'}</div>
                <div className="location">
                  <img src="img/location-map.svg" /> {PostData.district_name + ', ' + PostData.state_name + ', IN'}
                </div>
              </div>
            </div>

            <Link to={'/profile?id=' + FarmerData.farmer_id + '&mn=' + FarmerData.mobile_number}
              onClick={() => { encryptStorage.setItem('Profile_data', { id: FarmerData.farmer_id, mn: FarmerData.mobile_number }); }}>
              <div className="view-profile">
                View Profile <img src="img/profile-arrow.svg" />{" "}
              </div>
            </Link>
            <div className="box map">
              <div className="title">Posted In </div>
              <div className="location">{PostData.village_name}, {PostData.district_name}</div>
              <a target='_blank' href={`https://www.google.com/maps/@${PostData?.land_geolocation},16.77z`}>
                <img src="img/map.png" />
              </a>
            </div>
            <div className="box map">
              <div className="title">Address</div>
              <div className="location">
                {PostData.land_address}
              </div>
            </div>
            <div className="box">
              <h4>{PostData.view_count} </h4>People Viewed
            </div>
          </div>
          <div className="details">
            <div className="cover">
              <div className={css({ display: 'flex', justifyContent: 'space-between', alignItems: 'center  ' })}>
                <span className={css({ fontSize: 20, fontWeight: '600' })}>
                  {PostData.crop_name + ' - ' + PostData.crop_category}
                </span>
                <div className={css({ fontSize: 14 })}>
                  <span className={css({ backgroundColor: '#e8e8e8', padding: '4px 10px', borderRadius: 8, marginRight: 10 })}>
                    Post ID: {PostData.cultivation_request_id}
                  </span>
                  {/*<span className="ad">Ad</span> */}
                  <span className="right d-flex align-items-center">
                    <span className="d-flex align-items-center me-2 ">
                      <BsClock className='me-1' /> {contxt.getdate(PostData.created_at)}
                    </span>
                    <span className="bookmark m-0">
                      {<Bookmark postid={postid} />}
                    </span>
                  </span>
                </div>
              </div>
              <div className="box">
                <div className="description p-2" style={{ fontSize: 14 }}>{<About_Description content={PostData.crop_details} limit={1000} />}</div>
              </div>
              <div className="farmer crop-details">
                <div className="title">Crop Details</div>
                <div className="crop-grid">
                  <div className="box">
                    <div className="head">Crop Category</div>
                    <div className="sel">{PostData.crop_category}</div>
                  </div>
                  <div className="box">
                    <div className="head">Crop Name</div>
                    <div className="sel">{PostData.crop_name}</div>
                  </div>
                  <div className="box">
                    <div className="head">Crop Growing Duration</div>
                    <div className="sel">{PostData.crop_growing_duration}</div>
                  </div>
                  <div className="box">
                    <div className="head">Expected Crop Outcome</div>
                    <div className="sel">{PostData.expected_crop_outcome}</div>
                  </div>
                </div>
              </div>
              <div className="farmer crop-details">
                <div className="title">Land Details</div>
                <div className="crop-grid">
                  <div className="box">
                    <div className="head">Land Ownership Type</div>
                    <div className="sel">{PostData.land_ownership_type}</div>
                  </div>
                  <div className="box">
                    <div className="head">Land Field Type</div>
                    <div className="sel">{PostData.land_field_type}</div>
                  </div>
                  <div className="box">
                    <div className="head">Soil Type</div>
                    <div className="sel">{PostData.land_soil_type}</div>
                  </div>
                  <div className="box">
                    <div className="head">Land Area</div>
                    <div className="sel">{PostData.land_area_space}</div>
                  </div>
                </div>
              </div>
              <div className="facility">
                <div className="title">Facility</div>
                <ul className="tag-grp">
                  <li className="tag">
                    <img src="img/check-grey.svg" /> Water Facility: {PostData.water_facility_available}
                  </li>
                  <li className="tag">
                    <img src="img/check-grey.svg" /> Water Source: {PostData.water_facility_source}
                  </li>
                </ul>
              </div>
              <br />
            </div>
          </div>
          <div className="grid-item">
            <div className="box" style={{ marginTop: 0 }}>
              Post Status
              <h5>{PostData.post_status} </h5>
            </div>
            <div className="box">
              <div className="title">Fund Need</div>
              <h4 className="amt">₹ {PostData.expected_investment}</h4>
            </div>
            {KycStatus()
            && !OwnProfile? 
              <>
              <Link to="/messaging"><button className="inquire" onClick={startchat}>Inquire Now</button></Link>
              <Link to="/messaging"><button className="connect" onClick={startchat}>Connect</button></Link>
              </>:null}

            <div className="budget-details box">
              <div className="title">Return Details</div>
              <div className="return text-center">
                Return Type <div className="ans">{PostData.investment_return_type}% of crop</div>{" "}
              </div>

            </div>

            {!OwnProfile &&<div className="report" style={{ width: 'fit-content', float: 'right' }} onClick={togglePopup}>Report Post</div>}
            {
              isOpen && <Popup
                handleClose={togglePopup}
                content={
                  KycStatus() ?
                    <div>
                      <p className="report-text">If you find anything objectionable or incorrect information, let us know.</p>
                      <p style={{ display: Error?.status ? 'block' : 'none', color: '#f00' }}>*{Error?.message}</p>
                      <div className="content-title">Select a category</div>
                      <div className="category-list">
                        <ul>
                          <li>
                            <input type="radio" name='report__id' value="offensive content" className="check-box" onClick={(e) => setReportPostData({ ...ReportPostData, report_category: e.target.value })} />
                            <label htmlFor="list1">Offensive content</label>
                          </li>
                          <li>
                            <input type="radio" name='report__id' value="fraud" onClick={(e) => setReportPostData({ ...ReportPostData, report_category: e.target.value })} />
                            <label htmlFor="list2">Fraud</label>
                          </li>
                          <li>
                            <input type="radio" name='report__id' value="duplicate post" onClick={(e) => setReportPostData({ ...ReportPostData, report_category: e.target.value })} />
                            <label htmlFor="list3">Duplicate Post</label>
                          </li>
                          <li>
                            <input type="radio" name='report__id' value="deal already done" onClick={(e) => setReportPostData({ ...ReportPostData, report_category: e.target.value })} />
                            <label htmlFor="list4">Deal already done</label>
                          </li>
                          <ul>
                            <li>
                              <input type="radio" name='report__id' value="misleading" onClick={(e) => setReportPostData({ ...ReportPostData, report_category: e.target.value })} />
                              <label htmlFor="list5">Misleading</label>
                            </li>
                            <li>
                              <input type="radio" name='report__id' value="other" onClick={(e) => setReportPostData({ ...ReportPostData, report_category: e.target.value })} />
                              <label htmlFor="list6">Other</label>
                            </li>
                          </ul>
                        </ul>
                      </div>
                      <div className="content-title">Brief description</div>
                      <div className="description-container">
                        <textarea maxLength={2000} style={{ borderColor: ReportPostData?.report_description?.length > 2000 ? 'red' : null }} placeholder="Enter the details of reporting" onChange={(e) => setReportPostData({ ...ReportPostData, report_description: e.target.value })}></textarea>
                        <p className="text-end" style={{ fontSize: 12, color: ReportPostData?.report_description?.length > 2000 ? 'red' : null }}>{ReportPostData?.report_description?.length}/2000</p>
                      </div>
                      <div className="content-title">Upload images if available</div>
                      <div className="upload-image-container">
                        <FileUploader setImagePath={setImagePath} />
                      </div>
                      <Button disabled={Error?.status} className="button w-50 m-auto d-flex justify-content-center align-items-center text-center" onClick={() => !Error?.status ? submitReportPost() : null}>
                        Submit
                      </Button>
                    </div> :
                    <>
                      <Container className={`d-flex align-items-center justify-content-center ${css({
                        height: 'calc(100vh - 150px)'
                      })}`}>
                        <div className="text-center">
                          <BiError fontSize={95} color='rgba(108, 108, 108, 0.41)' />
                          <p style={{ color: 'rgba(108, 108, 108, 0.41)', fontWeight: '600' }}>Your kyc is pending for verification</p>
                        </div>
                      </Container>
                    </>
                }
              />
            }
          </div >
        </div >
        <div className="gallery">
          <div className="title">Land Images</div>
          <div className="gallery-container">
            <div className="slider">
              {
                PostData?.images?.length == 0 ?
                  <div className="d-flex justify-content-center w-100 align-items-center py-4 flex-column">
                    <IoImageOutline fontSize={45} color={'#6c6c6c'} />
                    <p className="color-text fw-semibold m-0">No Image Available</p>
                  </div> :
                  !PostData?.images ? null :
                    <ul className="Land-images" style={{ width: PostData?.images?.length === 1 ? 350 : null }}>
                      {PostData?.images.map(i => (
                        <li key={i?.image}>
                          <Image
                            src={i?.image}
                            style={{ objectFit: 'cover' }}
                            onClick={() => setModalShow({ ...modalShow, status: true, src: i?.image })}
                          />
                        </li>
                      ))}
                    </ul>
              }
              <LandImagesZoom
                show={modalShow?.status}
                onHide={() => setModalShow({ ...modalShow, status: false, src: null })}
                image={modalShow?.src}
              />
            </div>
          </div>
        </div>
        <div className="similar-posts">
          <h4>Similar Posts
            <Link reloadDocument to="/posts">
              <span className="right">View All
                <img src="img/right_arrow.svg" />
              </span>
            </Link>
          </h4>
          <SimilarPosts />
        </div >
      </div >
    )
  } else {

  }

}

export default Post_View;
