import React, { useContext } from "react";
import { Link } from "react-router-dom";
import globalContext from "../../context/GlobalContext";
import { encryptStorage } from "../EncryptStorage";


function Banner_Grid() {

  const FarmerData = encryptStorage.getItem('farmer_data');
  const contxt = useContext(globalContext)

  const find = (type) => {
    contxt.setFilters((prevState) => ({
      ...prevState,
      SearchTxt: type,
    }));
  };

  return (
    <div className="banner_grid">
      <div className="banner_item">
        <h1>
          <span className="highlight">No land for Farming?</span> Simply invest now
          in Farmers to increase your profits and contribute to Indian
          agriculture.
        </h1>
        <p>
          We must enable farmers to feed India and the world, let's start
          contributing and connect with the world's best agriculture service
          provider.
        </p>
        <p>
          <Link  to="/niveshak">
            <button className="btn btn-outline">Find Niveshak</button>
          </Link>   
            <Link  to="/farmers">
              <button className="btn btn-outline">Find Farmers</button>
            </Link>
          <a href="https://khetivalah.in/" target="_blank">
            <button className="btn btn-primary">Register</button>
          </a>
        </p>
      </div >
      <div className="banner_item">
        <img src="img/banner.png" alt="" />
      </div>
    </div >
  );
}

export default Banner_Grid;
