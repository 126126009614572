import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { encryptStorage } from "../EncryptStorage";
import { toast } from "react-toastify";

function Mudra() {
  const Profile_Data = encryptStorage.getItem("Profile_data");
  const [Mudrapts, setMudrapts] = useState();

  
  useEffect(() => {

    const url = `${process.env.REACT_APP_BASE_API}/farmer_app/mudra?farmer_id=${Profile_Data.id}`;

    axios.get(url)
    .then(function (response) {
      setMudrapts(response.data.data[0].mudra_points);
    })
    .catch(function (error) {
      setMudrapts(0);
    })
  
  }, [])
  
  return Mudrapts;
}

export default Mudra;
