import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import { encryptStorage } from '../../EncryptStorage';

const CropCategory = ({ setCropCategory }) => {
    const [CategoryList, setCategoryList] = useState([]);

    useEffect(() => {
        const url = `${process.env.REACT_APP_BASE_API}/admin_app/crop_category`;
        const fetch = async () => {
            await axios.get(url).then((response) => {
                setCategoryList(response.data.data);
            });
        };
        fetch();
    }, []);

    const clkdbox = (e) => {
        setCropCategory(e.target.value)
        encryptStorage.setItem('crop_category_filter', e.target.value)
    };
    return (
        <>
            <Form.Check
                onChange={(e) => clkdbox(e)}
                aria-label='All'
                type='radio'
                name='crop__category__Group'
                value=''
                label='All'
                id='radio-all'
                checked={encryptStorage.getItem('crop_category_filter')?false:true}
            />
            {CategoryList.map((data, index) => {
                return (
                    <Form.Check
                        onChange={(e) => clkdbox(e)}
                        key={index}
                        aria-label={data?.crop_category}
                        type='radio'
                        name='crop__category__Group'
                        id={`radio-${data?.crop_category}`}
                        checked={encryptStorage.getItem('crop_category_filter') === data?.primary_key}
                        value={data.primary_key}
                        label={data?.crop_category}
                    />
                )
            })}
        </>
    )
}

export default CropCategory