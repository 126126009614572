import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, Outlet } from "react-router-dom";

const RegisterRoute = () => {
    return (
        <Row className="position-absolute" style={{ backgroundColor: '#fff', zIndex: 999999, top: 0, left: 0, width: "100vw" }}>
            <Col lg={5} md={6} className="bg-img Col" style={{ padding: '30px 60px' }}>
                <Link to="/">
                    <div className="back">
                        <img src="/img/prev.svg" alt="" />
                    </div>
                </Link>
                <div className="title  fw-bold fs-1 lh-1 mb-3">
                    <span className="highlight">Great, </span>
                    You are about to grow your own Farming.
                </div>
                <p>
                    Join World Largest Farmer's Service Provider and get unlimited benefit's.
                </p>
                <div className="banner">
                    <img src="/img/register.svg" />
                </div>
            </Col>
            <Col lg={7} md={6} style={{ position: "relative", padding: "30px 60px" }} >
                <Row className="w-75 m-auto mt-5">
                    <Outlet />
                </Row>
            </Col>
        </Row>
    )
}

export default RegisterRoute