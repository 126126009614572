import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom";
import Posts_Card from "../cards/Posts_Card";
import axios from "axios";
import globalContext from '../../context/GlobalContext';
import { encryptStorage } from "../EncryptStorage";

function PostSlider() {
  const contxt = useContext(globalContext)
 
  const [CardList, setCardList] = useState([]);
  const [SlideNo, setSlideNo] = useState(0);
  const find = (type) => {
    contxt.setFilters((prevState) => ({
      ...prevState,
      SearchTxt: type,
    }));
  };
  useEffect(() => { console.log('post slider update')
     let url = `${process.env.REACT_APP_BASE_API}/farmer_app/cultivation_requests_list?page=1&status=published ${encryptStorage.getItem('state_filter') ? '&state_name=' + encryptStorage.getItem('state_filter') : ''}`;
    const fetch_data = async () => {
      await axios
        .get(url)
        .then((response) => {
          let res = response.data.data.requests_list;
          const list = res.map((item) => {
            return (
              <Posts_Card postid={item.primary_key} data={item} />
            );
          });

          setCardList(list);
        })
        .catch((error) => {
          setCardList(false);
        });
    };

    fetch_data();

  }, [encryptStorage.getItem("state_filter")])

  const prevslide = () => {
    let slideno = SlideNo - 4;
    if (slideno >= 0) {
      setSlideNo(slideno);
      let ele = document.getElementById('slide' + slideno);
      // ele.style.display = "block";
    }
  }

  const nextslide = () => {
    let slideno = SlideNo + 4;
    if (slideno < CardList.length) {
      setSlideNo(slideno);
      let ele = document.getElementById('slide' + slideno);
      // ele.style.display = "block";
    }
  }

  return (
    <div className="slider post_slider">
      <h1>
        Cultivation Posts by <span className="highlight">Farmers</span>
      </h1>
      <p>
        Browse latest posts to connect with farmers and niveshak.
        <Link to="/posts">
          <span className="view">
            View all <img src="img/right_arrow.svg" alt="" />
          </span>
        </Link>
        <span className="slider_btn">
          <img className="prev" src="img/prev.svg" onClick={prevslide} />
          <img className="next" src="img/next.svg" onClick={nextslide} />
        </span>
      </p>
      <div className='cards_container'>
      {CardList?<>
        {CardList[SlideNo]}
        {CardList[SlideNo + 1]}
        {CardList[SlideNo + 2]}
        {CardList[SlideNo + 3]}
        </>
        : 'No Posts'
      }  
      </div>
    </div >
  )
}

export default PostSlider