import React, { useEffect, useState, useContext } from "react";
import Chat from "./Chat";
import "./message.css";
import axios from "axios";
import { encryptStorage } from "../EncryptStorage";
import globalContext from "../../context/GlobalContext";
import Sender from "./Sender";
import Avatar from 'react-avatar';
import Deal from "./Deal";
import ScrollToBottom from 'react-scroll-to-bottom';
import { css } from '@emotion/css';
import moment from "moment/moment";
import { TbMessageOff } from "react-icons/tb";

const ROOT_CSS = css({
  height: '100%',
  width: '100%',
});
const View_Container = css({
  padding: 20
})
function Messaging() {
  const [ChatList, setChatList] = useState();
  const contxt = useContext(globalContext);
  const FarmerData = encryptStorage.getItem("farmer_data");
  const [Newid, setNewid] = useState(null);
  const [DealPop, setDealPop] = useState(false);
  const UserData = encryptStorage.getItem("UserData");
  const Connection_Thred = encryptStorage.getItem('connection_thred')
  const Chat_Connection = encryptStorage.getItem('ongoing_connectection')
  const [Messages, setMessages] = useState(null)
 

  const getchat = (send_to,for_post_id) => {

    let url = `${process.env.REACT_APP_BASE_API}/farmer_app/farmer_send_message?sender_farmer_id=${FarmerData.farmer_id}&receiver_farmer_id=${send_to}&cultivation_id=${for_post_id}`;

    const fetch_data = () => {
      if (Chat_Connection) {
        axios
          .get(url)
          .then((response) => {
            let res = response.data.data.reverse();
            //console.log(res)
            setMessages(res)
            let data = <Chat res={res} />
            setChatList(data);
          }).catch(() => {
            clearInterval(Newid)
            setChatList("No Chats");
          });
      }
    }
    fetch_data()
    clearInterval(Newid)
    setNewid(setInterval(fetch_data, 3000))
  }

  useEffect(() => {
    
    getchat(Chat_Connection?.send_to, Chat_Connection?.for_post_id);
    return () => clearInterval(Newid);
    console.log(Chat_Connection)
  }, [])

  const ReadMessages = (farmer_id, cultivation_id) => {
    var data = new FormData();
    data.append('message_to', FarmerData.farmer_id);
    data.append('message_from', farmer_id);
    data.append('cultivation_id', cultivation_id);

    var config = {
      method: 'put',
      url: `${process.env.REACT_APP_BASE_API}/farmer_app/farmer_send_message`,
      data: data
    };

    axios(config)
      .then(function () {
        console.log('OK')
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  const startconnection = (e, data) => {

    let img = e.target.querySelector('.icon img');
    if (img) {
      img = img.getAttribute('src');
    } else {
      img = ''
    }

    let obj = {
      send_to: data?.farmer_id,
      send_name: data?.farmer_name,
      for_post_id: data?.cultivation_id,
      post_title: data.crop_name + '-' + data.crop_category,
      send_to_img: img,
      cultivation_request_id: data?.cultivation_request_id,
      farmer_district: data?.farmer_district,
      farmer_state: data?.farmer_state,
      return: data?.investment_return_type
    }

    contxt.ongoing_connectection(obj);

    getchat(obj.send_to,obj.for_post_id)
    ReadMessages(data?.farmer_id, data?.cultivation_id)

  }

if(ChatList){
  return (
    <div className="container">
      <div className="massage_main">
        <div className="user_list">
          <div className="massage_text_filter">
            <ul>
              <li>
                <h2>Inbox</h2>
              </li>
            </ul>
          </div>
          {/* <div className="massage_tab_filter">
            <ul>
              <li className="active-tab">All</li>
              <li>Unread</li>
              <li>Read</li>
            </ul>
          </div> */}
          <div className="massage_list_filter">
            <ul>
            
                {Connection_Thred?.map((item, index) => (

                  <li className="conn-card" key={index} onClick={(e) => startconnection(e, item)}>
                    <div className="icon item">
                      {!item.farmer_image ?
                        <Avatar name={item?.farmer_name} maxInitials={2} size="85" textSizeRatio={2.5} style={{
                          border: '1px solid #ccc'
                        }} /> : <img src={item.farmer_image} style={{
                          objectFit: 'cover', width: 85, height: 85
                        }} />
                      }
                    </div>
                    <div className="content item" >
                      <h6><span className="fw-bold title message__box__title">{item.crop_name + '-' + item.crop_category}</span> 
                      <span className={item.unread_message_count?'unread-msg':''}>{item.unread_message_count?item.unread_message_count:null}</span>
                      </h6>
                      <span>Investment Budget:  <b>{item.expected_investment}</b></span>
                      <p className={`incoming__msg ${css({ fontWeight: item.unread_message_count === 0 ? null : '600' })}`} >{item.message_body}  - by <span className="farmer-name">{item.farmer_name}</span></p>
                    </div>
                    <div className="actions" >
                      {contxt.getdate(item.message_created)}
                    </div>
                  </li>
                
              ))}
            </ul>
          </div>
        </div>
        <div className={`chat-card `}>
          <div className="chat-card-header">
            <ul>
              <li>
                <div className="icon item">
                  {!Chat_Connection?.send_to_img ?
                    <Avatar name={Chat_Connection?.send_name} maxInitials={2} size="55" textSizeRatio={2.5} round style={{
                     border: '1px solid #ccc' 
                    }} /> : <img src={Chat_Connection?.send_to_img} style={{
                      objectFit: 'cover', width: 55, height: 55
                    }} />}
                </div>
                <div className="content item">
                  <h4>{Chat_Connection?.post_title} <span style={{ fontWeight: 'normal', fontSize: '12px' }}> - {Chat_Connection?.send_name}</span></h4>
                  <span>Post ID: {Chat_Connection?.cultivation_request_id}</span>
                </div>
                
                
                {/* {moment().diff(moment(Messages?.[0]?.message_updated), 'days') >= 1 ?
                  <> */}
                  <div className="actions">
                      {UserData?.farmer_type === 'Niveshak' ? <button onClick={() => setDealPop(true)}>Confirm Your Deal</button> : ''}
                  </div>
                  {/* </>
                   :
                  null} */} 
              </li>
            </ul>
          </div>
          <div className="chat-card-body" id="chatbox-scroll">
            <ScrollToBottom mode="bottom" scroller={() => Infinity} className={ROOT_CSS} scrollViewClassName={View_Container}>
              {ChatList}
            </ScrollToBottom>
          </div>
          <Sender />
        </div>
      </div>
      {DealPop && <Deal setDealPop={setDealPop} />}
    </div>
  );
}else{
  return <div style={{fontSize:'40px', color:'#ccc',textAlign:'center'}}>
  <TbMessageOff/> No Message
  </div>
  }
}

export default Messaging;
