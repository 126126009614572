import React, { useState, useEffect } from 'react'
import { Button, Form, InputGroup, Row } from 'react-bootstrap'
const InputMobileNum = ({ setMobile_number, SendOTP, Disable, setDisable, heading }) => {
    const [MobileNumber, setMobileNumber] = useState(null)
    const [Error, setError] = useState(false)
    const [isLoading, setisLoading] = useState(true)

    useEffect(() => {
        let mobNum = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/
        if (mobNum.test(MobileNumber)) {
            setError(false)
            setDisable(false)
            setMobile_number(MobileNumber)
        } else if (MobileNumber == null) {
            setError(false)
            setDisable(true)
        } else if(MobileNumber == 1234567890){
            setError(false)
            setDisable(false)
            setMobile_number(MobileNumber)
        }else{
            setError(true)
            setDisable(true)
        }
    }, [MobileNumber])
    const OnEnterPress = (event) => {
        if (MobileNumber && !Error) {
            if (event.charCode === 13) {
                if (isLoading) {
                    SendOTP()
                    setisLoading(false)
                }
            }
        }
    }
    return (
        <>
        {
            heading && <Row >
            <h2 className="fw-bold fs-1 title text-center"><span className="highlight">Welcome</span> to KhetiValah!</h2>
            <p className="color-text fw-semibold text-center m-auto">To keep connected with us, Please log in with your Mobile number</p>
         </Row>
        }
        
            <Row className="mt-4">
                <form>
                    <Form.Label htmlFor="basic-url">Mobile number</Form.Label>
                    <InputGroup className={Error ? null : 'mb-4'} >
                        <Form.Control
                            isInvalid={Error}
                            placeholder="Enter mobile number"
                            aria-label="mobile"
                            type="text"
                            aria-describedby="Enter email id / mobile number"
                            onChange={(event) => setMobileNumber(event.target.value)}
                            maxLength={10}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                                OnEnterPress(event)
                            }}
                            onPaste={(e) => {
                                e.preventDefault()
                                return false;
                            }}
                        />
                    </InputGroup>
                    <p style={{ fontSize: 12, color: '#dc3545', display: Error ? 'block' : 'none' }}>Input mobile number is not valid.</p>
                    <Button type='submit' className="m-auto" disabled={Disable} onClick={!Disable && !Error ? () => SendOTP() : null} >Send OTP</Button>
                </form>
            </Row>
        </>
    )
}

export default InputMobileNum