import { css } from '@emotion/css';
import moment from 'moment';
import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { ImUserCheck } from 'react-icons/im'
import { IoCheckmarkCircle, IoCloseCircleSharp, IoChatbubblesSharp } from 'react-icons/io5'
import { BsFillFileEarmarkCheckFill, BsFillFileEarmarkPostFill } from 'react-icons/bs'
export const TimelineComponent = ({ Data, Deal_Data }) => {
    console.log(Data)
    if (Data) {
        return (
            <>
                <VerticalTimeline layout='1-column-left' lineColor='rgb(46, 95, 43)'>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(228 241 227)', color: '#333333' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(228 241 227)' }}
                        date={moment(Data?.post_creation_date).calendar()}
                        iconStyle={{ background: 'rgb(228 241 227)', color: '#333333' }}
                        icon={<BsFillFileEarmarkPostFill />}
                    >
                        <h4 className="vertical-timeline-element-subtitle fw-bold fs-6">Requirement first posted</h4>
                        <p className={css({ fontSize: '12px !important' })}>Post has been approved and published for niveshak to investment into {Deal_Data?.crop_name} - {Deal_Data?.crop_category}.</p>
                    </VerticalTimelineElement>
                    {Data?.first_connection_date && <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(228 241 227)', color: '#333333' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(228 241 227)' }}
                        date={moment(Data?.first_connection_date).calendar()}
                        iconStyle={{ background: 'rgb(228 241 227)', color: '#333333' }}
                        icon={<IoChatbubblesSharp />}
                    >
                        <h4 className="vertical-timeline-element-subtitle fw-bold fs-6">Niveshak connected</h4>
                        <p className={css({ fontSize: '12px !important' })}>Niveshak approched with investment amount {Deal_Data?.invested_amount}.</p>
                    </VerticalTimelineElement>}

                    {Data?.deal_requested_date && <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(228 241 227)', color: '#333333' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(228 241 227)' }}
                        date={moment(Data?.deal_requested_date).calendar()}
                        iconStyle={{ background: 'rgb(228 241 227)', color: '#333333' }}
                        icon={<ImUserCheck />}
                    >
                        <h4 className="vertical-timeline-element-subtitle fw-bold fs-6">Deal confirmed from niveshak</h4>
                        <p className={css({ fontSize: '12px !important' })}>Niveshak confirmed their deal for {Deal_Data?.crop_name} - {Deal_Data?.crop_category} for amount {Deal_Data?.invested_amount}.</p>
                    </VerticalTimelineElement>}

                    {Data?.farmer_approval_date && <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(228 241 227)', color: '#333333' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(228 241 227)' }}
                        date={moment(Data?.farmer_approval_date).calendar()}
                        iconStyle={{ background: 'rgb(228 241 227)', color: '#333333' }}
                        icon={<IoCheckmarkCircle />}
                    >
                        <h4 className="vertical-timeline-element-subtitle fw-bold fs-6">Farmer approval for investment</h4>
                        <p className={css({ fontSize: '12px !important' })}>Farmer has confirmed niveshak investment for {Deal_Data?.crop_name} - {Deal_Data?.crop_category} of amount {Deal_Data?.invested_amount}.</p>
                    </VerticalTimelineElement>}

                    {Data?.farmer_rejected_date && <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(228 241 227)', color: '#333333' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(228 241 227)' }}
                        date={moment(Data?.farmer_rejected_date).calendar()}
                        iconStyle={{ background: 'rgb(228 241 227)', color: '#333333' }}
                        icon={<IoCloseCircleSharp />}
                    >
                        <h4 className="vertical-timeline-element-subtitle fw-bold fs-6">Farmer rejected investment deal</h4>
                        <p className={css({ fontSize: '12px !important' })}>Farmer has rejected niveshak investment deal for {Deal_Data?.crop_name} - {Deal_Data?.crop_category}.</p>
                    </VerticalTimelineElement>}

                    {Data?.certificate_release_date && <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(228 241 227)', color: '#333333' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(228 241 227)' }}
                        date={moment(Data?.certificate_release_date).calendar()}
                        iconStyle={{ background: 'rgb(228 241 227)', color: '#333333' }}
                        icon={<BsFillFileEarmarkCheckFill />}
                    >
                        <h4 className="vertical-timeline-element-subtitle fw-bold fs-6">Ivestment certificate released</h4>
                        <p className={css({ fontSize: '12px !important' })}>Deal has been settled for {Deal_Data?.crop_name} - {Deal_Data?.crop_category}, with niveshak on amount {Deal_Data?.invested_amount}.</p>
                    </VerticalTimelineElement>}
                </VerticalTimeline>
            </>
        )
    }
}
