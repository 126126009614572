import { css } from "@emotion/css";
import axios from "axios";
import FormData from "form-data";
import moment from "moment/moment";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DragNDrop from "../Drag-n-Drop";
import { encryptStorage } from "../EncryptStorage";
import Spinner from "../Spinner";
import ViewRegisterDetails from "./ViewRegisterDetails";
import { Image } from 'semantic-ui-react'
import FileUploader from "../KYC file upload/File Uploader";

const ErrorField = css({
  fontSize: 12,
  color: "#f00",
});
const RegisterUser = () => {

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  let navigate = useNavigate();
  var data = new FormData();
  const Farmer_data = encryptStorage.getItem("farmer_data");
  const UserData = encryptStorage.getItem("UserData");
  const [DistrictData, setDistrictData] = useState(null);
  const [VillageData, setVillageData] = useState(null);
  const [Assembly, setAssembly] = useState(null);
  const [Mandal, setMandal] = useState(null);
  const [FarmerImage, setFarmerImage] = useState(null);
  const [StateData, setStateData] = useState(null);
  const [isLoading, setisLoading] = useState(true);
  console.log('Farmer___DATA', UserData);
  const [Blob, setBlob] = useState(FarmerImage ? FarmerImage[0] ? FarmerImage[0].preview : null : null);
  const [FarmerData, setFarmerData] = useState({
    farmer_id: Farmer_data?.farmer_id,
    mobile_number: encryptStorage.getItem("Mob"),
    privacy_policy_agreed: UserData?.privacy_policy_agreed ? true : false,
    address_geolocation: [
      encryptStorage.getItem("Latitude"),
      encryptStorage.getItem("Longitude"),
    ],
  });
  const RouteRedirect = () => {
    if (UserData?.farmer_type === 'Farmer') {
      navigate('/register/farmer-kyc-verification');
    } else if (UserData?.farmer_type === 'Niveshak') {
      navigate('/register/investor-kyc-verification');
    }
  }
  const validateKYC = () => {
    if (UserData?.farmer_type === "Farmer") {
      let path = "/";
      return UserData?.farmer_kyc_status === "Completed"
        ? navigate(path)
        : UserData?.mandal_name
          ? navigate("/register/farmer-kyc-verification")
          : null;
    } else {
      let path = "/";
      return UserData?.would_be_farmer_kyc_status === "Completed "
        ? navigate(path)
        : UserData?.mandal_name
          ? navigate("/register/investor-kyc-verification")
          : null;
    }
  };

  useLayoutEffect(() => {
    // validateKYC();
    if (encryptStorage.getItem("Authenticated") === undefined || false) {
      let path = `/signin`;
      navigate(path, { replace: true });
    }

    setisLoading(true);

    var configState = {
      method: "get",
      url: `${process.env.REACT_APP_API_BASE_URL}/admin_app/states`,
      headers: {},
    };

    axios(configState)
      .then(function (response) {
        setStateData(response?.data?.data);
        encryptStorage.setItem("state", response?.data?.data);
        setisLoading(false);
      })
      .catch(function () {
        setisLoading(false);
      });

  }, []);

  const OnStateChange = (event) => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_BASE_URL}/admin_app/districts?state_name=${event.target.value}`,
      headers: {},
    };
    axios(config)
      .then(function (response) {
        setDistrictData(response?.data?.data);
      })
      .catch(function () { });
  };

  const OnDistrictChange = (event) => {

    console.log(event.target.value)

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_BASE_URL}/admin_app/assemblies?district_name=${event.target.value}`,
      headers: {},
    };
    axios(config)
      .then(function (response) {
        setAssembly(response?.data?.data);
      })
      .catch(function () { });
  };

  const OnAssemblyChange = (event) => {
    var configAssembly = {
      method: "get",
      url: `${process.env.REACT_APP_API_BASE_URL}/admin_app/mandals?assembly_name=${event.target.value}`,
      headers: {},
    };

    axios(configAssembly)
      .then(function (response) {
        setMandal(response?.data?.data);
        setisLoading(false);
      })
      .catch(function () { });
  }

  const OnMandalChange = (event) => {
    var configMandal = {
      method: "get",
      url: `${process.env.REACT_APP_API_BASE_URL}/admin_app/villages?mandal_name=${event.target.value}`,
      headers: {},
    };

    axios(configMandal)
      .then(function (response) {
        setVillageData(response?.data?.data);
      })
      .catch(function () { });
  }

  const routeChange = (response) => {
    if (response?.data?.data?.farmer_type === "Farmer") {
      navigate("farmer-kyc-verification");
    } else {
      navigate("investor-kyc-verification");
    }
    // window.location.reload()
  };


  const RegisterFarmer = (Data) => {

    if (FarmerImage?.[0]) { data.append("farmer_image", FarmerImage?.[0]) }

    data.append("farmer_name", Data?.farmerFullName);
    data.append("mobile_number", FarmerData?.mobile_number);
    data.append("email_id", Data?.farmerEmailAddress);
    data.append("gender", Data?.farmerGender);
    data.append(
      "data_of_birth",
      moment(Data?.farmerDateOfBirth).format("DD-MM-YYYY")
    );
    data.append("farmer_type", Data?.farmerRegistrationType);
    data.append("state_name", Data?.farmerRegistrationState);
    data.append("district_name", Data?.farmerRegistrationDistrict);
    data.append("village_name", Data?.farmerRegistrationCity);
    data.append("assembly_name", Data?.farmerRegistrationAssembly);
    data.append("mandal_name", Data?.farmerRegistrationMandal);
    data.append(
      "privacy_policy_agreed",
      Data?.farmerPrivacyPolicyChecked ? "True" : "False"
    );
    data.append("address_geolocation", `[${FarmerData.address_geolocation}]`);
    data.append("farmer_id", FarmerData.farmer_id);

    var config = {
      method: "put",
      url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/farmer_registration`,
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios(config)
      .then(function (response) {
        toast.success(response?.data?.message);
        console.log(response);
        encryptStorage.setItem(
          "UserData",
          JSON.stringify(response?.data?.data)
        );
        routeChange(response);
      })
      .catch(function (e) {
        toast.warning(e.message);
        console.log(e);
      });
  };
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Row>
            <p className="fs-2 text-center  fw-bold">
              Complete KhetiValah Profile
            </p>
            <p className="fs-6 text-center color-text">
              Create your account by filling the form below,Please fill the
              information correctly, it's important to profile verification
              process.
            </p>
          </Row>
          <hr />
          {UserData?.state_name ? (
            <ViewRegisterDetails />
          ) : (
            <>
              <form onSubmit={handleSubmit(RegisterFarmer)}>
                <Row>
                  <p className="fs-6 text-start">Please upload your image</p>

                  <FileUploader
                    register={register}
                    ErrorField={ErrorField}
                    errors={errors}
                    setImagePath={setFarmerImage}
                  />
                </Row>
                <Row>
                  {/* <Form.Label htmlFor="basic-url" className="mt-3">
                    Upload Profile Photo
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      placeholder="Upload Jpg, Png or Jpeg"
                      aria-label="pan-card"
                      type="file"
                      aria-describedby="Upload photo"
                      accept=".png, .jpg, .jpeg"
                      {...register("farmerImage", {
                        // required: {
                        //   value: false,
                        //   message: "",
                        // },
                      }
                      )}
                      onChange={(event) => {
                        console.log(event.target.files[0].type)
                      }}
                    />
                  </InputGroup>
                  <span className={ErrorField}>
                    { }
                  </span> */}

                  <Form.Label htmlFor="basic-url" className="mt-3">
                    Full name* (as per PAN Card){" "}
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      placeholder="Enter name"
                      aria-label="pan-card"
                      type="text"
                      aria-describedby="Enter name"
                      {...register("farmerFullName", {
                        required: {
                          value: true,
                          message: "Name should not be empty.",
                        },
                        maxLength: {
                          value: 30,
                          message: "Maximum 30 characters are allowed.",
                        }
                      })}
                      onKeyPress={(event) => {
                        if (/[^a-zA-Z ]+/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </InputGroup>
                  <span className={ErrorField}>
                    {errors?.farmerFullName?.message}
                  </span>
                  <Form.Label htmlFor="basic-url" className="mt-3">
                    Mobile Number*
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Mobile number"
                      aria-label="Mobile number"
                      type="text"
                      disabled
                      defaultValue={`+91-${FarmerData?.mobile_number}`}
                      aria-describedby="Mobile number"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      maxLength={10}
                    />
                  </InputGroup>
                  <Form.Label htmlFor="basic-url">Email address</Form.Label>
                  <InputGroup>
                    <Form.Control
                      placeholder="Email address"
                      aria-label="Email address"
                      type="email"
                      aria-describedby="Email address"
                      {...register("farmerEmailAddress", {
                        pattern: {
                          value:
                            /^(([^<>()\[\]\\.,;#:\s@"]+(\.[^<>()\[\]\\.,;#:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Email address format is not valid.",
                        },
                        // required: {
                        //     value: true,
                        //     message: 'Email address is a required field.'
                        // },
                      })}
                    />
                  </InputGroup>
                  <span className={ErrorField}>
                    {errors?.farmerEmailAddress?.message}
                  </span>
                  <Row className="justify-content-center pe-0">
                    <Col className="pe-0">
                      <Form.Label htmlFor="basic-url" className="mt-3">
                        Date of birth
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="datepic"
                        placeholder="Date of birth"
                        {...register("farmerDateOfBirth", {
                          // required: {
                          //     value: true,
                          //     message: 'Date of birth is a required field.'
                          // }
                        })}
                        style={{ width: "100%", display: "flex" }}
                      />
                    </Col>
                  </Row>
                  <span className={ErrorField}>
                    {errors?.farmerDateOfBirth?.message}
                  </span>
                  <Row className="pe-0 mb-2 mt-3">
                    <Col>
                      <Form.Label htmlFor="basic-url">Gender*</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        {...register("farmerGender", {
                          required: {
                            value: true,
                            message: "Gender is a required field.",
                          },
                        })}
                      >
                        <option value="">Select your gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Others">Others</option>
                      </Form.Select>
                      <span className={ErrorField}>
                        {errors?.farmerGender?.message}
                      </span>
                    </Col>
                    <Col className="pe-0">
                      <Form.Label htmlFor="basic-url">
                        You want to register as*
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        {...register("farmerRegistrationType", {
                          required: {
                            value: true,
                            message: "Please select your registration type.",
                          },
                        })}
                      >
                        <option value="">Select your choice</option>
                        <option value="Farmer">Farmer</option>
                        <option value="Niveshak">Niveshak</option>
                      </Form.Select>
                      <span className={ErrorField}>
                        {errors?.farmerRegistrationType?.message}
                      </span>
                    </Col>
                  </Row>
                  <Row className="pe-0 mb-2">
                    <Col>
                      <Form.Label htmlFor="basic-url">State*</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        {...register("farmerRegistrationState", {
                          required: {
                            value: true,
                            message: 'Select your state of registration.'
                          },
                        })}
                        onChange={(event) => OnStateChange(event)}
                      >
                        <option value="">Select your state</option>
                        {!StateData
                          ? null
                          : StateData.map((state, index) => (
                            <option key={index} value={state?.primary_key}>
                              {state?.state_name}
                            </option>
                          ))}
                      </Form.Select>
                      <span className={ErrorField}>
                        {errors?.farmerRegistrationState?.message}
                      </span>
                    </Col>
                    <Col className="pe-0">
                      <Form.Label htmlFor="basic-url">District</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        {...register("farmerRegistrationDistrict", {
                          // required: {
                          //     value: true,
                          //     message: 'Select your district of registration.'
                          // },
                        })}
                        onChange={(event) => OnDistrictChange(event)}
                      >
                        <option value="">Select your district</option>
                        {!DistrictData
                          ? null
                          : DistrictData.map((district, index) => (
                            <option key={index} value={district?.primary_key}>
                              {district?.district_name}
                            </option>
                          ))}
                      </Form.Select>
                      <span className={ErrorField}>
                        {errors?.farmerRegistrationDistrict?.message}
                      </span>
                    </Col>
                  </Row>
                  <Row className="justify-content-center pe-0">
                    <Col className="pe-0 mb-2">
                      <Form.Label htmlFor="basic-url">Assembly</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        {...register("farmerRegistrationAssembly", {
                          // required: {
                          //     value: true,
                          //     message: 'Select your assembly of registration.'
                          // },
                        })}
                        onChange={(event) => OnAssemblyChange(event)}
                      >
                        <option value="">Select your assembly</option>
                        {!Assembly
                          ? null
                          : Assembly.map((data, index) => (
                            <option key={index} value={data?.primary_key}>
                              {data?.assembly_name}
                            </option>
                          ))}
                      </Form.Select>
                      <span className={ErrorField}>
                        {errors?.farmerRegistrationAssembly?.message}
                      </span>
                    </Col>
                    <Col className="pe-0">
                      <Form.Label htmlFor="basic-url">Mandal</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        {...register("farmerRegistrationMandal", {
                          // required: {
                          //     value: true,
                          //     message: 'Select your mandal of registration.'
                          // },
                        })}
                        onChange={(event) => OnMandalChange(event)}
                      >
                        <option value="">Select your mandal</option>
                        {!Mandal
                          ? null
                          : Mandal.map((data, index) => (
                            <option key={index} value={data?.primary_key}>
                              {data?.mandal_name}
                            </option>
                          ))}
                      </Form.Select>
                      <span className={ErrorField}>
                        {errors?.farmerRegistrationMandal?.message}
                      </span>
                    </Col>

                  </Row>
                  <Row className="justify-content-center pe-0">
                    <Col>
                      <Form.Label htmlFor="basic-url">
                        Village / City
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        {...register("farmerRegistrationCity", {
                          // required: {
                          //     value: true,
                          //     message: 'Select your village/city of registration.'
                          // },
                        })}
                      >
                        <option value="">Select your village/city</option>
                        {!VillageData
                          ? null
                          : VillageData.map((village, index) => (
                            <option key={index} value={village?.primary_key}>
                              {village?.village_name}
                            </option>
                          ))}
                      </Form.Select>
                      <span className={ErrorField}>
                        {errors?.farmerRegistrationCity?.message}
                      </span>
                    </Col>

                  </Row>
                  <Row className="mt-3">
                    <Form.Check
                      type="checkbox"
                      id={`default-privacy-policy`}
                      label="Agree to our privacy policy by clicking checkbox"
                      {...register("farmerPrivacyPolicyChecked", {
                        required: {
                          value: true,
                          message:
                            "Agree to privacy policy is required to continue.",
                        },
                      })}
                    />
                    <span className={ErrorField}>
                      {errors?.farmerPrivacyPolicyChecked?.message}
                    </span>
                  </Row>
                </Row>
                <div className="d-flex m-auto mt-3 justify-content-center">
                  <Button
                    type="submit"
                    className="bg-green"
                    variant="secondary m-0"
                  >
                    Signup
                  </Button>
                </div>
              </form>
            </>
          )}
        </>
      )}
    </>
  );
};

export default RegisterUser;
