import { css } from '@emotion/css';
import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { encryptStorage } from '../../EncryptStorage';

const ErrorField = css({
    fontSize: 12,
    color: '#f00'
})

const KYCInvestorForm = ({ register, errors }) => {
    const UserData = encryptStorage.getItem('UserData')
    return (
        <>
            <Form.Label htmlFor="basic-url" className='p-0'>Intrested in investing*</Form.Label>
            <Form.Select aria-label="Default select example"
                {...register("investorInterest", {
                    required: {
                        value: true,
                        message: 'Intrested in investment is a required field.'
                    }
                })
                }>
                <option>Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </Form.Select>
            <span className={ErrorField}>
                {errors?.investorInterest?.message}
            </span>
            <Form.Label htmlFor="basic-url" className='p-0 mt-3'>Investment budget</Form.Label>
            <InputGroup className=" p-0">
                <Form.Control
                    placeholder="Enter amount"
                    aria-label="amount-card"
                    type="text"
                    aria-describedby="Enter document number"
                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                    {...register("investorInvestmentBudget", {
                        required: {
                            value: false,
                            message: 'Investment budget is a required field.'
                        },
                        validate: {
                            positive: v => parseInt(v) >= 10000 || 'Please make sure your investment amount should be in ₹10000 - ₹50000 range.',
                            lessThanTen: v => parseInt(v) <= 50000 || 'Please make sure your investment amount should be in ₹10000 - ₹50000 range.',
                        }
                    })
                    } />
            </InputGroup>
            <span className={ErrorField}>
                {errors?.investorInvestmentBudget?.message}
            </span>
            <Form.Label htmlFor="basic-url" className='p-0 mt-3'>Investment return type*</Form.Label>
            <Form.Select aria-label="Default select example"
                {...register("investorInvestmentReturnType", {
                    required: {
                        value: true,
                        message: 'Investment return type is a required field.'
                    }
                })
                }>
                <option value=''>Select your return type</option>
                <option value="Crop">Crop</option>
                <option value="Interest">Interest</option>
            </Form.Select>
            <span className={ErrorField}>
                {errors?.investorInvestmentReturnType?.message}
            </span>
            <Form.Label htmlFor="basic-url" className='p-0 mt-3'>About you*</Form.Label>
            <InputGroup className="mb-3 p-0">
                <Form.Control placeholder='About yourself' as="textarea" maxLength={4000}
                    {...register("investerDescription", {
                        required: {
                            value: true,
                            message: 'Please write a breif description about yourself.'
                        }, minLength: {
                            value: 20,
                            message: 'Minimum 20 characters is required.'
                        }, maxLength: {
                            value: 4000,
                            message: 'Maximum 4000 characters are allowed.'
                        }
                    })}
                    aria-label="about you"
                    style={{ height: 150 }}
                />
            </InputGroup>
            <span className={ErrorField}>
                {errors?.investerDescription?.message}
            </span>
        </>
    )
}

export default KYCInvestorForm