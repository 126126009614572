import React,{useEffect} from "react";
import FarmerSlider from "../sliders/FarmerSlider";
import InvestorSlider from "../sliders/InvestorSlider";
import PostSlider from "../sliders/PostSlider";
import Banner_Grid from "./Banner_Grid";
import Bottom_banner from "./Bottom_banner";
import How_Farmers from "./How_Farmer";
import How_Investor from "./How_Investor";
import Icon_Grid from "./Icon_Grid";
import FacebookFeed from "../Social feed/facebookFeed";
import ImageGallery from "../Gallery/ImageGallery";
import TwitterFeed from "../Social feed/TwitterFeed";
import { Col, Row } from "react-bootstrap";
import YouTubeFeed from "../Social feed/YouTubeFeed";
import BlogSlider from "../Blogs/BlogSlider";
import { encryptStorage } from "../EncryptStorage";
import { useNavigate } from "react-router-dom";

function Home() {
  let navigate = useNavigate();
  useEffect(() => {
    const UserData = encryptStorage.getItem('UserData');

    if(UserData){
      console.log(UserData)
      if(UserData.farmer_id && !UserData.farmer_name){  
        navigate('/register');
      }
    }

  }, [])
  
  return (
    <div className="container home">
      <Icon_Grid />
      <Banner_Grid />
      <PostSlider />
      <How_Farmers />
      <InvestorSlider />
      <How_Investor />
      <FarmerSlider />
      <Bottom_banner />
      <div style={{margin:50, textAlign:'center'}}>
        <h1>KhetiValah Success Stories</h1><br/>
        <ImageGallery />
      </div>
      <div style={{ marginTop: 50 }}>
        <div className="d-flex justify-content-center my-3">
          <h1><span className="highlight">KhetiValah </span>on Social Media</h1>
        </div>
        <Row>
          <Col xs={4}>
            <FacebookFeed />
          </Col>
          <Col xs={4}>
            <TwitterFeed />
          </Col>
          <Col xs={4}>
            <YouTubeFeed />
          </Col>

        </Row>
      </div>
      <div>
        
        <BlogSlider />
      </div>
    </div>
  );
}

export default Home;
