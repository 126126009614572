import React, { useState, useEffect, useContext } from 'react'
import Posts_Card from "../cards/Posts_Card";
import globalContext from "../../context/GlobalContext";
import axios from "axios";

function SimilarPosts() {

  const [CardList, setCardList] = useState(null);
  const contxt = useContext(globalContext);

  useEffect(() => {

    let url = `${process.env.REACT_APP_BASE_API}/farmer_app/cultivation_requests_list?page=1&status=published${contxt.Filters.Location ? "&state_name=" + contxt.Filters.Location : ""}`;

    const fetch_data = async () => {
      await axios
        .get(url)
        .then((response) => {
          let res = response.data.data.requests_list;
          const list = res.map((item) => {
            return (
              <Posts_Card key={item.primary_key} data={item} />
            );
          });
          setCardList(list);
        })
        .catch((error) => {
          setCardList("No Results");
        });
    };

    fetch_data();

  }, [])
  return (

    <div className='cards_container'>
      {/* {CardList} */}
      {CardList && Object.entries(CardList).slice(0, 4).map(entry => entry[1])}
    </div>

  )
}

export default SimilarPosts