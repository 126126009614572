import React, { useEffect, useState, useContext } from 'react';
import { encryptStorage } from "../EncryptStorage";
import axios from 'axios';
import globalContext from "../../context/GlobalContext";
import { BsChatLeftText } from 'react-icons/bs';

function NewMsg() {

  const FarmerData = encryptStorage.getItem("farmer_data");
  const [Unread, setUnread] = useState();

  useEffect(() => {
    const url = `${process.env.REACT_APP_BASE_API}/farmer_app/farmer_send_message?farmer_id=${FarmerData.farmer_id}&page=1`;

    const fetch_data = async () => {

      await axios
        .get(url)
        .then((response) => {

          encryptStorage.setItem('connection_thred', response.data.data)

         // console.log(response.data.data)

          if(!encryptStorage.getItem('ongoing_connectection')){

            encryptStorage.setItem('ongoing_connectection', {
              send_to: response.data.data[0].farmer_id,
              send_name: response.data.data[0].farmer_name,
              for_post_id: response.data.data[0].cultivation_id,
              post_title: response.data.data[0].crop_name + '-' + response.data.data[0].crop_category,
              send_to_img: response.data.data[0].farmer_image,
              cultivation_request_id: response.data.data[0].cultivation_request_id,
              farmer_district: response.data.data[0].farmer_district,
              farmer_state: response.data.data[0].farmer_state,
              return: response.data.data[0].return
            })
        }
        
          setUnread(response.data.pages.total_unread_messages);
        })
        .catch((error) => {
          console.log('find data function: ' + error.message);
        });
    };

    
    fetch_data();

  }, [encryptStorage.getItem('ongoing_connectection')])

  return (
    <span className="icon message">
      <span style={{ fontSize: 20 }}><BsChatLeftText /></span>
      {Unread ? <span className="num">{Unread}</span> : ''}
    </span>
  );
}

export default NewMsg;
