import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AiOutlineMobile, AiOutlineMail } from 'react-icons/ai'
import { GrFacebookOption, GrTwitter, GrYoutube, GrLinkedinOption, GrPinterest } from 'react-icons/gr'
import { IoLogoInstagram, IoLogoFacebook } from 'react-icons/io5'
import globalContext from "../context/GlobalContext";
import { Container, Row } from "react-bootstrap";
import { ReactComponent as Instagram } from '../images/Icons/instagram.svg';
import { SiQuora } from 'react-icons/si'
import { encryptStorage } from "./EncryptStorage";

function Footer() {
  const contxt = useContext(globalContext);
  const UserData = encryptStorage.getItem('UserData');

  return (
    <footer>
      <Container>
        <Row>
          <div className="col">
            <img src="/img/KhetiValah.png" alt="" style={{ width: 120 }} />
            <p>Work with the largest network of independent Farmer's / Worker's and Niveshak get things done from quick turnarounds to big transformation</p>
          </div>
          <div className="col">
            <h5><b>Company</b></h5>
            <ul>
              <li><a href="https://khetivalah.in/" target="_blank" rel="noreferrer">About us</a></li>
              <li><a href="./khetivalah_privacy_policy.html" target="_blank" rel="noreferrer">Policy</a></li>
              <li><a href="./khetivalah_privacy_policy.html" target="_blank" rel="noreferrer">Terms & Conditions</a></li>
              <li><a href="https://khetivalah.in/" target="_blank" rel="noreferrer">Contact us</a></li>
            </ul>
          </div>
          <div className="col">
            <h5><b>Resources</b></h5>
            <ul>
              <li ><Link reloadDocument to="/posts">Browse Posts</Link></li>
              <li ><Link reloadDocument to="/niveshak">Browse Niveshak</Link></li>
              <li ><Link reloadDocument to="/farmers">Browse Farmers</Link></li>
              {UserData?.farmer_type === 'Farmer' ? <><li><Link reloadDocument to="/post-your-need">Post Your Need</Link></li></> : null}
            </ul>
          </div>
          <div className="col">
            <h5><b>Contact Us</b></h5>
            <ul>
              <li><a href="mailto:contactus@khetivalah.com">Email: contactus@khetivalah.com</a></li>

              <li><a href="tel:+919440906666">Phone: +91-9440906666</a></li>
              <li>Haladhari Technologies Pvt. Ltd.</li>
              <li>Bangalore, Karnataka - 560078.</li>
              <li>Hyderabad, Gurugram, India.</li>
            </ul>
          </div>
          <div className="col">
            <h5>Follow us </h5>
            <div className="social">

              <span className='topbar-icon-nav'>
                <a href="https://www.facebook.com/KhetiValah/" target="_blank" rel="noreferrer">
                  <GrFacebookOption />
                </a>
              </span>
              <span className='topbar-icon-nav'>
                <a href="https://twitter.com/kethiValah" target="_blank" rel="noreferrer">
                  <GrTwitter />
                </a>
              </span>
              <span className='topbar-icon-nav'>
                <a href="https://www.instagram.com/khetiValah/" target="_blank" rel="noreferrer">
                  <IoLogoInstagram />
                </a>
              </span>
              <span className='topbar-icon-nav'>
                <a href="https://www.youtube.com/@khetivalah" target="_blank" rel="noreferrer">
                  <GrYoutube />
                </a>
              </span>
              <span className='topbar-icon-nav'>
                <a href="https://www.linkedin.com/company/khetivalah/" target="_blank" rel="noreferrer">
                  <GrLinkedinOption />
                </a>
              </span>
              <span className='topbar-icon-nav'>
                <a href="http://www.pinterest.com/khetivalah" target="_blank" rel="noreferrer">
                  <GrPinterest />
                </a>
              </span>
              <span className='topbar-icon-nav'>
                <a href="https://www.quora.com/KhetiValah" target="_blank" rel="noreferrer">
                  <SiQuora />
                </a>
              </span>
            </div>
          </div>
        </Row>
        <hr />
        <Row className="justify-content-center">
          <p className="text-center">© 2024 Haladhari Technologies Pvt. Ltd. All Rights Reserved.</p>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
