import React, { useContext } from 'react'
import { Form } from 'react-bootstrap';
import { encryptStorage } from '../../EncryptStorage';

const Gender = [
    { id: "1", label: "Female" },
    { id: "2", label: "Male" },
]
const GenderFilter = ({ setGender }) => {

    const clkdbox = (e) => {
        setGender(e.target.value)
        encryptStorage.setItem('gender_filter', e.target.value)
        console.log(e.target.value)
    };
    return (
        <>
            <Form.Check
                onChange={(e) => clkdbox(e)}
                aria-label='All'
                type='radio'
                name='gender__Group'
                id='radio-all'
                checked={encryptStorage.getItem('gender_filter')?false:true}
                value=''
                label='All'
            />
            {Gender.map((data, index) => {
                return (
                    <Form.Check
                        onChange={(e) => clkdbox(e)}
                        key={index}
                        aria-label={data?.label}
                        type='radio'
                        name='gender__Group'
                        id={`radio-${data?.label}`}
                        checked={encryptStorage.getItem('gender_filter') === data?.label}
                        value={data.label}
                        label={data?.label}
                    />
                )
            })}
        </>
    )
}

export default GenderFilter