import { css } from '@emotion/css'
import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Row } from 'react-bootstrap'
import Carousel from 'react-multi-carousel-18'
import BlogSliderCard from './BlogSliderCard'

const BlogSlider = () => {

    const [AllBlogs, setAllBlogs] = useState([])
    const GetBlogs = async () => {
        try {
            let res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API}/farmer_app/blog`,
                headers:
                {
                    "Content-Type": "Content-Type: multipart/form-data"
                }
            })
            console.log('dfg'+res?.data?.data)
            return setAllBlogs(res?.data?.data)
            
        } catch (error) {
            // return encryptStorage.setItem('qs_posts', []) 
           // console.log(error)
           
        }
    }
    useEffect(() => {

        GetBlogs()
    }, [])

    
    return (
        <>
         
            <Row>
                <Carousel
                    additionalTransfrom={0}
                    arrows
                    autoPlaySpeed={3000}
                    className=""
                    containerClass="container"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside
                    responsive={{
                        desktop: {
                            breakpoint: {
                                max: 3000,
                                min: 1024
                            },
                            items: 3,
                            partialVisibilityGutter: 100
                        },
                        mobile: {
                            breakpoint: {
                                max: 464,
                                min: 0
                            },
                            items: 1,
                            partialVisibilityGutter: 30
                        },
                        tablet: {
                            breakpoint: {
                                max: 1024,
                                min: 464
                            },
                            items: 3,
                            partialVisibilityGutter: 30
                        }
                    }}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    showDots={false}
                    sliderClass={css({ marginBottom: 50 })}
                    slidesToSlide={2}
                    swipeable
                >
                    {AllBlogs?.map((element, index) => {
                        return (
                            <BlogSliderCard key={index} Data={element} />
                        )
                    })}
                </Carousel>
            </Row>
        </>
    )
}

export default BlogSlider