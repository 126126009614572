import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Investor_Card from "../cards/Investor_Card";
import Farmer_Card from "../cards/Farmer_Card";
import axios from "axios";

function SimilarProfiles(props) {

  let url = `${process.env.REACT_APP_BASE_API}/farmer_app/farmers_list?page=1&farmer_type=${props.user_type}`;

  const [CardList, setCardList] = useState([]);

  useEffect(() => {
    const fetch_data = async () => {
      await axios
        .get(url)
        .then((response) => {
          let res = response.data.data.farmers_list;
          const list = res.map((key) => {

            if (props.user_type === 'Niveshak') {
              return (
                <Investor_Card
                  key={key.farmer_id}
                  name={key.farmer_name}
                  img={key.farmer_image}
                  district={key.district_name}
                  state={key.state_name}
                  date={key.created_at}
                  id={key.farmer_id}
                  kyc={key.would_be_farmer_kyc_status}
                  mn={key.mobile_number}
                />
              )
            } else {
              return (
                <Farmer_Card
                  key={key.farmer_id}
                  name={key.farmer_name}
                  img={key.farmer_image}
                  district={key.district_name}
                  state={key.state_name}
                  date={key.created_at}
                  id={key.farmer_id}
                  kyc={key.farmer_kyc_status}
                  mn={key.mobile_number}
                />)
            }

          });

          setCardList(list);
        })
        .catch((error) => {
          setCardList("No Results");
        });
    };

    fetch_data();
  }, []);

  return (

    <div className="cards_container card_farmer">
      {CardList}
    </div>

  );
}

export default SimilarProfiles;
