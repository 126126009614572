import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import globalContext from "../../context/GlobalContext";
import Avatar from 'react-avatar';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { encryptStorage } from '../EncryptStorage';

function Farmer_Card(props) {

  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let d = new Date(props.date);
  let month_name = months[d.getMonth()];
  let year = d.getFullYear();
  const contxt = useContext(globalContext);
  
  return (

    <div onClick={() =>contxt.current_profile(props.id)}>
      <Link  to='/profile'>
        <div className="card_container" farmer-id={props.id}>
          <div className='top'>
            {/* <span className="user-type">{props.user_type}</span> */}
            <span className="kyc">{props.kyc ?
                <img src="./img/verified.svg" />:null}
             </span>
          </div>
          <div className='img'>{!props?.img ? <Avatar name={props?.name} size="84" maxInitials={1} textSizeRatio={2} /> : <img className="pic" src={props.img} />
          }</div>
          <div className="title">{props.name}</div>
          <div className="location">{props.district}, {props.state}, IN</div>
          {/* <div className="budget">Budget Need: Rs.12000</div>
        <div className="amt-for">Member Since: {props.date}</div> */}
          <div className="approved">Member Since: {month_name}, {year} </div>
          {/* <button className="connect">Connect</button> */}
        </div>
      </Link>
    </div>
  )
}

export default Farmer_Card