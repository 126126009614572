import React, { useState, useContext, useEffect } from 'react'
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import FileUploader from "../KYC file upload/File Uploader";
import FormData from "form-data";
import { Button, Container } from "react-bootstrap";
import { encryptStorage } from "../EncryptStorage";
import globalContext from "../../context/GlobalContext";
import Popup from '../cards/Report_Popup'
import { css } from '@emotion/css';
import { BiError } from 'react-icons/bi';


function ReportProfile({ togglePopup }) {

  const [ImagePath, setImagePath] = useState('')
  const [Error, setError] = useState({ status: false, message: '' });
  const Profile_Data = encryptStorage.getItem('Profile_data');
  const [Disablebtn, setDisablebtn] = useState(false)
  const contxt = useContext(globalContext);

  const farmerId = encryptStorage.getItem('UserData');

  const [ReportPostData, setReportPostData] = useState({
    reported_by: farmerId?.farmer_id,
    profile_id_reported: Profile_Data.id,
    report_category: '',
    report_description: '',
    screenshot: !undefined ? ImagePath?.[0] : null,
  })


  useEffect(() => {
    if (ReportPostData?.report_category === '') {
      setError({ status: true, message: 'Please select category.' })
    } else if (ReportPostData?.report_description === '') {
      setError({ status: true, message: 'Please provide description about report.' })
    } else {
      setError({ status: false, message: '' })
    }
    if (ReportPostData?.report_description?.length > 2000) {
      toast.error('Word limit exceeded.')
    }
  }, [ReportPostData])

  var data = new FormData();

  const submitReportPost = () => {

    setDisablebtn(true);

    data.append('reported_by', ReportPostData?.reported_by);
    data.append('profile_id_reported', ReportPostData?.profile_id_reported);
    data.append('report_category', ReportPostData?.report_category);
    data.append('report_description', ReportPostData?.report_description);
    data.append('screenshot', !undefined ? ImagePath?.[0] : null);
    data.append('post_type', 'Profile');

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_API}/farmer_app/report`,
      data: data
    };

    axios(config)
      .then(function (response) {
        toast.warning('Request is pending for review.')
        //togglePopup()
      })
      .catch(function (error) {
        toast.warning(error)
        setDisablebtn(false)
      });
  }
  const KycStatus = () => {

    if (encryptStorage.getItem('UserData')?.farmer_type === 'Farmer') {
      return encryptStorage.getItem('UserData')?.farmer_kyc_status === 'Completed' ? true : false
    } else
      if (encryptStorage?.getItem('UserData')?.farmer_type === 'Niveshak') {
        return encryptStorage.getItem('UserData')?.would_be_farmer_kyc_status === 'Completed' ? true : false
      }
    return false
  }
  return (
    <Popup
      handleClose={togglePopup}
      content={
        KycStatus() ?

          <div>
            <div className="report-heading">Report</div>
            <p className="report-text">If you find anything objectionable or incorrect information, let us know.</p>
            <div className="line"></div>
            <p style={{ display: Error?.status ? 'block' : 'none', color: '#f00' }}>*{Error?.message}</p>
            <div className="content-title">Select a category</div>
            <div className="category-list">
              <div className='d-flex align-items-center' style={{ gap: 15 }}>
                <li className='d-flex align-items-center' style={{ gap: 5 }}>
                  <input type="checkbox" value="offensive content" className="check-box" onClick={(e) => setReportPostData({ ...ReportPostData, report_category: e.target.value })} />
                  <label htmlFor="list1">Offensive content</label>
                </li>
                <li className='d-flex align-items-center' style={{ gap: 5 }}>
                  <input type="checkbox" value="fraud" onClick={(e) => setReportPostData({ ...ReportPostData, report_category: e.target.value })} />
                  <label htmlFor="list2">Fraud</label>
                </li>
                <li className='d-flex align-items-center' style={{ gap: 5 }}>
                  <input type="checkbox" value="duplicate post" onClick={(e) => setReportPostData({ ...ReportPostData, report_category: e.target.value })} />
                  <label htmlFor="list3">Duplicate Post</label>
                </li>
                <li className='d-flex align-items-center' style={{ gap: 5 }}>
                  <input type="checkbox" value="deal already done" onClick={(e) => setReportPostData({ ...ReportPostData, report_category: e.target.value })} />
                  <label htmlFor="list4">Deal already done</label>
                </li>
                <li className='d-flex align-items-center' style={{ gap: 5 }}>
                  <input type="checkbox" value="misleading" onClick={(e) => setReportPostData({ ...ReportPostData, report_category: e.target.value })} />
                  <label htmlFor="list5">Misleading</label>
                </li>
                <li className='d-flex align-items-center' style={{ gap: 5 }}>
                  <input type="checkbox" value="other" onClick={(e) => setReportPostData({ ...ReportPostData, report_category: e.target.value })} />
                  <label htmlFor="list6">Other</label>
                </li>
              </div>
            </div>
            <div className="content-title">Brief description</div>
            <div className="description-container">
              <textarea style={{ borderColor: ReportPostData?.report_description?.length > 2000 ? 'red' : null }} maxLength={2000} placeholder="Enter the details of reporting" onChange={(e) => setReportPostData({ ...ReportPostData, report_description: e.target.value })}></textarea>
              <p className="text-end" style={{ fontSize: 12, color: ReportPostData?.report_description?.length > 2000 ? 'red' : null }}>{ReportPostData?.report_description?.length}/2000</p>
            </div>
            <div className="content-title">Upload images if available</div>
            <div className="upload-image-container">
              <FileUploader setImagePath={setImagePath} />
            </div>
            <Button disabled={Disablebtn} className="button w-50 m-auto d-flex justify-content-center align-items-center text-center" onClick={() => !Error?.status ? submitReportPost() : null}>
              Submit
            </Button>
          </div> :
          <>
            <Container className={`d-flex align-items-center justify-content-center ${css({
              height: 'calc(100vh - 150px)'
            })}`}>
              <div className="text-center">
                <BiError fontSize={95} color='rgba(108, 108, 108, 0.41)' />
                <p style={{ color: 'rgba(108, 108, 108, 0.41)', fontWeight: '600' }}>Your kyc is pending for verification</p>
              </div>
            </Container>
          </>
      }
    />
  )
}

export default ReportProfile