import React, { useState ,useEffect} from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap';
import { encryptStorage } from '../EncryptStorage';
import axios from 'axios'
import FormData from 'form-data';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { css } from '@emotion/css';
import { updateLocale } from 'moment';

const ErrorField = css({
  fontSize: 12,
  color: '#f00'
})


const BasicSettings = () => {
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  let navigate = useNavigate();
  var data = new FormData();
  const FarmerData = encryptStorage.getItem('UserData');
  const [isLoading, setisLoading] = useState(false)

  useEffect(() => {
    console.log(FarmerData)
  }, [])

  const updatelocal = () =>{
    FarmerData.about_me = data?.settigDescription;  console.log(FarmerData.about_me)
    encryptStorage.setItem('UserData',FarmerData)
  }


  const submitBasicSettings = (Data) => {
    setisLoading(true)
    data.append('intersted_in_investing', Data?.settigOpenForInvestment);
    data.append('about_me', Data?.settigDescription);
    data.append('investment_amount', Data?.settingInvestmentAmount);
    data.append('investment_return_type', Data?.settingInvestmentReturnType);
    data.append('own_land_space', Data?.settigLandAreaSpace + ' ' + Data?.settigLandAreaSpaceUnt);
    data.append('farmer_id', FarmerData?.farmer_id);
    data.append('mobile_number', FarmerData?.mobile_number);

    var config = {
      method: 'put',
      url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/farmer_registration`,
      data: data
    };

    axios(config)
      .then(function (response) {
        toast.success('Settings updated successfully')
        updatelocal()
        setisLoading(false)
      })
      .catch(function (error) {
        console.log(error);
        setisLoading(false)
      });
  }

  return (
    <div className="settings-container">
      <div className="title">
        Overview
      </div>
      <div className="caption">
        This information will be displayed in publicly so be careful what you share.
      </div>

      <form onSubmit={handleSubmit(submitBasicSettings)}>
        <div className="label">
          Open For investment
        </div>
        <div className="form-check d-flex" style={{ gap: 25 }}>
          <Form.Check
            type='radio'
            label='Yes'
            id="flexRadioDefault"
            value='Yes'
            defaultChecked={FarmerData?.intersted_in_investing === 'Yes' ? true : false}
            {...register("settigOpenForInvestment", {
              required: {
                value: true,
                message: 'Open for investment is a required field.'
              }
            })
            } />
          <Form.Check
            type='radio'
            label='No'
            value='No'
            id="flexRadioDefault"
            defaultChecked={FarmerData?.intersted_in_investing === 'No' ? true : false}
            {...register("settigOpenForInvestment", {
              required: {
                value: true,
                message: 'Open for investment is a required field.'
              }
            })
            } />
        </div>
        <span className={ErrorField}>
          {errors?.settigOpenForInvestment?.message}
        </span>
        <div className="label">
          About yourself<span className="label-star">*</span>
        </div>
        <div className="about-field">
          <textarea defaultValue={FarmerData?.about_me} maxLength={4000} placeholder="Please describe about yourself"
            {...register("settigDescription", {
              required: {
                value: true,
                message: 'Please write a breif description about yourself.'
              }, minLength: {
                value: 40,
                message: 'Minimum 40 characters is required.'
              }, maxLength: {
                value: 4000,
                message: 'Maximum 4000 characters are allowed.'
              }
            })
            }></textarea>
        </div>
        <span className={ErrorField}>
          {errors?.settigDescription?.message}
        </span>
        <div className="label-caption">
          Please describe yourself so investor's look to your profile
        </div>
        <div className="grid-1">
          <div className="field">
            <div className="label">Land area space*</div>
            <InputGroup className="flex-nowrap p-0" style={{ width: 490 }}>
              <Form.Control
                maxLength={2}
                placeholder="eg. 50 Acer"
                aria-label="land property"
                type="text"
                defaultValue={(FarmerData?.own_land_space).slice(0, 2)}
                aria-describedby="land property"
                {...register("settigLandAreaSpace", {
                  required: {
                    value: true,
                    message: 'Size of land area is a required field.'
                  }, validate: {
                    positive: v => parseInt(v) >= 0 || 'Land area size is not a valid input.',
                  }
                })
                } />
              <Form.Select aria-label="Default select example"
                defaultValue={(FarmerData?.own_land_space).slice(3)}
                {...register("settigLandAreaSpaceUnt", {
                  required: {
                    value: true,
                    message: 'Land area size unit is required.'
                  }
                })
                } >
                <option value="acer">Acres</option>
                <option value="hectare">Hectare</option>
              </Form.Select>
            </InputGroup>
            <span className={ErrorField}>
              {errors?.settigLandAreaSpace?.message}<br />
              {errors?.settigLandAreaSpaceUnt?.message}
            </span>
          </div>
          <div className="field  position-relative">
            <div className="label">Expected investment Amount*</div>
            <input type="text" defaultValue={FarmerData?.investment_amount} placeholder="Enter the amount you wish to invest" maxLength={5}
              {...register("settingInvestmentAmount", {
                required: {
                  value: true,
                  message: 'Investment amount is a required field.'
                },
                validate: {
                  positive: v => parseInt(v) >= 10000 || 'Please make sure your investment amount should be in ₹10000 - ₹50000 range.',
                  lessThanTen: v => parseInt(v) < 50001 || 'Please make sure your investment amount should be in ₹10000 - ₹50000 range.',
                }
              })}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }} style={{ paddingLeft: 25 }} />
            <p className="position-absolute fw-semibold" style={{ top: 73, fontSize: 20, left: 10 }}>&#x20B9;</p>
            <p className={ErrorField}>
              {errors?.settingInvestmentAmount?.message}
            </p>
          </div>

        </div>
        <div className="grid-2">
          <div className="field">
            <div className="label">Return type</div>
            <input className="text" type="text" placeholder="Enter the return details (like - % of crop or money)"
              defaultValue={FarmerData?.investment_return_type}
              {...register("settingInvestmentReturnType", {
                required: {
                  value: true,
                  message: 'Return type is a required field.'
                }
              })} />
            <p className={ErrorField}>
              {errors?.settingInvestmentReturnType?.message}
            </p>
            <div className="field-caption">Please select an investment return type that you give the investor</div>
          </div>
        </div>

        <Button type='submit' style={{ width: 250 }} >
          {isLoading ? 'Saving...' : 'Save'}
        </Button>
      </form>
    </div>
  )
}

export default BasicSettings;