import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { encryptStorage } from "./EncryptStorage";
import FormData from "form-data";
import axios from "axios";
import Avatar from "react-avatar";
import { FaHandshakeSlash, FaRegHandshake } from "react-icons/fa";
import { toast } from "react-toastify";
import OtpInput from 'react-otp-input';

function DealConfirmation() {
  const [searchParams, setSearchParams] = useSearchParams();
  const deal_confirmation_id = searchParams.get("deal_confirmation_id");
  const [DealInfo, setDealInfo] = useState();
  const [SubmitPop, setSubmitPop] = useState(false);
  const [Disablebtn, setDisablebtn] = useState(true);
  const [OTP, setOTP] = useState(null)
  let data = new FormData();

  useEffect(() => {

    let url = `${process.env.REACT_APP_BASE_API}/farmer_app/investment_confirmation?deal_confirmation_id=${deal_confirmation_id}`;

    const fetch_data = () => {
      axios
        .get(url)
        .then((response) => {
          setDealInfo(response.data.data[0]);
          console.log(response.data.data[0].farmer_approval);
        })
        .catch((error) => {
          setDealInfo(false);
          console.log(error.message);
        });
    };

    fetch_data();
    console.log(encryptStorage.getItem("UserData"))
  }, []);

 

  const confirmDeal = (status) => {

    setDisablebtn(true)
   
    data.append("farmer", DealInfo.invested_user_id);
    data.append("deal_confirmation_id", deal_confirmation_id);
    data.append("farmer_approval", status);

    var config = {
      method: "put",
      url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/update_investement_farmer_approval`,
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.message);
        toast.warning(response.data.message);
        if (response.data.code == 200) {
          setSubmitPop(status);
        }
      })
      .catch(function (error) {
        console.log(error.response.data.message);
        toast.warning(error.response.data.message);
      });
  };

  //Request OTP

  const requestOTP=()=>{
    
    data.append('mobile_number', encryptStorage.getItem("UserData").mobile_number);

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/receive_mobile_otp`,
      data: data
    };

    axios(config)
      .then(function (response) {
        toast.success(response?.data?.message);
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message)
      });
  }

  const verifyOTP=()=>{
    
    data.append('mobile_number', encryptStorage.getItem("UserData").mobile_number);
    data.append('mobile_otp', OTP);
    var config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/verify_mobile_number`,
        data: data
    };

    axios(config)
        .then(function (response) {
            toast.success('OTP verified');
            setDisablebtn(false)
        })
        .catch(function (error) {
            toast.warning('Incorrect OTP');
            setDisablebtn(true)
        });

  }

  if (DealInfo) {
    return (
      <div className="deal"> 

      {SubmitPop == "Approved" || DealInfo?.farmer_approval =='Approved'?
        <h4><FaRegHandshake /> It's a deal !! Thanks for approving.</h4>
      :null}

      {SubmitPop == "Reject" || DealInfo?.farmer_approval =='Reject'?
        <h4> <FaHandshakeSlash /> You rejected a deal.</h4> 
      :null}

      {SubmitPop == false && DealInfo?.farmer_approval == 'pending'?
      <>
        <h4>Confirm Your Deal</h4>
        <p>Confirm the deal from your side to get certified by KhetiValah</p>
        </>
      :null}
        <hr />
        <div className="grid">
          <div className="farmer sub">
            <div className="farmer-img">
              <Avatar
                name={DealInfo.farmer_name}
                maxInitials={2}
                size="65"
                textSizeRatio={2}
              />
            </div>
            <div>
              <div>
                Farmer: <b>{DealInfo.farmer_name}</b>
              </div>
              <div>
                Location:{" "}
                <b>
                  {DealInfo.farmer_district}, {DealInfo.farmer_state}
                </b>
              </div>
              <div>
                Phone: <b>{DealInfo.farmer_mobile}</b>
              </div>
            </div>
          </div>
          <div className="niveshak sub">
            <div className="niveshak-img">
              {DealInfo.invested_user_image ? (
                <img src={DealInfo.invested_user_image} />
              ) : (
                <Avatar
                  name={DealInfo.invested_user_name}
                  maxInitials={2}
                  size="65"
                  textSizeRatio={1}
                />
              )}
            </div>
            <div>
              <div>
                Niveshak: <b>{DealInfo.invested_user_name}</b>
              </div>
              <div>
                Location:{" "}
                <b>
                  {DealInfo.invested_user_district},{" "}
                  {DealInfo.invested_user_state}
                </b>
              </div>
              <div>
                Phone: <b>{DealInfo.invested_user_mobile}</b>
              </div>
            </div>
          </div>
        </div>
        <div className="deal-details">
          <div>
            Post: <b>{DealInfo.crop_name + "-" + DealInfo.crop_category}</b>
          </div>
          <div>
            Post ID: <b>{DealInfo.cultivation_id}</b>
          </div>
          <div>
            Investment Return:{" "}
            <b>{DealInfo.investment_return_type} % of crop</b>
          </div>
        </div>
        <h6>
          Investment Info:
        </h6>
        <div className="grid">
          <div className="invest-sub">
            Investment Amount (INR): <h5>{DealInfo.invested_amount}</h5>
          </div>
          <div className="invest-sub">
            Agreement Document:{" "}
            <h6>    
              {DealInfo.investment_agreement?<a href={DealInfo.investment_agreement} target="blank">View</a>:'No Agreement Document'}      
            </h6>
          </div>
        </div>
        {DealInfo?.farmer_approval == 'pending' && SubmitPop==false?
        <>
        <h6>
          Mobile Verification:
        </h6>
        <div className="grid">
          <div className="invest-sub" style={{paddingTop:'8px'}}>
           <h6> Mobile: <b>{encryptStorage.getItem("UserData").mobile_number}</b> </h6>
            <span style={{color:'rgb(46, 95, 43)',cursor:'pointer'}} onClick={requestOTP}> Request OTP</span> 
          </div>
          <div className="invest-sub">
          <OtpInput
                    numInputs={4}
                    onChange={(otp)=>setOTP(otp)}
                    isInputNum={true}
                    value={OTP}
                    inputStyle={{ width: '40px', height: '30px', margin:'5px', borderRadius: 12, border: '1px solid #ACACAC', fontSize: 18, color:'#333' }}
                    focusStyle={{ outlineColor: '#ACACAC' }}
                    shouldAutoFocus={true}
                />
                <span style={{color:'rgb(46, 95, 43)',cursor:'pointer'}} onClick={verifyOTP}> Verify OTP</span> 
          </div>
        </div>
        <hr />
        <p>
          {" "}
          By clicking below, I confirm that the above mentioned fields are true
          to best of my knowledge.
        </p>
        <div className="grid">
          <button
            disabled={Disablebtn}
            style={Disablebtn ? { opacity: 0.2 } : null}
            onClick={() => confirmDeal("Approved")}
            className="submit-deal bg-green"
          >
            I Confirm
          </button>
          <button
            disabled={Disablebtn}
            style={Disablebtn ? { opacity: 0.2 } : null}
            onClick={() => confirmDeal("Reject")}
            className="submit-deal btn-primary"
          >
            I Reject
          </button>
        </div> </>
        :null}
      </div>
    );
  }else {
    return (
      <div className="deal" style={{ textAlign: "center", color: "#ccc" }}>
        <div style={{ fontSize: "150px" }}>
          <FaHandshakeSlash />
        </div>
        <h1 style={{ color: "#ccc" }}>No deal exists</h1>
        <Link to="/">
          <b>Continue browsing KhetiValah</b>
        </Link>
      </div>
    );
  }
}

export default DealConfirmation;
