import axios from 'axios';
import React, { useState, useCallback, useEffect } from "react";
import './gallery.css'
import Carousel1, { Modal, ModalGateway } from "react-images";
import Carousel from 'react-multi-carousel-18';
import 'react-multi-carousel-18/lib/styles.css';
import { Image } from "semantic-ui-react";
import { css } from '@emotion/css';

const ImageGallery = () => {
    const [GalleryPhotos, setGalleryPhotos] = useState()
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState({ modalIsOpen: false });

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen({ modalIsOpen: true });
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen({ modalIsOpen: false });
    };
    const toggleModal = (index) => {
        setCurrentImage(index)
        setViewerIsOpen(state => ({ modalIsOpen: !state.modalIsOpen }))
    }
    useEffect(() => {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_API}/admin_app/gallery_images`,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                const res = response && response.data && response.data.data
                const data = res.map((data) => {
                    return (
                        {
                            key: data?.gallery_id,
                            // gallery_id: data?.gallery_id,
                            // gallery_title: data?.gallery_title,
                            // gallery_description: data?.gallery_description,
                            src: data?.images,
                            width: 2,
                            height: 2
                        }
                    )
                })
                setGalleryPhotos(data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])
    return (
        GalleryPhotos &&
        <>
            <div>
                <ModalGateway>
                    {viewerIsOpen?.modalIsOpen ?
                        <Modal onClose={toggleModal}>
                            <Carousel1
                                currentIndex={currentImage}
                                views={GalleryPhotos.map(x => ({
                                    ...x,
                                    srcset: x.srcSet,
                                    caption: x.title
                                }))}
                            />
                        </Modal>
                        : null}
                </ModalGateway>

                <Carousel
                    additionalTransfrom={0}
                    arrows
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className=""
                    containerClass="container"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                        desktop: {
                            breakpoint: {
                                max: 3000,
                                min: 1024
                            },
                            items: 5,
                            partialVisibilityGutter: 45
                        },
                        mobile: {
                            breakpoint: {
                                max: 464,
                                min: 0
                            },
                            items: 1,
                            partialVisibilityGutter: 30
                        },
                        tablet: {
                            breakpoint: {
                                max: 1024,
                                min: 464
                            },
                            items: 3,
                            partialVisibilityGutter: 30
                        }
                    }}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={2}
                    swipeable
                >
                    {GalleryPhotos && GalleryPhotos.map((element, index) => {
                        return (
                            <Image className={css({ width: 220, height: 220, objectFit: 'cover', cursor: 'pointer', borderRadius: 8, boxShadow: '0px 4px 6px #0000000A;' })} src={element?.src} onClick={() => toggleModal(index)} />
                        )
                    })}
                </Carousel>
            </div>
        </>
    )
}

export default ImageGallery