import React, { useContext, useState, useEffect } from "react";
import { FaTimesCircle } from "react-icons/fa";
import { encryptStorage } from "../EncryptStorage";
import axios from "axios";
import Avatar from "react-avatar";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { css } from "@emotion/css";
import { toast } from "react-toastify";
import OtpInput from 'react-otp-input';

const ErrorField = css({
  fontSize: 12,
  color: "#f00",
});
function Deal(props) {

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const UserData = encryptStorage.getItem("UserData");
  const Chat_Connection = encryptStorage.getItem("ongoing_connectection");
  const [Disablebtn, setDisablebtn] = useState(true);
  const [OTP, setOTP] = useState(null);
  let data = new FormData();

  
  const submitdeal = (DealData) => {
    
    if (DealData?.DealAgreementCheck) {
      
      let data = new FormData();

      data.append("farmer", UserData?.farmer_id);
      data.append("cultivation_request_id", Chat_Connection?.for_post_id);
      data.append("confirmed_farmer_id", Chat_Connection.send_to);
      data.append("invested_amount", DealData?.DealAmount);
      data.append("investment_agreement", DealData?.DealAgreement?.[0]);
      data.append("farmer_approval", "pending");

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_API}/farmer_app/investment_confirmation`,
        data: data,
      };

      axios(config)
        .then(function (response) {
          toast.success("Request sent.");
          props.setDealPop(false);
        })
        .catch(function (error) {
          console.log(error);
          toast.error(error.response.data.message);
        });
    }
  };

  //Request OTP

  const requestOTP=()=>{
    
    data.append('mobile_number', encryptStorage.getItem("UserData").mobile_number);

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/receive_mobile_otp`,
      data: data
    };

    axios(config)
      .then(function (response) {
        toast.success(response?.data?.message);
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message)
      });
  }

  const verifyOTP=()=>{

    data.append('mobile_number', encryptStorage.getItem("UserData").mobile_number);
    data.append('mobile_otp', OTP);
    var config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/verify_mobile_number`,
        data: data
    };

    axios(config)
        .then(function (response) {
            toast.success('OTP verified');
            setDisablebtn(false)
        })
        .catch(function (error) {
            toast.warning('Incorrect OTP');
            setDisablebtn(true)
        });

  }


  return (
    <div className="popup">
      <div className="deal">
        <div className="close" onClick={() => props.setDealPop(false)}>
          <FaTimesCircle />
        </div>
        <h4>Confirm Your Deal</h4>
        <p>Confirm the deal from your side to get certified by KhetiValah</p>
        <hr />
        <div className="grid">
          <div className="farmer sub">
            <div className="farmer-img">
              {Chat_Connection.send_to_img ? (
                <img src={Chat_Connection.send_to_img} />
              ) : (
                <Avatar
                  name={Chat_Connection.send_name}
                  maxInitials={2}
                  size="65"
                  textSizeRatio={2}
                />
              )}
            </div>
            <div>
              <div>Farmer: <b>{Chat_Connection?.send_name}</b></div>
              <div>Location: <b>{Chat_Connection?.farmer_district}, {Chat_Connection?.farmer_state}</b></div>
            </div>
          </div>
          <div className="niveshak sub">
            <div className="niveshak-img">
              {UserData.farmer_image ? (
                <img src={UserData.farmer_image} />
              ) : (
                <Avatar
                  name={UserData?.farmer_name}
                  maxInitials={2}
                  size="65"
                  textSizeRatio={1}
                />
              )}
            </div>
            <div>
              <div>Niveshak: <b>{UserData.farmer_name}</b></div>
               <div>Location: <b>{UserData.district_name}, {UserData.state_name}</b></div>
              {/* <div>Phone: <b>{Chat_Connection?.send_name}</b></div>  */}
            </div>
          </div>      
        </div>
        <div className="deal-details">
          <div>
            Post: <b>{Chat_Connection?.post_title}</b>
          </div>
          <div>
            Post ID: <b>{Chat_Connection?.cultivation_request_id}</b>
          </div>
          <div>
            Return Terms: <b>{Chat_Connection?.return}% of Crop</b>
          </div>
         </div> 
         <h6>
          Mobile Verification:
        </h6>
        <div className="grid">
          <div className="invest-sub" style={{paddingTop:'8px'}}>
           <h6> Mobile: <b>{encryptStorage.getItem("UserData").mobile_number}</b> </h6>
            <span style={{color:'rgb(46, 95, 43)',cursor:'pointer'}} onClick={requestOTP}> Request OTP</span> 
          </div>
          <div className="invest-sub">
          <OtpInput
                    numInputs={4}
                    onChange={(otp)=>setOTP(otp)}
                    isInputNum={true}
                    value={OTP}
                    inputStyle={{ width: '40px', height: '30px', margin:'5px', borderRadius: 12, border: '1px solid #ACACAC', fontSize: 18, color:'#333' }}
                    focusStyle={{ outlineColor: '#ACACAC' }}
                    shouldAutoFocus={true}
                />
                <span style={{color:'rgb(46, 95, 43)',cursor:'pointer'}} onClick={verifyOTP}> Verify OTP</span> 
          </div>
        </div>
         <h6><b>Investment Info</b></h6>
         <hr/>
        <form onSubmit={handleSubmit(submitdeal)}>
          <Row>
            <Col xs={6}>
              <div className="me-4">
                Invested Amount: <br />
                <Form.Control
                  type="text"
                  id="investment-amt"
                  maxLength={5}
                  {...register("DealAmount", {
                    required: {
                      value: true,
                      message: "Investment amount is a required field.",
                    },
                    validate: {
                      positive: (v) =>
                        parseInt(v) >= 10000 ||
                        "Please make sure your investment amount should be in ₹10000 - ₹50000 range.",
                      lessThanTen: (v) =>
                        parseInt(v) < 50001 ||
                        "Please make sure your investment amount should be in ₹10000 - ₹50000 range.",
                    },
                  })}
                />
              </div>
              <p className={ErrorField}>{errors?.DealAmount?.message}</p>
            </Col>
            <Col xs={6}>
              <div>
                Investment Agreement:{" "}
                <Form.Control type="file" {...register("DealAgreement")} />
              </div>
            </Col>
          </Row>
          <input
            type="checkbox"
            id="agree"
            {...register("DealAgreementCheck", {
              required: {
                value: true,
                message: "please check to acknowledge.",
              },
            })}
          />{" "}
          <label for="agree">
            I confirm that the above mentioned fields are true to best of my
            knowledge.
          </label>
          <p className={ErrorField}>{errors?.DealAgreementCheck?.message}</p>
          <Button
            type="submit"
            className="submit-deal bg-green"
            disabled={Disablebtn}
            style={Disablebtn ? { opacity: 0.2 } : null}
          >
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
}

export default Deal;
