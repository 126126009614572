import { css } from '@emotion/css'
import axios from 'axios'
import FormData from 'form-data'
import React, { useEffect, useState } from 'react'
import Avatar from 'react-avatar'
import { Button, Col, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate } from "react-router-dom"
import { toast } from 'react-toastify'
import { Image } from 'semantic-ui-react'
import { encryptStorage } from '../EncryptStorage'
import KYCFarmerForm from '../KYC file upload/KYC Form/KYCFarmerForm'
import About_Description from '../profile/About_Description'

const FarmerKycUpdate = () => {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    var data = new FormData();
    let navigate = useNavigate();
    const UserData = encryptStorage.getItem('UserData')

    useEffect(() => {
        const validateKYC = () => {
            if (UserData?.farmer_type === 'Farmer') {
                let path = '/'
                return UserData?.farmer_kyc_status === 'Completed' ? navigate(path) : UserData?.mandal_name ? navigate('/register/farmer-kyc-verification') : navigate(path)

            } else {
                let path = '/'
                return UserData?.would_be_farmer_kyc_status === 'Completed ' ? navigate(path) : UserData?.mandal_name ? navigate('/register/investor-kyc-verification') : navigate(path)
            }
        }
        // validateKYC()
    }, [])

    const [KycData, setKycData] = useState({
        identity_proof_image: null,
        address_proof_image: null,
    })
    console.log(KycData)
    
    const submitKYC = (Data) => {
        data.append('farmer_id', UserData?.farmer_id);
        data.append('mobile_number', UserData?.mobile_number);
        data.append('identity_proof_type', Data?.farmerIdProofMethod);
        data.append('identity_proof_number', Data?.farmerIdProofDocumentNumber);
        data.append('identity_proof_image', KycData?.identity_proof_image);
        data.append('address_proof_image', KycData?.address_proof_image);
        data.append('address_line_1', Data?.farmerAddressLine1);
        data.append('address_line_2', Data?.farmerAddressLine2);
        data.append('address_geolocation', [encryptStorage.getItem('Latitude'), encryptStorage.getItem('Longitude')]);
        data.append('having_own_land', Data?.farmerOwnLand);
        data.append('own_land_space', Data?.farmerLandAreaSize + ' ' + Data?.farmerLandAreaUnit);
        data.append('about_me', Data?.farmerDescription);

        var config = {
            method: 'put',
            url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/farmer_registration`,
            data: data
        };

        if (KycData?.address_proof_image && KycData?.identity_proof_image) {
            axios(config)
                .then(function (response) {
                    toast.warning('KYC is pending for review!')
                    encryptStorage.setItem('UserData', JSON.stringify(response?.data?.data))
                    navigate('/')
                })
                .catch(function (error) {
                    Object.entries(error.response.data.message).map((key, index) => {
                        toast.error(key?.[1]?.[0])
                    })
                });
        }


    }
    console.log(UserData)

    return (
        <>
            <Col>
                <Row>
                    <p className='fs-2 text-center  fw-bold'>Your KYC is {UserData?.farmer_kyc_status}</p>
                    <p className='fs-6 text-center color-text'>Create your account by filling the form below,Please fill the information correctly, it's important to profile verification process.</p>
                </Row>
                <Row className='justify-content-center mt-3'>
                    <div style={{ width: 100, height: 100 }} className='p-0 rounded-circle border d-flex justify-content-center align-items-center '>
                        {UserData?.farmer_image ? <img src={UserData?.farmer_image} style={{ width: 100, height: 100, objectFit: 'cover', borderRadius: '50%' }} /> : (
                            <Avatar name={UserData?.name} maxInitials={1} round size="100" />
                        )}
                    </div>
                    <p className='m-0 fw-semibold text-center'>{UserData?.farmer_name}</p>
                    <p className='m-0 text-center'>+91-{UserData?.mobile_number}</p>
                </Row>
                <hr />
                {UserData?.identity_proof_number ?
                    <>
                        <div className={css({ backgroundColor: '#e8e8e8', borderRadius: 8, padding: '3px 10px', fontSize: 14, textAlign: 'center' })}>
                            <span className='text-center color-text m-0'>Your KYC details has been submited, hence you cannot make any chages. </span>
                        </div>

                        <Row>
                            <Col xs={6}>
                                <p className='m-0 fw-semibold mt-3 mb-3'>Address proof</p>
                                <Image src={UserData?.address_proof_image} alt={UserData?.address_proof_image} className={css({ width: '100%', height: 200, objectFit: 'cover', border: '1px solid #e8e8e8', borderRadius: 8 })} />
                            </Col>
                            <Col xs={6}>
                                <p className='m-0 fw-semibold mt-3 mb-3'>Identity proof</p>
                                <Image src={UserData?.identity_proof_image} alt={UserData?.identity_proof_image} className={css({ width: '100%', height: 200, objectFit: 'cover', border: '1px solid #e8e8e8', borderRadius: 8 })} />
                            </Col>
                        </Row>
                        <p className='m-0 fw-semibold mt-3'>Identity proof number</p>
                        <span className='fs-5 fw-normal'>
                            {(UserData?.identity_proof_number).replace(/.(?=.{4})/g, '*')}
                        </span>
                        <p className='m-0 fw-semibold mt-3'>Owned land size</p>
                        <span className='fs-5'>
                            {UserData?.own_land_space!='undefined acres'?UserData?.own_land_space:'NA'}
                        </span>
                        <p className='m-0 fw-semibold mt-3'>About</p>
                        {<About_Description content={UserData?.about_me} limit={400} />}
                    </> : <>
                        <form onSubmit={handleSubmit(submitKYC)}>
                            <Row>
                                <KYCFarmerForm setKycData={setKycData} KycData={KycData} register={register} errors={errors} />
                            </Row>
                            <Button variant='primary' className='w-100 ms-0 mt-3 me-0 bg-green' type='submit'>Submit</Button>
                        </form>
                    </>}

            </Col>
        </>
    )
}

export default FarmerKycUpdate