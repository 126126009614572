import React, { useEffect, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FiUpload } from 'react-icons/fi'
import { AiFillCloseCircle, AiOutlinePlusCircle } from 'react-icons/ai'
import { css } from '@emotion/css';
const thumbsContainer = {
    display: 'flex',
    gap: 10,
    flexWrap: 'wrap',
    maxWidth: 720
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
    justifyContent: 'center',

};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};
const ImageOuter = {
    backgroundColor: '#E4F1E3',
    border: '1px dashed #3D7F39',
    borderRadius: 8,
    cursor: 'pointer',
    maxWidth: 485,
    marginLeft: 0,
}
const Close_CSS = css({
    right: -8,
    top: 0,
    position: 'absolute',
    zIndex: 1,
    background: '#fff',
    borderRadius: '50%',
    cursor: 'pointer'
})
const MultipleFileUpload = ({ setImagePath, register, errors, setValue }) => {

    const [AcceptedFiles, setAcceptedFiles] = useState([])
    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 6,
        accept: {
            'image/jpeg': [],
            'image/png': [],
        },
        onDrop: acceptedFiles => {
            setAcceptedFiles(...AcceptedFiles, acceptedFiles?.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });
    const removeFile = file => () => {
        const newFiles = [...AcceptedFiles]
        newFiles?.splice(newFiles.indexOf(file), 1)
        setAcceptedFiles(newFiles)
    }
    const thumbs = AcceptedFiles?.map(file => (
        <div style={thumb} key={file?.name} className='position-relative'>
            <div style={thumbInner}>
                <img
                    src={file?.preview}
                    style={img}
                    alt={'land images'}
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file?.preview) }}
                />
                <p className={Close_CSS} onClick={removeFile(file)}><AiFillCloseCircle fontSize={25} color='#000' /></p>
            </div>
        </div>
    ));

    useEffect(() => {
        setImagePath(AcceptedFiles)
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => AcceptedFiles.forEach(file => URL.revokeObjectURL(file?.preview));
    }, [AcceptedFiles]);

    return (
        <>
            <div {...getRootProps({ className: 'container d-flex p-3 mt-2' })} style={thumbs?.length === 0 ? ImageOuter : null}>
                <div className='dropzone' style={{ textAlign: 'center', cursor: 'pointer', margin: 'auto' }}>
                    <div style={{ display: thumbs?.length === 0 ? 'block' : 'none' }}>
                        <input {...getInputProps()}
                        // {...register("cropLandImages", {
                        //     required: {
                        //         value: true,
                        //         message: 'Land Images are required.'
                        //     }
                        // })
                        // } 
                        />
                        <FiUpload fontSize={25} color='#396D14' className='mb-2' />
                        <p className='title fs-6 m-0'>Upload Images</p>
                        <em>(max 6 images can be uploaded)</em>
                    </div>
                </div>
            </div >
            <div className='ll' style={thumbsContainer}>
                {thumbs}
            </div>
            <span className={css({
                fontSize: 12,
                color: '#f00'
            })}>
                {AcceptedFiles?.length <= 0 ? 'Please upload valid image.' : null}
            </span>
        </>
    )
}

export default MultipleFileUpload