import React from 'react'
import './index.scss'
const PageNotFound = () => {


    return (
        <>
            <div className="base">
                <div className="error">
                    <span className="neon">4</span>
                    <span className="neon">0</span>
                    <span className="neon">4</span>
                </div>
                <span className="message-error">Page Not Found</span><br />
            </div>
        </>
    )
}

export default PageNotFound