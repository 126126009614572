import React, { useEffect, useState, useContext } from "react";
import globalContext from "../../context/GlobalContext";
import { encryptStorage } from "../EncryptStorage";
import Avatar from 'react-avatar';
import About_Description from "../profile/About_Description";

function Chat(props) {
  const contxt = useContext(globalContext);
  const FarmerData = encryptStorage.getItem("farmer_data");
  const UserData = encryptStorage.getItem("UserData")

  let list = props.res.map((item) => {
    if (item.message_from === FarmerData.farmer_id) {
      return (
        <div className="reply-massage" key={item.message_id}>
          <div className="massage_1">

            <div className="content item">
              <div className="msg">
                <div className="chat-inner">
                  <p>{<About_Description content={item?.message_body} limit={600} />}</p>
                  <span className="check-icon span">{item.message_read_status ? <img src='img/checked.png' /> : ''}</span>
                  <span className="time span">{contxt.getdate(item.message_created)}</span>
                </div>
              </div>
            </div>
            <div className="icon item">
              {!UserData?.farmer_image ? <Avatar name={UserData?.farmer_name} maxInitials={2} round size="25" textSizeRatio={2} />
                : <img className="profile_icon" src={UserData?.farmer_image} />}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="massage_1" key={item.message_id}>
          <div className="icon item">
            {!item.message_from_image ?
              <Avatar name={item.message_from_name} maxInitials={2} round size="25" textSizeRatio={2} /> :
              <img src={item.message_from_image} />}
          </div>
          <div className="content item">
            <div className="msg first-chat">
              <div className="chat-inner">
                <p>{<About_Description content={item?.message_body} limit={600} />}</p>
                <span className="check-icon span"></span>
                <span className="time span">{contxt.getdate(item?.message_created)}</span>
              </div>
            </div>
          </div>
        </div>
      );
    }

  });

  return list;

}

export default Chat