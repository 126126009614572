import React, { useContext } from "react";
import { Link } from "react-router-dom";
import globalContext from "../../context/GlobalContext";
import Avatar from 'react-avatar';
import Bookmark from "./Bookmark";
import { encryptStorage } from "../EncryptStorage";

function Posts_Card(props) {
  const contxt = useContext(globalContext);
  const FarmerData = encryptStorage.getItem("farmer_data");
  return (
    <div className="post-cards" key={props.data.primary_key}>
      <div>
        <div className="card_container">
          <div className="title">
            {props?.data?.crop_name}
            {FarmerData && <Bookmark postid={props?.postid} Data={props?.data} />}
            <span className="posted_on">
              <img src="img/time.svg" />
              <span>{' ' + contxt.getdate(props?.data?.created_at)}</span>
            </span>
          </div>
          <div className="location">
            <img src="img/location-map.svg" />
            <span>{' ' + props?.data?.district_name + ', ' + props?.data?.state_name}</span>
          </div>
          <div className="budget">Budget: {props?.data?.expected_investment}</div>
          <div className="description" style={{ overflowWrap: 'anywhere' }}>
            {props?.data?.crop_details}
          </div>
          <div className="foot">
            <span className="postid">
              PostID: {props?.data?.cultivation_request_id}
            </span>
            <span className="profile">
              {props?.data?.farmer_image ?
                <img className="pic" src={props.data.farmer_image} /> :
                <Avatar name={props?.data?.farmer_name} maxInitials={1} round size="20" textSizeRatio={2} />}
              <span className="name"> {props?.data?.farmer_name}</span>
            </span>
          </div>
          {FarmerData && <div className="">
            <Link to={'/post-view?postid=' + props?.data?.primary_key + '&farmer_id=' + props?.data?.farmer_id}>
              <button className="btn_full" onClick={() => { encryptStorage.setItem('Post_View', { post_id: props?.data?.primary_key, farmer_id: props?.data?.farmerid }) }}>View Details</button>
            </Link>
          </div>}

        </div>
      </div>
    </div >
  );
}

export default Posts_Card;
