import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FiUpload } from 'react-icons/fi'
import { AiFillCloseCircle } from 'react-icons/ai'
import { css } from '@emotion/css';


const Close_CSS = css({
    right: -8,
    top: 0,
    position: 'absolute',
    zIndex: 1,
    background: '#fff',
    borderRadius: '50%',
    cursor: 'pointer'
})
const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    width: '100%',
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
    justifyContent: 'center',

};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};
const ImageOuter = {
    backgroundColor: '#E4F1E3',
    border: '1px dashed #3D7F39',
    borderRadius: 8,
    cursor: 'pointer'
}

const FileUploader = ({ setImagePath }) => {

    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': [],
        },
        maxFiles: 1,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });
    const removeFile = file => () => {
        const newFiles = [...files]
        newFiles.splice(newFiles.indexOf(file), 1)
        setFiles(newFiles)
    }
    const thumbs = files.map(file => (
        <div style={thumb} key={file.name} className='position-relative'>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    alt={file?.name}
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
                <p className={Close_CSS} onClick={removeFile(file)}><AiFillCloseCircle fontSize={25} color='#000' /></p>
            </div>
        </div>
    ));
    useEffect(() => {
        setImagePath(files)
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);
    console.log(thumbs)
    return (
        <div {...getRootProps({ className: 'container d-flex justify-content-center p-3 mt-2 mb-3' })} style={thumbs.length === 0 ? ImageOuter : null}>
            <div className='dropzone' style={{ textAlign: 'center', cursor: 'pointer' }}>
                <div style={{ display: thumbs.length === 0 ? 'block' : 'none' }}>
                    <input {...getInputProps()} />
                    <FiUpload fontSize={25} color='#396D14' className='mb-2' />
                    <p className='title fs-6 m-0'>Upload Image</p>
                    <em><small>( Max 1 file )</small></em>
                </div>
                <div style={thumbsContainer}>
                    {thumbs}
                </div>
            </div>


        </div >
    )
}

export default FileUploader