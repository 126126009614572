import { css } from "@emotion/css";
import axios from "axios";
import FormData from "form-data";
import React, { useContext, useEffect, useState } from "react";
import Avatar from 'react-avatar';
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import { BsSearch } from 'react-icons/bs';
import { Link, useNavigate } from "react-router-dom";
import globalContext from "../../context/GlobalContext";
import { useAuth } from "../../Utils/Auth Context";
import { encryptStorage } from "../EncryptStorage";
import Spinner from "../Spinner";
import NewMsg from "./NewMsg";
import NewNotification from "./NewNotification";
import TopNavBar from "./TopNavBar";
import {RiDeleteBin7Line } from "react-icons/ri";
import DeleteAccount from "./DeleteAccount";

function NavBar() {
  var data = new FormData();
  let navigate = useNavigate();
  const auth = useAuth();
  const contxt = useContext(globalContext);
  const url = `${process.env.REACT_APP_API_BASE_URL}/admin_app/states`;
  const FarmerData = encryptStorage.getItem('farmer_data');
  const Mob_num = encryptStorage.getItem('Mob');
  const Auth = encryptStorage.getItem('Authenticated');
  const UserData = encryptStorage.getItem('UserData');
  const [StateList, setStateList] = useState(null);
  const [isVisible, setIsVisible] = useState(true);
  const [height, setHeight] = useState(0)
  const [Qs, setQs] = useState('')
  const [SearchTxt, setSearchTxt] = useState();
  const page = window.location.pathname;
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  const LoginCheck = () => {
    if (!Auth) {
      let path = '/signin'
      navigate(path)
    } else {
      let path = '/post-your-need';
      navigate(path);
    }
  }
  const GetFarmersData = () => {
    if (FarmerData?.farmer_id === undefined) {
      return <Spinner />
    } else {
      var config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/farmer_registration?mobile_number=${Mob_num}&farmer_id=${FarmerData?.farmer_id}`,
      };

      axios(config)
        .then(function (response) {
          encryptStorage.setItem('UserData', response?.data?.data)
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  useEffect(() => {
    const url = `${process.env.REACT_APP_BASE_API}/admin_app/states`;
    axios.get(url).then((response) => {
      setStateList(response.data.data);
    }).catch(function (error) {
      console.log('searchbar states fetch error: ' + error);
    });

    if (!FarmerData === true) {
    } else {
      var config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/farmer_registration?mobile_number=${Mob_num}&farmer_id=${FarmerData?.farmer_id}`,
      };
      axios(config)
        .then(function (response) {
          encryptStorage.setItem('UserData', JSON.stringify(response?.data?.data))
        })
        .catch(function () {
          // navigate('/register')
        });
    }
    GetFarmersData();
  }, [Mob_num])


  const Logout = () => {
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/farmer_logout`,
      data: data
    };

    axios(config)
      .then(function () {
        console.log('Logout successful!')
      })
      .catch(function () {
      });
    auth.logout()
    encryptStorage.clear();
    navigate('/')
  }

  const deleteAccount = () =>{
    openPopup();
  }
  const navToSearch = (e) => {
    const path = `/search?q=${e}`
    navigate(path)
  }

  const onenter = (e) => {
    setQs(e.target.value)
    if (e.keyCode === 13) {
      navToSearch(e.target.value)
    }
  };

  const select_location = (e) => {
    encryptStorage.setItem('state_filter', e.target.value)
  };

  //setting SearchTxt for Farmers,Investors and Post

  useEffect(() => {
    axios.get(url).then((response) => {
      setStateList(response.data.data);
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () =>
      window.removeEventListener("scroll", listenToScroll);
  }, [])

  const listenToScroll = () => {
    let heightToHideFrom = 105;
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    setHeight(winScroll);

    if (winScroll > heightToHideFrom) {
      isVisible && setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    if (page != '/search') {
      setSearchTxt('');
    }
  }, [page])

  return (
    <>
      {isVisible && <TopNavBar />}
      <header style={{ top: isVisible ? 38 : 0 }}>
        <nav className="container">
          <div className="logo">
            <Link to="/">
              <img src={'/img/KhetiValah.png'} alt="KhetiValah" />
            </Link>
          </div>
          <div className="search">
            <ul>
              <div className="bar__container">
                <li className="search-item select">
                  <img className="location_img" src="/img/location.png" alt="location" />
                  <select name="location" id="location" defaultValue={encryptStorage.getItem('state_filter')} onChange={(e) => select_location(e)}>
                    <option value="">Location</option>
                    {StateList === null ? null : StateList?.map((key) => {
                      return (
                        <option key={key?.primary_key} id={key?.primary_key} value={key?.state_name}>
                          {key?.state_name}
                        </option>
                      );
                    })}
                  </select>
                </li>
                <li className="search-item input position-relative" style={{ width: 350 }}>
                  <input
                    type="text"
                    id="search-text"
                    placeholder="Search posts, niveshak & farmers"
                    onKeyDown={(e) => onenter(e)}
                    value={SearchTxt}
                    onChange={(e) => { setSearchTxt(e.target.value) }}
                  />
                  <div className="search__container position-absolute" id="search-img">
                    <BsSearch className="search-img" onClick={() => navToSearch(Qs)} color={'#fff'} fontSize={26} />
                  </div>
                </li>
              </div>
            </ul>
          </div >
          <div className="links">
            <Link to="/posts" className={(navData) => (navData.isActive ? 'navbar__link--active' : '')}>Posts</Link>
          </div>
          <div className="links">
            <Link to="/niveshak" className={(navData) => (navData.isActive ? 'navbar__link--active' : '')}>Niveshak</Link>
          </div>
          <div className="links">
            <Link to="/farmers" className={(navData) => (navData.isActive ? 'navbar__link--active' : '')}>Farmers</Link>
          </div>

          {
            !Auth ? (
              <div className="links login">
                <Link to="/signin">Login or Signup</Link>
              </div >
            ) : (
              <div className="logged position-relative d-flex">
                <Link to="/messaging">
                  <NewMsg />
                </Link>

                <Link to="/notification" >
                  <NewNotification />
                </Link >
                <Dropdown as={ButtonGroup} style={{ width: 35 }} align="end">
                  <Button variant="secondary" className={`w - auto p-0 border-0 ${css({
                    backgroundColor: 'transparent !important', width: 'auto',
                    '&:focus': {
                      boxShadow: 'none'
                    },
                    '&:after': {
                      borderTopColor: '#4c9f47'
                    }
                  })}`}>

                    <Link to={'/profile'} onClick={() => contxt.current_profile(FarmerData.farmer_id)}>
                      {!UserData?.farmer_image ? <Avatar name={UserData?.farmer_name} maxInitials={1} round size="25" textSizeRatio={2} />
                        : <img className="profile_icon" alt={UserData?.farmer_name} src={UserData?.farmer_image} style={{ borderRadius: '50%', width: 35, height: 35, objectFit: 'cover' }} />}
                    </Link>

                  </Button>
                  <Dropdown.Toggle split variant="secondary" id="dropdown-split-basic" align="end" className={css({
                    padding: '0 !important', border: 'none !important', background: 'none',
                    '&:focus': {
                      boxShadow: 'none',
                      backgroundColor: 'transparent',
                      background: 'transparent',
                    },
                    '&:after': {
                      borderTopColor: '#4c9f47'
                    },
                    '&:hover': {
                      backgroundColor: 'transparent !important'
                    },
                    '&:active': {
                      backgroundColor: 'transparent !important'
                    }
                  })} />
                  <Dropdown.Menu className={css({ top: '25px !important' })}>
                    <Dropdown.Item as={'div'} className={css({
                      '&:active': {
                        background: 'transparent !important'
                      }
                    })}>
                      <Link to='/profile' onClick={() => contxt.current_profile(FarmerData.farmer_id)}>
                        <img className="icon" src="/img/edit_profile_icon.svg" />
                        View Profile
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item as={'div'} className={css({
                      '&:active': {
                        background: 'transparent !important'
                      }
                    })}  >
                      <Link to='/profile' onClick={() => contxt.current_profile(FarmerData.farmer_id)}>
                        <img className="icon" src="/img/list_icon.svg" />
                        My Posts
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item as={'div'} className={css({
                      '&:active': {
                        background: 'transparent !important'
                      }
                    })} >
                      <Link to='/profile' onClick={() => contxt.current_profile(FarmerData.farmer_id)}>
                        <img className="icon" src="/img/bookmark.svg" />
                        Bookmark
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item as={'div'} className={css({
                      '&:active': {
                        background: 'transparent !important'
                      }
                    })}  >
                      <Link to={UserData?.farmer_type === 'Farmer' ? '/register/farmer-kyc-verification' : '/register/investor-kyc-verification'} >
                        <img className="icon" src="/img/kyc.svg" />
                        KYC
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item as={'div'} className={css({
                      '&:active': {
                        background: 'transparent !important'
                      }
                    })}>
                      <Link to="/setting"    >
                        <img className="icon" src="/img/setting.svg" />
                        Setting
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item as={'div'} className={css({
                      '&:active': {
                        background: 'transparent !important'
                      }
                    })} onClick={() => deleteAccount()}>
                      <RiDeleteBin7Line size={20} className="mr-2"/>
                      <span style={{marginLeft:'8px',display:"inline-block",cursor:"pointer"}}>  Delete Account</span>
                    </Dropdown.Item>
                    <Dropdown.Item as={'div'} className={css({
                      '&:active': {
                        background: 'transparent !important'
                      }
                    })} onClick={() => Logout()}>
                      <img className="icon" src="/img/logout.svg" />
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div >
            )
          }
          {
            (() => {
              if (UserData?.farmer_type != 'Niveshak') {
                return <div className="post_btn">
                  <button onClick={() => LoginCheck()}>Post Your Need</button>
                </div>
              }
            })()
          }

        </nav >
      </header >
      { isPopupOpen &&
        <DeleteAccount  isOpen={isPopupOpen} closePopup={closePopup}/>
      }    </>
  );
}

export default NavBar;
