import React from 'react'

function How_Farmer() {
  return (
    <div className="how_grid">
      <div className="grid_investor text-capitalize">
        <p className='highlight'>
          How it work for Farmers
        </p>
        <h1>
          Get Funding for <span className='highlight'>Farming</span>
        </h1>
        <p>Post your Requirement and Find Niveshak, Learn how it works</p>
      </div>

      <div className="grid_investor"></div>

      <div className="grid_investor">
        <ul>
          <li>
            <div className="icon item">
              <img src="/img/profile_icon.svg" />
            </div>
            <div className="content item">
              <h4>Register as a Niveshak, and complete the KYC</h4>
              <div>
                KhetiValah offers you unlimited investment choice,
                so that you can earn more money by investing with our verified farmers.
              </div>
            </div>
          </li>
          <li>
            <div className="icon item">
              <img src="/img/rupee_icon.svg" />
            </div>
            <div className="content item">
              <h4>Publish your cultivation post</h4>
              <div>
                KhetiValah offers you unlimited investment choice,
                so that you can earn more money by investing with our verified farmers.
              </div>
            </div>
          </li>
          <li>
            <div className="icon item">
              <img src="/img/msg_icon.svg" />
            </div>
            <div className="content item">
              <h4>Get offers by verified farmers</h4>
              <div>
                KhetiValah offers you unlimited investment choice, so that you
                can earn more money by investing with our verified farmers.
              </div>
            </div>
          </li>
          <li>
            <div className="icon item">
              <img src="/img/check_icon.svg" />
            </div>
            <div className="content item">
              <h4>Get your investment in a click</h4>
              <div>
                KhetiValah offers you unlimited investment choice, so that you
                can earn more money by investing with our verified farmers.
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="grid_investor">
        <img src="img/how_farmer.svg" alt="" />
      </div>
    </div>
  )
}

export default How_Farmer