import React, { useState } from 'react'
import globalContext from "../../context/GlobalContext";
import { encryptStorage } from "../EncryptStorage";
import axios from "axios";

function Sender() {

  const FarmerData = encryptStorage.getItem("farmer_data");
  const SenderData = encryptStorage.getItem('ongoing_connectection')
  const [InputMessage, setInputMessage] = useState(null)

  const send_message = () => {
    let data = new FormData();
    data.append('message_to', SenderData.send_to);
    data.append('message_from', FarmerData.farmer_id);
    data.append('message_body', InputMessage);
    data.append('cultivation_id', SenderData.for_post_id);
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_API}/farmer_app/farmer_send_message`,
      data: data
    };
    if (InputMessage.trim().length > 0) {
      axios(config)
        .then(function (response) {
          document.getElementById("message_input").value = null;
          let chatbox = document.getElementById("chatbox-scroll");
          chatbox.scrollTop = chatbox.scrollHeight;
          console.log(SenderData)
        })
        .catch(function (error) {
          console.log(error?.response.data.message);
        });
    }
  };

  return (
    <div className="chat-card-footer">
      <div className="inner-footer">
        <input
          className="massage-type"
          type="text"
          id="message_input"
          placeholder="Type your message here"
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyUp={(e) => { if (e.code === 'Enter') { send_message() } }}
        />
        <button className="send" type="button" onClick={send_message}>
          Send
        </button>
      </div>
    </div>
  )
}

export default Sender