import React from "react";
import { useNavigate } from "react-router-dom";

function Bottom_banner() {
  let navigate = useNavigate()

  const RouteSignin = () => {
    const path = '/register'
    navigate(path)
  }
  return (
    <div className="bottom_banner">
      <div className="overlay"></div>
      <div className="content">
        <h1>Now Farming is Easier than Ever</h1>
        <p className="sub-title text-center color-white">
          Work with the largest network of independent Farmer's / Worker's and<br />
          Niveshak get things done from quick turnarounds to big transformation
        </p>
        <button className="botton_btn" onClick={() => RouteSignin()}>
          <h4 className="text-center fw-bold color-white">Grow as Niveshak  <img src="./img/right_arrow_icon.svg" /></h4>
          <div>
            Let's contribute in farming without <br />a land and get more benefits
          </div>
        </button>
        <button className="bottom_btn" onClick={() => RouteSignin()}>
          <h4 className="text-center fw-bold color-white">Register as Farmer  <img src="./img/right_arrow_icon.svg" /></h4>
          <div>
            Find niveshak for your agriculture <br /> land and earn more money.
          </div>
        </button>
      </div>
    </div>
  );
}

export default Bottom_banner;
