import React from 'react'
import { ScaleLoader } from 'react-spinners'

const Spinner = () => {
    return (
        <div style={{ position: 'absolute', width: '100%', height: '100vh', backgroundColor: '#E4F1E3', top: '0px', left: '0px', zIndex: 8 }}>
            <div style={{ position: 'absolute', top: 'calc(50% - 30px)', left: 'calc(50% - 30px)', zIndex: 11 }}>
                <ScaleLoader color='#396D14' />
            </div>
        </div>
    )
}

export default Spinner