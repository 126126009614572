import React, { useState } from "react";
import GlobalContext from "./GlobalContext";
import { encryptStorage } from "../components/EncryptStorage";

const GlobalState = (props) => {

    const [ViewProfileData, setViewProfileData] = useState(null)
    const [ConnectionData, setConnectionData] = useState();
    const [Dropdown, setDropdown] = useState(false);
    const [OwnPost, setOwnPost] = useState(null)
    const [Filters, setFilters] = useState({
        Location: '',
        SearchTxt: '',
        Gender: '',
        CropCategory: ''
    });

    const [NotificationData, setNotificationData] = useState();

    const [ProfileView, setProfileView] = useState({
        id: '',
        mn: ''
    })

    const [PostView, setPostView] = useState({
        post_id: '',
        farmer_id: ''
    })

    const [Message, setMessage] = useState({
        send_to: '',
        send_name: '',
        for_post_id: '',
        post_title: '',
        send_to_img: '',
        post_id_for_user:''
    })

    const current_profile=(profile_id)=>{
        setProfileView({id:profile_id});
        encryptStorage.setItem('Profile_data',{id:profile_id})
    }

    const ongoing_connectection=(conn)=>{
        setMessage(conn);
        encryptStorage.setItem('ongoing_connectection', conn)
        console.log(conn)
    }

    const getdate = (txt) => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        let d = new Date(txt);
        let month_name = months[d.getMonth()];
        let year = d.getFullYear();
        let date = d.getDate();
        let hour = d.getHours();
        let min = d.getMinutes();

        let ampm = hour >= 12 ? 'pm' : 'am';
        hour = hour % 12;
        hour = hour ? hour : 12; // the hour '0' should be '12'
        min = min < 10 ? '0' + min : min;
        let strTime = hour + ':' + min + ' ' + ampm;

        let today = new Date();

        let diff = Math.round((today.getTime() - d.getTime()) / (1000 * 3600 * 24));

        if (diff < 1) {
            return strTime;
        } else if (diff < 7) {
            return diff + ' days ago';
        } else {
            return date + ', ' + month_name + ', ' + year
        }
    }
    return (
        <GlobalContext.Provider value={{current_profile,
            Dropdown, setDropdown, Filters, setFilters,
            ProfileView, setProfileView, PostView, setPostView, ongoing_connectection, getdate, NotificationData,
            setNotificationData, ConnectionData, setConnectionData, ViewProfileData, setViewProfileData, OwnPost, setOwnPost
        }}>
            {props.children}
        </GlobalContext.Provider>
    )
}

export default GlobalState;