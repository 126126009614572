import { css } from '@emotion/css'
import axios from 'axios'
import FormData from 'form-data'
import React, { useEffect, useState } from 'react'
import Avatar from 'react-avatar'
import { Button, Col, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate } from "react-router-dom"
import { toast } from 'react-toastify'
import { encryptStorage } from '../EncryptStorage'
import KYCInvestorForm from '../KYC file upload/KYC Form/KYCInvestorForm'
import About_Description from '../profile/About_Description'

const InvestorKycUpdate = () => {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    var data = new FormData();
    let navigate = useNavigate();
    const UserData = encryptStorage.getItem('UserData')

    const validateKYC = () => {
        if (UserData?.farmer_type === 'Farmer') {
            let path = '/'
            return UserData?.farmer_kyc_status === 'Completed' ? navigate(path) : UserData?.mandal_name ? navigate('/register/farmer-kyc-verification') : navigate(path)

        } else {
            let path = '/'
            return UserData?.would_be_farmer_kyc_status === 'Completed ' ? navigate(path) : UserData?.mandal_name ? navigate('/register/investor-kyc-verification') : navigate(path)
        }
    }
    // validateKYC()

    const submitKYC = (Data) => {
        data.append('farmer_id', UserData?.farmer_id);
        data.append('mobile_number', UserData?.mobile_number);
        data.append('intersted_in_investing', Data?.investorInterest);
        data.append('investment_amount', Data?.investorInvestmentBudget);
        data.append('investment_return_type', Data?.investorInvestmentReturnType);
        data.append('address_geolocation', [encryptStorage.getItem('Latitude'), encryptStorage.getItem('Longitude')]);
        data.append('about_me', Data?.investerDescription);

        var config = {
            method: 'put',
            url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/farmer_registration`,
            data: data
        };

        axios(config)
            .then(function (response) {
                toast.warning('KYC is pending for review!')
                encryptStorage.setItem('UserData', JSON.stringify(response?.data?.data))
                navigate('/', { replace: true });
            })
            .catch(function (error) {
                Object.entries(error.response.data.message).map((key, index) => {
                    toast.error(key?.[1]?.[0])
                })
            });

    }
    return (
        <>
            <Col>
                <Row>
                    <p className='fs-2 text-center  fw-bold'>Your KYC is {UserData?.would_be_farmer_kyc_status}</p>
                    <p className='fs-6 text-center color-text'>Create your account by filling the form below,Please fill the information correctly, it's important to profile verification process.</p>
                </Row>
                <Row className='justify-content-center mt-3'>
                    <div style={{ width: 100, height: 100 }} className='rounded-circle border p-0 d-flex justify-content-center align-items-center '>
                        {UserData?.farmer_image ? <img src={UserData?.farmer_image} alt={UserData?.farmer_name} style={{ width: 100, height: 100, objectFit: 'cover', borderRadius: '50%' }} /> : (
                            <Avatar name={UserData?.farmer_name} maxInitials={1} round size="100" />
                        )}
                    </div>
                    <p className='m-0 fw-semibold text-center'>{UserData?.farmer_name}</p>
                    <p className='m-0 text-center'>+91-{UserData?.mobile_number}</p>
                </Row>
                <hr />
                {UserData?.about_me ?
                    <>
                        <div className={css({ backgroundColor: '#e8e8e8', borderRadius: 8, padding: '3px 10px', fontSize: 14, textAlign: 'center' })}>
                            <span className='text-center color-text m-0'>Your KYC details has been submited, hence you cannot make any chages. </span>
                        </div>
                        <p className='m-0 fw-semibold mt-3'>About</p>
                        {<About_Description content={UserData?.about_me} limit={400} />}
                        <p className='m-0 fw-semibold mt-3'>Investment budget</p>
                        <span className='fs-3 fw-normal'>
                            &#x20B9;{UserData?.investment_amount}
                        </span>
                        <p className='m-0 fw-semibold mt-3'>Investment return type</p>
                        <span className='fs-5'>
                            {UserData?.investment_return_type}
                        </span>
                    </> :
                    <>
                        <form onSubmit={handleSubmit(submitKYC)}>
                            <Row>
                                <KYCInvestorForm register={register} errors={errors} />
                            </Row>
                            <Button variant='primary' className='w-100 bg-green ms-0 me-0 mt-3 d-block' type='submit'>Submit</Button>
                        </form>
                    </>}


            </Col>
        </>
    )
}

export default InvestorKycUpdate