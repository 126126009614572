import { css } from '@emotion/css'
import React from 'react'
import Avatar from 'react-avatar'
import { Col, Row } from 'react-bootstrap'
import { Image } from 'semantic-ui-react'
import { encryptStorage } from '../EncryptStorage'

const ViewRegisterDetails = () => {
    const UserData = encryptStorage.getItem('UserData')
    console.log(UserData)
    return (
        <>
            <Row>
                <Col>
                    <div className={css({ backgroundColor: '#e8e8e8', borderRadius: 8, padding: '3px 10px', fontSize: 14, textAlign: 'center' })}>
                        <span className='text-center color-text m-0'>Your registration details has been submited, hence you cannot make any chages. </span>
                    </div>
                    <div className='d-flex justify-content-between mt-4'>
                        <div>
                            <p className='m-0 fw-semibold'>Name</p>
                            <span className='fs-5 fw-semibold'>
                                {UserData?.farmer_name}
                            </span>
                            <p className='m-0 fw-semibold mt-3'>Mobile number</p>
                            <span className='fs-6'>
                                +91-{UserData?.mobile_number}
                            </span>
                            <p className='m-0 fw-semibold mt-3'>Email address</p>
                            <span className='fs-6'>
                                {!UserData?.email_id && '-N/A-'}
                            </span>
                            <p className='m-0 fw-semibold mt-3'>Registered as</p>
                            <span className='fs-6'>
                                {UserData?.farmer_type}
                            </span>
                        </div>
                        <div >
                            {!UserData?.farmer_image ? <Avatar name={UserData?.farmer_name} maxInitials={1} round size="95" textSizeRatio={1.8} /> :
                                <Image src={UserData?.farmer_image} alt={UserData?.farmer_name} className={css({ width: 250, height: 250, objectFit: 'cover', borderRadius: 8, border: '1px dashed #3D7F39' })} />
                            }
                        </div>
                    </div>
                    <Row>
                        <Col xs={6}>
                            <p className='m-0 fw-semibold mt-3'>Gender</p>
                            <span className='fs-6'>
                                {UserData?.gender}
                            </span>
                        </Col>
                        <Col xs={6}>
                            <p className='m-0 fw-semibold mt-3'>Date of birth</p>
                            <span className='fs-6'>
                                {UserData?.data_of_birth === "Invalid date" ? '-N/A-' : UserData?.data_of_birth}
                            </span>
                        </Col>
                        {/* <Col xs={6}>
                            <p className='m-0 fw-semibold mt-3'>Registered from state</p>
                            <span className='fs-6'>
                                {UserData?.state_name}
                            </span>
                        </Col>
                        <Col xs={6}>
                            <p className='m-0 fw-semibold mt-3'>Registered from district</p>
                            <span className='fs-6'>
                                {UserData?.district_name}
                            </span>
                        </Col>
                        <Col xs={6}>
                            <p className='m-0 fw-semibold mt-3'>Registered from village/city</p>
                            <span className='fs-6'>
                                {UserData?.village_name}
                            </span>
                        </Col>
                        <Col xs={6}>
                            <p className='m-0 fw-semibold mt-3'>Registered from assembly</p>
                            <span className='fs-6'>
                                {UserData?.assembly_name}
                            </span>
                        </Col>
                        <Col xs={6}>
                            <p className='m-0 fw-semibold mt-3'>Registered from mandal</p>
                            <span className='fs-6'>
                                {UserData?.mandal_name}
                            </span>
                        </Col> */}
                    </Row>

                </Col>
            </Row>
        </>
    )
}

export default ViewRegisterDetails