import React, { useState, useEffect, useRef, useContext } from 'react'
import axios from 'axios';
import parse from 'html-react-parser';
import { BsBellSlash, BsCheckAll } from 'react-icons/bs'
import globalContext from '../../context/GlobalContext';
import FormData from 'form-data';
import NewNotification from '../navbar/NewNotification';
import { Anchorme } from 'react-anchorme';
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap';
import { css } from '@emotion/css';
import { Outlet, useNavigate } from 'react-router-dom';
import context from 'react-bootstrap/esm/AccordionContext';
import NotificationTemplate from './NotificationTemplate';

function Notification() {
  var data = new FormData();
  const ref = useRef(null);
  const contxt = useContext(globalContext);
  const Deal_notification = []
  const Other_notification = []
  const [DealNotification, setDealNotification] = useState([])
  const [OtherNotification, setOtherNotification] = useState([])
  useEffect(() => {

    if (document.getElementById('submit-farmer-approval')) {

      let deal_approve = document.getElementById('submit-farmer-approval');

      deal_approve.addEventListener('click', function (e) {

        let farmer_id = e.target.parentElement.getAttribute("data-niveshak");
        let primary_key = e.target.parentElement.getAttribute("data-key");

        data.append('farmer', farmer_id);
        data.append('primary_key', primary_key);
        data.append('farmer_approval', 'Approved');

        putt()

      });

      let deal_reject = document.getElementById('submit-farmer-reject');

      deal_reject.addEventListener('click', function (e) {

        let farmer_id = e.target.parentElement.getAttribute("data-niveshak");
        let primary_key = e.target.parentElement.getAttribute("data-key");

        data.append('farmer', farmer_id);
        data.append('primary_key', primary_key);
        data.append('farmer_approval', 'Fail');

        putt()

      });

      const putt = () => {

        var config = {
          method: 'put',
          url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/update_investement_farmer_approval`,
          data: data
        };

        axios(config)
          .then(function (response) {
          })
          .catch(function (error) {
            console.log(error);
          });
      }

    }
  }, [])

  const UpdateNotificationStatus = (item) => {
    data.append('primary_key', item?.primary_key);
    data.append('read_status', 'read');
    var config = {
      method: 'put',
      url: `${process.env.REACT_APP_API_BASE_URL}/farmer_app/notification`,
      data: data
    };

    axios(config)
      .then(function (response) {
        <NewNotification />
        window.location.reload()
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  console.log(contxt.NotificationData)
  useEffect(() => {
    contxt?.NotificationData?.map(element => {
      if (element?.notification_type === 'Deal_Notification') {
        return Deal_notification.push(element)
      } else if (element?.notification_type === 'Other') {
        return Other_notification.push(element)
      }
    })
    setDealNotification(Deal_notification)
    setOtherNotification(Other_notification)
  }, [contxt?.NotificationData])
  console.log(DealNotification, Other_notification)


  return (
    <>
      <Container>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row className='pt-4'>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first" style={{ backgroundColor: 'transparent' }}>
                    <div className={css({
                      backgroundColor: '#E8E8E8',
                      borderRadius: 8,
                      boxShadow: '0px 4px 16px #00000000',
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '20px 30px',
                      alignItems: 'center'
                    })}>
                      <span className={css({
                        color: '#2E5F2B',
                        fontWeight: '600'
                      })}>All</span>
                      {/* <span className={css({
                        backgroundColor: '#FA8C01',
                        color: '#FFFFFF',
                        borderRadius: 12,
                        fontSize: 12,
                        padding: '5px 10px',
                      })}>2 new</span> */}
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second" style={{ backgroundColor: 'transparent' }}>
                    <div className={css({
                      backgroundColor: '#E8E8E8',
                      borderRadius: 8,
                      boxShadow: '0px 4px 16px #00000000',
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '20px 30px',
                      alignItems: 'center'
                    })}>
                      <span className={css({
                        color: '#646464',
                        fontWeight: '600'
                      })}>Deal Confirmation</span>
                      {/* <span className={css({
                        backgroundColor: '#FA8C01',
                        color: '#FFFFFF',
                        borderRadius: 12,
                        fontSize: 12,
                        padding: '5px 10px',
                      })}>2 new</span> */}
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='third' style={{ backgroundColor: 'transparent' }}>
                    <div className={css({
                      backgroundColor: '#E8E8E8',
                      borderRadius: 8,
                      boxShadow: '0px 4px 16px #00000000',
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '20px 30px',
                      alignItems: 'center'
                    })}>
                      <span className={css({
                        color: '#646464',
                        fontWeight: '600'
                      })}>Post Approval</span>
                      {/* <span className={css({
                        backgroundColor: '#FA8C01',
                        color: '#FFFFFF',
                        borderRadius: 12,
                        fontSize: 12,
                        padding: '5px 10px',
                      })}>2 new</span> */}
                    </div>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <div className={css({
                    backgroundColor: '#fff',
                    borderRadius: 8,
                    boxShadow: '0px 4px 24px #00000029',
                    padding: '20px 15px'
                  })}>
                    <NotificationTemplate notification={contxt.NotificationData} UpdateNotificationStatus={UpdateNotificationStatus} />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <div className={css({
                    backgroundColor: '#fff',
                    borderRadius: 8,
                    boxShadow: '0px 4px 24px #00000029',
                    padding: '20px 15px'
                  })}>
                    <NotificationTemplate notification={DealNotification} UpdateNotificationStatus={UpdateNotificationStatus} />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <div className={css({
                    backgroundColor: '#fff',
                    borderRadius: 8,
                    boxShadow: '0px 4px 24px #00000029',
                    padding: '20px 15px'
                  })}>
                    <NotificationTemplate notification={OtherNotification} UpdateNotificationStatus={UpdateNotificationStatus} />
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  )
}

export default Notification
