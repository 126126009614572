import React, { useState, useEffect } from 'react'
import FileUploader from '../File Uploader'
import { Form, Image, InputGroup } from 'react-bootstrap';
import { encryptStorage } from '../../EncryptStorage';
import { css } from '@emotion/css';

const ErrorField = css({
    fontSize: 12,
    color: '#f00'
})
const KYCFarmerForm = ({ setKycData, KycData, register, errors }) => {
    const UserData = encryptStorage.getItem('UserData')
    const [IdProof, setIdProof] = useState('')
    const [Aadhar, setAadhar] = useState('')
    const [AddressProof, setAddressProof] = useState([])
    console.log(AddressProof, IdProof)

    useEffect(() => {
        setKycData({ ...KycData, identity_proof_image: IdProof?.[0], address_proof_image: AddressProof?.[0] })
    }, [IdProof, AddressProof])

    return (
        <>
            <Form.Label htmlFor="basic-url" className='p-0'>Identity proof method*</Form.Label>
            <Form.Select aria-label="Default select example"
                {...register("farmerIdProofMethod", {
                    required: {
                        value: true,
                        message: 'Please select a identity proof type.'
                    }
                })
                }>
                <option value=''>Select identity proof method</option>
                <option value="Pan card">PAN card</option>
                <option value="Aadhar card">Aadhar card</option>
                <option value="Passport">Passport</option>
            </Form.Select>
            <span className={ErrorField}>
                {errors?.farmerIdProofMethod?.message}
            </span>
            <Form.Label htmlFor="basic-url" className='p-0 mt-3'>ID proof document number*</Form.Label>
            <InputGroup className="p-0">
                <Form.Control
                    placeholder="Enter document number"
                    aria-label="pan-card"
                    type="text"
                    minLength={10}
                    aria-describedby="Enter document number"
                    {...register("farmerIdProofDocumentNumber", {
                        required: {
                            value: true,
                            message: 'Please provide identity proof number.'
                        },
                        maxLength: {
                            value: 20,
                            message: "Maximum 20 characters are allowed.",
                        }
                    })
                    }
                />
            </InputGroup>
            <span className={ErrorField}>
                {errors?.farmerIdProofDocumentNumber?.message}
            </span>
            <Form.Label htmlFor="basic-url" className='p-0 mt-3'>Upload ID proof*</Form.Label>
            <div className={`${KycData?.identity_proof_type === 'Aadhar card' ? 'd-flex' : null} p-0`} style={{ gap: KycData?.identity_proof_type === 'Aadhar card' ? 25 : 0 }} >
                <FileUploader setImagePath={setIdProof} />
                {IdProof?.length !== 0 ? null :
                    <>
                        <span className={ErrorField}>
                            Please upload a valid ID proof
                        </span>
                    </>}
                {KycData?.identity_proof_type === 'Aadhar card' ? <FileUploader setImagePath={setAadhar} /> : null}
            </div>

            <Form.Label htmlFor="basic-url" className='p-0'>Upload address proof*</Form.Label>
            <div  >
                <FileUploader setImagePath={setAddressProof} />
                {AddressProof?.length !== 0 ? null :
                    <>
                        <span className={ErrorField}>
                            Please upload a valid address proof
                        </span>
                    </>}
            </div>

            <Form.Label htmlFor="basic-url" className='p-0 mt-3'>Address as per document*</Form.Label>
            <InputGroup className="mb-3 p-0">
                <Form.Control
                    placeholder="Address line 1"
                    aria-label="pan-card"
                    type="text"
                    aria-describedby="Enter Address"
                    maxLength={200}
                    {...register("farmerAddressLine1", {
                        required: {
                            value: true,
                            message: 'Please provide address line 1.'
                        },
                        minLength: {
                            value: 10,
                            message: 'Address line 1 must be at least 10 characters.'
                        },
                        maxLength: {
                            value: 200,
                            message: 'Address line 1 have 200 characters allowed.'
                        }
                    })
                    }
                />
            </InputGroup>
            <InputGroup className="p-0">
                <Form.Control
                    placeholder="Address line 2"
                    aria-label="pan-card"
                    type="text"
                    maxLength={200}
                    aria-describedby="Enter Address"
                    {...register("farmerAddressLine2", {
                        required: {
                            value: true,
                            message: 'Please provide address line 2.'
                        },
                        minLength: {
                            value: 10,
                            message: 'Adress line 2 must be at least 10 characters.'
                        },
                        maxLength: {
                            value: 200,
                            message: 'Address line 2 have 200 characters allowed.'
                        }
                    })
                    }
                />
            </InputGroup>
            <span className={ErrorField}>
                {errors?.farmerAddressLine1?.message}<br />
                {errors?.farmerAddressLine2?.message}
            </span>
            <Form.Label htmlFor="basic-url" className='p-0 mt-3'>Do you own land?</Form.Label>
            <InputGroup className="p-0">
                <Form.Select aria-label="Default select"
                    {...register("farmerOwnLand", {

                    })
                    }
                >
                    <option value=''>Do you own land?</option>
                    <option value="yes">yes</option>
                    <option value="no">No</option>
                </Form.Select>
            </InputGroup>
            <span className={ErrorField}>
                {errors?.farmerOwnLand?.message}
            </span>
            <InputGroup className="mt-3 p-0">
                <Form.Control
                    placeholder="Area of land"
                    aria-label="land property"
                    type="text"
                    aria-describedby="land property"
                    maxLength={2}
                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                    {...register("farmerLandAreaSize", {

                        validate: {
                            positive: v => parseInt(v) >= 0 || 'Land area size is not a valid input.',
                        }
                    })
                    }
                />
                <Form.Select aria-label="Default select example"
                    {...register("farmerLandAreaUnit", {
                        required: {
                            value: true,
                            message: 'Please select unit of land size.'
                        }
                    })
                    }>
                    <option value="acres">Acres</option>
                    <option value="hectare">Hectare</option>
                </Form.Select>
            </InputGroup>
            <span className={ErrorField}>
                {errors?.farmerLandAreaSize?.message}
                {errors?.farmerLandAreaUnit?.message}
            </span>
            <Form.Label htmlFor="basic-url" className='p-0 mt-3'>About you</Form.Label>
            <InputGroup className=" p-0">
                <Form.Control
                    placeholder='About yourself' as="textarea" maxLength={4000} minLength={20} aria-label="about you"
                    {...register("farmerDescription", {
                        minLength: {
                            value: 20,
                            message: 'Minimum 20 characters are required.'
                        }
                    })
                    }
                    style={{ height: 150 }}
                />
            </InputGroup>
            <span className={ErrorField}>
                {errors?.farmerDescription?.message}
            </span>
        </>
    )
}

export default KYCFarmerForm