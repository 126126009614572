import { css } from '@emotion/css'
import moment from 'moment/moment'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Image } from 'semantic-ui-react'

const BlogSliderCard = ({ Data }) => {
    return (
        <>
            <div className={css({ backgroundColor: '#E8E8E8', margin: 15, borderRadius: 8, border: '1px solid #e8e8e8', padding: 10, height: '100%' })}>
                <div>
                    <Image src={Data?.display_image} className={css({ width: '100%', height: 200, objectFit: 'cover', borderRadius: 8 })} />
                </div>
                <div className='d-flex justify-content-between my-2'>
                    <div className={css({ fontSize: 12 })}>
                        <span className='fw-bold'>Post on:</span>
                        {moment(Data?.updated_at).calendar(null, {
                            sameDay: '[Today]',
                            nextDay: '[Tomorrow]',
                            nextWeek: 'dddd',
                            lastDay: '[Yesterday]',
                            lastWeek: '[Last] dddd',
                            sameElse: 'DD/MM/YYYY'
                        })}
                    </div>
                    <div>
                        <div className={css({ backgroundColor: '#3D7F39', color: '#fff', fontSize: 12, padding: '0 5px', borderRadius: 4 })}>
                            Annoncements
                        </div>
                    </div>
                </div>
                <div>
                    <div className={css({ fontSize: 14, fontWeight: '500' })}>{Data?.title}</div>
                    <div className={css({ fontSize: 14 })}>
                        {Data?.blog_content}
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogSliderCard