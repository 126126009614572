import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Investor_Card from "../cards/Investor_Card";
import axios from "axios";
import globalContext from "../../context/GlobalContext";
import { toast } from "react-toastify";

function InvestorSlider() {
  const contxt = useContext(globalContext)
  let url = `${process.env.REACT_APP_BASE_API}/farmer_app/farmers_list?page=1&farmer_type=Niveshak`;

  const [CardList, setCardList] = useState([]);
  const [SlideNo, setSlideNo] = useState(0);

  useEffect(() => {
    const fetch_data = async () => {
      await axios
        .get(url)
        .then((response) => {
          let res = response.data.data.farmers_list;
          const list = res.map((key) => {
            return (
              <Investor_Card
                key={key.farmer_id}
                name={key.farmer_name}
                img={key.farmer_image}
                district={key.district_name}
                state={key.state_name}
                date={key.created_at}
                id={key.farmer_id}
                kyc={key.would_be_farmer_kyc_status}
                mn={key.mobile_number}
              />
            );
          });

          setCardList(list);
        })
        .catch((error) => {
          setCardList(false);
        });
    };

    fetch_data();
  }, []);

  const prevslide = () => {
    let slideno = SlideNo - 4;
    if (slideno >= 0) {
      setSlideNo(slideno);
      let ele = document.getElementById("slide" + slideno);
      ele.style.display = "block";
    }
  };

  const nextslide = () => {
    let slideno = SlideNo + 4;
    if (slideno < CardList.length) {
      setSlideNo(slideno);
      let ele = document.getElementById("slide" + slideno);
      ele.style.display = "block";
    }
  };
  return (
    <div className="slider investor_slider">
      <h1>
        Browse <span className="highlight">Niveshak</span>
      </h1>
      <p>
        Looking for investing in crops & machinery
        <Link to="/niveshak">
          <span className="view">
            View all <img src="img/right_arrow.svg" alt="" />
          </span>
        </Link>
        <span className="slider_btn">
          <img className="prev" src="img/prev.svg" onClick={prevslide} />
          <img className="next" src="img/next.svg" onClick={nextslide} />
        </span>
      </p>
      <div className="cards_container">
      {CardList?<>
        {CardList[SlideNo]}
        {CardList[SlideNo + 1]}
        {CardList[SlideNo + 2]}
        {CardList[SlideNo + 3]}
        </>
        : 'No Niveshaks'
      }  
      </div>
    </div >
  );
}

export default InvestorSlider;
