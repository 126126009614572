import React, { useState, useEffect } from "react";
import Reviews from "./tabs/Reviews";
import MyPosts from "./tabs/MyPosts";
import Bookmarks from "./tabs/Bookmarks";
import { encryptStorage } from "../EncryptStorage";
import MyDeals from "./tabs/MyDeals";

function Tabs(props) {
  const [toggleState, setToggleState] = useState();

  const toggleTab = (index) => {
    setToggleState(index)
  }

  useEffect(() => {
    if (props.profile.farmer_type === 'Farmer') {
      setToggleState(1)
    } else {
      setToggleState(2)
    }

  }, [])
  return (
    <div>
      <div className="tabs">
        <div className="tab-button">
          <div className={toggleState === 1 ? "title active" : "title"}
            onClick={() => toggleTab(1)}>
            {props.profile.farmer_type === 'Farmer' ? 'Posts' : null}
          </div>
          <div className={toggleState === 2 ? "title active" : "title"}
            onClick={() => toggleTab(2)}>
            {props.profile.farmer_type === 'Niveshak' ? 'Deals' : ''}
          </div>
          <div className={toggleState === 3 ? "title active" : "title"}
            onClick={() => toggleTab(3)}>
            Ratings & Reviews
          </div>
          <div className={toggleState === 4 ? "title active" : "title"}
            onClick={() => toggleTab(4)}>
            {props.ownprofile ? 'Bookmark' : ''}
          </div>
        </div>
        <div className={toggleState === 1 ? "tab posts active" : "tab reviews"}>
          <div className="my-posts">
            {props.profile.farmer_type === 'Farmer' ? <MyPosts ownprofile={props?.ownprofile} /> : null}
          </div>
        </div>
        <div className={toggleState === 2 ? "tab posts active" : "tab reviews"}>
          <div className="my-deals">
            {props.profile.farmer_type === 'Niveshak' ? <MyDeals profile={props.profile} /> : ''}
          </div>
        </div>
        <div className={toggleState === 3 ? "tab posts active" : "tab reviews"}>
          <div className="review-tab-container">
            <Reviews />
          </div>
        </div>
        <div className={toggleState === 4 ? "tab posts active" : "tab reviews"}>
          <div className="my-bookmarks">
            {props.ownprofile ? <Bookmarks/> : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tabs;
