import React, { useEffect, useState, useContext } from 'react';
import axios from "axios";
import Search_Card from '../../cards/Search_Card';
import globalContext from '../../../context/GlobalContext';
import { encryptStorage } from '../../EncryptStorage';

function MyPosts(props) {
  const FarmerData = encryptStorage.getItem("farmer_data");
  const [MyPostList, setMyPostList] = useState();
  const [AllPosts, setAllPosts] = useState(null);
  const [MyPostData, setMyPostData] = useState(null)
  const [Hide, setHide] = useState(true)
  const contxt = useContext(globalContext);

  const profileid = contxt.ProfileView.id?contxt.ProfileView.id : encryptStorage.getItem('Profile_data').id;

  useEffect(() => {
    console.log('posts'+profileid)
    let url = `${process.env.REACT_APP_BASE_API}/farmer_app/cultivation_requests_list?farmer_id=${profileid}`;

    const fetch_data = async () => {
      await axios
        .get(url)
        .then((response) => {
          let res_data = response.data.data.requests_list;
          setMyPostData(res_data)
          const res = response.data.data.requests_list.slice(0, 3)
          const list = res.map((key, index) => {
            return (
              <Search_Card
                key={index}
                id={key.primary_key}
                farmerid={key.farmer_id}
                ownprofile={key?.farmer_id === FarmerData?.farmer_id}
              />
            );
          });
          setMyPostList(list);
        })
        .catch((error) => {
          setMyPostList("No Posts");
        });
    };

    fetch_data();
  }, ['',contxt.ProfileView])

  const MorePosts = () => {
    setHide(false)
    const res = MyPostData.slice(3, MyPostData?.length)
    const list = res.map((key, index) => {
      return (
        <Search_Card
          key={index}
          id={key.primary_key}
          farmerid={key.farmer_id}
          ownprofile={props?.ownprofile}
        />
      );
    })
    setAllPosts(list)

  }
  return (
    <div className='myposts'>
      {MyPostList}
      {!Hide && <span class="view" style={{ display: Hide ? 'block' : 'none', cursor: 'pointer' }} onClick={() => MorePosts()}>View all <img src="img/right_arrow.svg" /> </span>}
      {AllPosts}
    </div>
  )
}

export default MyPosts