import React, { useEffect, useContext, useState } from 'react'
import axios from "axios";
import Search_Card from '../../cards/Search_Card';
import { encryptStorage } from '../../EncryptStorage';
import { css } from "@emotion/css";
import { BsBookmark} from 'react-icons/bs'

function Bookmarks() {
  const FarmerData = encryptStorage.getItem("Profile_data");
  const [BookmarkList, setBookmarkList] = useState();
  const url = new URL(window.location.href);
  const id = url.searchParams.get("id");

  console.log(FarmerData)
  useEffect(() => {
    let url = `${process.env.REACT_APP_BASE_API}/farmer_app/book_mark?farmer_id=${FarmerData.id}`;

    const fetch_data = async () => {
      await axios
        .get(url)
        .then((response) => {
          let res = response.data.data;
          console.log(res)
          if (res === '') {
            setBookmarkList("No Bookmarks");
          } else {
            const list = res.map((key, index) => {
              return (
                <Search_Card
                  key={index}
                  id={key.cultivation_id}
                  farmerid={key.posted_by_id}
                  ownprofile={key?.posted_by_id === id}
                />
              );
            });
            setBookmarkList(list);
          }

        })
        .catch((error) => {
          setBookmarkList("No Bookmarks");
        });
    };

    fetch_data();
  }, [FarmerData?.farmer_id])

  
  if(BookmarkList !=''){
    return BookmarkList
  }else{
   return <>
    <div className={css({ boxShadow: '0px 3px 6px #00000029', display: 'flex', justifyContent: 'center', padding: '15px', marginTop: 20, backgroundColor: '#fff', borderRadius: 8 })}>
      <div className="text-center">
        <BsBookmark fontSize={35} />
        <div className="mt-2">No Bookmarks</div>
      </div>
    </div>
  </>
  }

  

    
}

export default Bookmarks