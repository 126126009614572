import React from "react";
import { BiCheckCircle, BiDownload } from "react-icons/bi";
import { FaTimesCircle } from "react-icons/fa";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";


function Confirmation(props) {

  const handleDownload = () => {
    const element = document.getElementById('popup');

    html2canvas(element).then((canvas) => {
      canvas.toBlob((blob) => {
        saveAs(blob, 'event_pass.png');
      });
    });
  };

  console.log("confirmation");

  return (
    <div className="popup" >
      <div className="deal" id="popup">
        <div className="close" onClick={() => props.setConfirm(false)}>
          <FaTimesCircle />
        </div>
        <h4>
          <BiCheckCircle /> Booking Confirmation
        </h4>
        <hr />
        {console.log(props.Res)}
        <div class="container">
          <div class="row">
            <div className="col-10">
              <div class="row">
                <div className="col p-3">
                  Pass ID: {props.Res.event_pass_id}
                </div>
                <div
                  className="col p-3"
                  style={{ color: "green" }}
                  onClick={handleDownload}
                >
                  <BiDownload /> Download Pass
                </div>
              </div>
              <div className="row">
                <div className="col p-3">
                  Name: <h5>{props.Res.name}</h5>
                </div>
                <div className="col p-3">
                  Mobile: <h5>{props.Res.mobile_number}</h5>
                </div>
                <div className="col p-3">
                  Email: <h5>{props.Res.email_id}</h5>
                </div>
              </div>
              <div className="row">
                <div className="col p-3">
                  Occupation: <h5>{props.Res.profession_name}</h5>
                </div>
                <div className="col p-3">
                  Company/College: <h5>{props.Res.profession_type}</h5>
                </div>
                <div className="col p-3">
                  City: <h5>{props.Res.city}</h5>
                </div>
              </div>
            </div>
            <div className="col-2">
              <img src={props.Res.qr_code} width="100" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Confirmation;
