import React, { useState } from 'react'

const About_Description = ({ content, limit }) => {
    const [showAll, setShowAll] = useState(false);

    const showMore = () => setShowAll(true);
    const showLess = () => setShowAll(false);

    if (content?.length <= limit) {
        // there is nothing more to show
        return <div style={{ overflowWrap: 'break-word' }}>{content}</div>
    }
    if (showAll) {
        // We show the extended text and a link to reduce it
        return <div style={{ overflowWrap: 'break-word' }}>
            {content}
            <span className='highlight fw-bold' style={{ cursor: 'pointer' }} onClick={showLess}>Read less</span>
        </div>
    }
    // In the final case, we show a text with ellipsis and a `Read more` button
    const toShow = content?.substring(0, limit) + "...";
    return <div style={{ overflowWrap: 'break-word' }}>
        {toShow}
        <span className='highlight fw-bold' style={{ cursor: 'pointer' }} onClick={showMore}>Read more</span>
    </div>
}

export default About_Description