import React, { useState } from "react";
import { FaTimesCircle } from "react-icons/fa";
import axios from "axios";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { css } from "@emotion/css";
import { toast } from "react-toastify";


const ErrorField = css({
  fontSize: 12,
  color: "#f00",
});

function EventPop(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [Disablebtn, setDisablebtn] = useState(false);


  let data = new FormData();

  const onsubmit = (FormData) => {
    console.log(FormData);

    data.append("name", FormData.FullName);
    data.append("email_id", FormData.Email);
    data.append("mobile_number", FormData.MobileNo);
    data.append("profession_type", FormData.Company);
    data.append("profession_name", FormData.Occupation);
    data.append("city", FormData.City);
    data.append("event_schedule", props.EveInfo.eventId);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url:
        process.env.REACT_APP_BASE_API + "/farmer_app/event_attendee_details",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setDisablebtn(true);
        props.setEpop(false);
        props.setRes(response.data.data);
        props.setConfirm(true);
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
        toast.error(error);
      });
  };

  return (
    <>
      <div className="popup">
        <div className="deal">
          <div className="close" onClick={() => props.setEpop(false)}>
            <FaTimesCircle />
          </div>
          <h4>{props.EveInfo.title}</h4>
          <p>Confirm your spot at the event by filling following form </p>
          <hr />
          <form onSubmit={handleSubmit(onsubmit)}>
            <Row>
              <Col xs={6}>
                <div className="me-4">
                  Full Name: <br />
                  <Form.Control
                    type="text"
                    id="fullname"
                    maxLength={50}
                    {...register("FullName", {
                      required: {
                        value: true,
                        message: "Full Name is a required field.",
                      },
                    })}
                  />
                </div>
                <p className={ErrorField}>{errors?.FullName?.message}</p>
              </Col>
              <Col xs={6}>
                <div>
                  Mobile Number: <br />
                  <Form.Control
                    type="number"
                    number
                    id="mobileno"
                    maxLength={10}
                    {...register("MobileNo", {
                      required: {
                        value: true,
                        message: "Mobile Number is a required field.",
                      },
                    })}
                  />
                </div>
                <p className={ErrorField}>{errors?.MobileNo?.message}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="me-4">
                  Occupation: <br />
                  <Form.Control
                    type="text"
                    id="occupation"
                    maxLength={50}
                    {...register("Occupation", {
                      required: {
                        value: true,
                        message: "Occupation is a required field.",
                      },
                    })}
                  />
                </div>
                <p className={ErrorField}>{errors?.FullName?.message}</p>
              </Col>
              <Col xs={6}>
                <div>
                  Company/College Name: <br />
                  <Form.Control
                    type="text"
                    id="company"
                    maxLength={50}
                    {...register("Company", {
                      required: {
                        value: true,
                        message: "Company is a required field.",
                      },
                    })}
                  />
                </div>
                <p className={ErrorField}>{errors?.MobileNo?.message}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="me-4">
                  Email: <br />
                  <Form.Control
                    type="email"
                    id="email"
                    maxLength={50}
                    {...register("Email", {
                      required: {
                        value: true,
                        message: "Email is a required field.",
                      },
                    })}
                  />
                </div>
                <p className={ErrorField}>{errors?.FullName?.message}</p>
              </Col>
              <Col xs={6}>
                <div>
                  City: <br />
                  <Form.Control
                    type="text"
                    id="city"
                    maxLength={50}
                    {...register("City", {
                      required: {
                        value: true,
                        message: "City is a required field.",
                      },
                    })}
                  />
                </div>
                <p className={ErrorField}>{errors?.MobileNo?.message}</p>
              </Col>
            </Row>

            <Button
              type="submit"
              className="submit-deal bg-green"
              disabled={Disablebtn}
              style={Disablebtn ? { opacity: 0.2 } : null}
            >
              Submit
            </Button>
          </form>
        </div>
      </div>
    </>
  );
}

export default EventPop;
