import React, { useContext } from "react";
import { Link } from 'react-router-dom';
import globalContext from "../../context/GlobalContext";
import Avatar from 'react-avatar';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment/moment";
import { css } from "@emotion/css";
import { encryptStorage } from "../EncryptStorage";

function Investor_Card(props) {

  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let d = new Date(props.date);
  let month_name = months[d.getMonth()];
  let year = d.getFullYear();
  const contxt = useContext(globalContext);

  return (
    <div className="investor-cards">
      <div onClick={() =>contxt.current_profile(props.id)}>
        <Link to='/profile'>
          <div className="card_container">
            <div className="grid">
              <div className="img">
                {!props?.img ? <Avatar name={props?.name} maxInitials={1} size="84" textSizeRatio={2} /> :
                  <img className={`pic ${css({ width: 84, height: 84, objectFit: 'cover' })}`} src={props.img} />}

              </div>
              <div className="content">
                <div className="name"><span>{props.name}</span> {props.kyc ?
                  <OverlayTrigger
                    overlay={
                      <Tooltip >
                        Verified niveshak
                      </Tooltip>
                    }>
                    <img className="verified" src="img/verified.svg" />
                  </OverlayTrigger> : null
                }</div>
                <div className="location">
                  <img src="img/location-map.svg" /> {props.district}, {props.state}
                </div>
                {/* <div className="budget">Budget: Rs.12,000</div> */}

                {/* <div className="testimonial"><img className="verified" src="img/verified.svg" />  KhetiValah Verified</div> */}
                <div className="invested">Member Since: {month_name}, {year}</div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div >
  );
}

export default Investor_Card;
