import { css } from '@emotion/css';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useCallback } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Pagination from '../../Utils/Pagination/Pagination';
import Search_Card from '../cards/Search_Card';
import { encryptStorage } from '../EncryptStorage';
import Filters from '../Filters';
import Spinner from '../Spinner';

let PageSize = 10;

const PostList = () => {
    const FarmerData = encryptStorage.getItem("farmer_data");
    const State_Filter = encryptStorage.getItem('state_filter')
    const [isLoading, setisLoading] = useState(true);
    const [CardList, setCardList] = useState(null);
    const [State, setState] = useState('')
    const [CropCategory, setCropCategory] = useState('')
    const [PostPages, setPostPages] = useState(null)
    const [ActivePagination, setActivePagination] = useState(1)

    const postsTable = useMemo(() => {
        const firstPageIndex = (ActivePagination - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return PostPages?.requests_list?.slice(firstPageIndex, lastPageIndex);
    }, [ActivePagination, PostPages]);

    console.log(postsTable)
    useEffect(() => {
        const posts = postsTable?.map((key) => {
            return (
                // eslint-disable-next-line react/jsx-pascal-case
                <Search_Card
                    key={key.primary_key}
                    id={key.primary_key}
                    farmerid={key.farmer_id}
                    data={key}
                    ownprofile={key?.farmer_id === FarmerData?.farmer_id}
                />
            );
        });
        setCardList(posts);
    }, [FarmerData?.farmer_id, postsTable])

    useEffect(() => {
        const getPostList = async (page) => {
            setisLoading(true);
            let url = `${process.env.REACT_APP_BASE_API}/farmer_app/cultivation_requests_list?page=${page}&status=published${State ? "&state_name=" + State : State_Filter === undefined || ' ' ? '' : "&state_name=" + encryptStorage.getItem('state_filter')}${CropCategory ? "&crop_category=" + CropCategory : encryptStorage.getItem('crop_categor_filter') === undefined ? '' : "&crop_category=" + encryptStorage.getItem('crop_category_filter')}`;

            const fetch_data = async () => {
                try {
                    await axios
                        .get(url)
                        .then((response) => {
                            setPostPages(response?.data?.data)
                            setisLoading(false);
                        })
                } catch (error) {
                    console.log(error)
                    setCardList("No Results");
                    setisLoading(false);
                    if (error?.response?.data?.code === 400) {
                        toast.error(`Error ${error?.response?.data?.code}: Unexpected error occured.`, {
                            position: "top-right",
                            autoClose: false,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                }
            };

            fetch_data();
        }
        getPostList(ActivePagination)
    }, [ActivePagination, CropCategory, State, State_Filter])
    console.log(CardList)
    return (
        isLoading ? <Spinner /> :
            <>
                <Container>
                    <Row>
                        <Col xs={3}>
                            <Filters setState={setState} setCropCategory={setCropCategory} />
                        </Col>
                        <Col xs={9}>
                            <div className={css({ display: 'grid', gap: 20, flexWrap: 'wrap', marginTop: 50, gridTemplateColumns: 'auto' })}>
                                {CardList}
                            </div>
                            {CardList !== 'No Results' && PostPages && <Row className='mt-4'>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={ActivePagination}
                                    pageSize={PageSize}
                                    totalCount={PostPages?.requests_list?.length}
                                    onPageChange={page => setActivePagination(page)} />
                            </Row>}
                        </Col>
                    </Row>
                </Container>
            </>
    )
}

export default PostList