import React from 'react'
import { Navigate } from 'react-router-dom'
import { encryptStorage } from '../../components/EncryptStorage'
import { useAuth } from '../Auth Context'

export const RequireAuth = ({ children }) => {
    const auth = useAuth()
    if (!encryptStorage.getItem('Authenticated') == true) {
        return <Navigate to='/signin' />
    }
    return children

}
