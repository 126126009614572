import { css } from '@emotion/css'
import React from 'react'
import { useContext } from 'react'
import { Anchorme } from 'react-anchorme'
import { BsBellSlash, BsCheckAll } from 'react-icons/bs'
import globalContext from '../../context/GlobalContext'

const NotificationTemplate = ({ notification, UpdateNotificationStatus }) => {
    console.log(notification)
    const contxt = useContext(globalContext);

    return (
        <>
            <div className="bg-white Notification_scroll_bar d-flex flex-column" id='Notification_scroll_bar' style={{ gap: 10 }} >
                {!notification || notification.length === 0 ? (
                    <div className='d-flex flex-column text-center p-5' style={{ color: '#6c6c6c69' }}>
                        <div>
                            <BsBellSlash fontSize={50} />
                        </div>
                        <div className='mt-2 fw-semibold' >
                            No notification to show.
                        </div>
                    </div>
                ) :
                    notification?.map((data, index) => (
                        <div key={index} className="d-flex justify-content-between py-3 ps-2 m-1 pe-2 align-items-center" style={{ backgroundColor: data?.read_status === "unread" ? '#F6F6F6' : null, borderRadius: data?.read_status === "unread" ? 12 : null, gap: 20 }} >
                            <div className='text-end' >
                                {data?.notification_type === 'Deal_Notification' ?
                                    <img src='/img/icons/confirm_deal.svg' alt={data?.notification_type} /> :
                                    <img src='/img/icons/announcement.svg' alt={data?.notification_type} />
                                }
                            </div>
                            <div className={css({ width: '100%' })} >
                                {data?.notification_type === 'Deal_Notification' ?
                                    <>
                                        <div className={css({ fontWeight: '600', marginBottom: 5, color: '#333333' })}>Great Deal Done !</div>
                                    </> : null}
                                <div className={css({ fontSize: 14, color: '#757575' })}>
                                    <Anchorme target="_blank" rel="noreferrer noopener" className="m-0 text-start color-text notify-msg" style={{ fontWeight: data?.read_status === "unread" ? 600 : 400 }}>{data?.notification_message}</Anchorme>
                                </div>
                            </div>
                            <div className='text-end' style={{ width: '15%' }}>
                                <p className='m-0' style={{ fontSize: 12, color: data?.read_status === "unread" ? '#0044c7' : null }}>{contxt.getdate(data?.created_at)}</p>
                                <p className='m-0' style={{ fontSize: 14, color: data?.read_status === "unread" ? '#0044c7' : null, cursor: data?.read_status === "unread" ? 'pointer' : null }} onClick={() => UpdateNotificationStatus(data)}>{data?.read_status === "unread" ? 'mark as read' : <BsCheckAll fontSize={16} color={data?.read_status === "read" ? '#0044c7' : null} />}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export default NotificationTemplate