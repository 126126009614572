import React from 'react'

function How_Investor() {
  return (
    <div className="how_grid">
      <div className="grid_investor">
        <p className='highlight'>
          How it work for niveshak
        </p>
        <h1>
          Become a <span className="highlight">Niveshak. </span>
        </h1>
        <div>
          KhetiValah offers you unlimited investment choices, so that you can earn more money by investing with our verified farmers.
        </div>
      </div>

      <div className="grid_investor"></div>

      <div className="grid_investor">
        <img src="img/how_investor.svg" alt="" />
      </div>

      <div className="grid_investor">
        <ul>
          <li>
            <div className="icon item">
              <img src="/img/profile_icon.svg" />
            </div>
            <div className="content item">
              <h4>Register as a Niveshak, and complete KYC</h4>
              <div>
                KhetiValah offers you unlimited investment limit,
                so that you can earn more money by investing with our verified farmers.
              </div>
            </div>
          </li>
          <li>
            <div className="icon item">
              <img src="/img/rupee_icon.svg" />
            </div>
            <div className="content item">
              <h4>Publish your investment post</h4>
              <div>
                KhetiValah offers you unlimited investment limit,
                so that you can earn more money by investing with our verified farmers.
              </div>
            </div>
          </li>
          <li>
            <div className="icon item">
              <img src="/img/msg_icon.svg" />
            </div>
            <div className="content item">
              <h4>Get offer's by verified farmer's</h4>
              <div>
                KhetiValah offers you unlimited investment limit, so that you
                can earn more money by investing with our verified farmers.
              </div>
            </div>
          </li>
          <li>
            <div className="icon item">
              <img src="/img/check_icon.svg" />
            </div>
            <div className="content item">
              <h4>Finalize Your deal in just one click.</h4>
              <div>
                KhetiValah offers you unlimited investment limit, so that you
                can earn more money by investing with our verified farmers.
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default How_Investor