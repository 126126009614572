import React from "react";
import { Modal } from "react-bootstrap";

const Popup = props => {
    return (
        <>
            <Modal
                show={props?.handleClose}
                onHide={props?.handleClose}
                backdrop="static"
                keyboard={false}
                size='lg'
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="report-heading">Report</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props?.content}
                </Modal.Body>
            </Modal>
            {/* <div className="popup-box-container">
            <div className="popup-box">
                {props?.content}
                <div className="close-icon" onClick={props?.handleClose}>
                    <img src="img/close-popup.svg" />
                </div>
            </div>
        </div> */}
        </>
    )
}

export default Popup;